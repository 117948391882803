import { Video } from '@tvi/types/video'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProjectsAction } from '../../../redux/projects/projects.saga'
import {
  selectProjectsByBrandId,
  selectProjectsLoading,
} from '../../../redux/projects/projects.selector'
import { selectVideosByBrandId } from '../../../redux/videos/videos.selectors'
import { useBrandsProvider } from '../../brands/components/BrandsProvider'

type UseProjectsProps = {
  excludeVideos?: number[]
}

const useProjects = ({ excludeVideos = [] }: UseProjectsProps) => {
  const dispatch = useDispatch()
  const brand = useBrandsProvider()
  const projects = useSelector(selectProjectsByBrandId(brand?.id))
  const isLoading = useSelector(selectProjectsLoading)
  const videos = useSelector(selectVideosByBrandId(brand?.id)) as Video[]
  const hasProjects = useMemo(() => Boolean(projects.length), [projects])

  useEffect(() => {
    if (!brand?.id) return
    // if (hasProjects) return
    dispatch(fetchProjectsAction({ brandId: brand.id, excludeVideos }))
  }, [dispatch, brand?.id])

  return {
    projects,
    videos,
    isLoading,
  }
}

export default useProjects
