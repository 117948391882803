import { Project } from '@tvi/types/project'
import { Video } from '@tvi/types/video'
import { createVideo } from '../../videos/models/video'

export const createProject = (
  project: Partial<Project> | number
): Partial<Project> => {
  const p = project as Project

  return {
    ...p,
    videos: p.videos.map((video) => createVideo(video as Video) as Video),
  }
}

/*
 * Flatten all videos in a list of projects,
 * ensure each video has a reference to its project
 */
export const projectsVideos = (projects: Project[]): (number | Video)[] => {
  return projects?.reduce?.(
    (videos: (number | Video)[], project: Project) => [
      ...videos,
      ...((project?.videos || []).map((video) =>
        createVideo({
          ...(video as Video),
          projectId: project.id,
        })
      ) as Video[]),
    ],
    []
  )
}
