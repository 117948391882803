import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { pxToRem } from '@tvi/uikit/utils/html-units'

export const VideosModuleContainer = styled(Box)`
  height: 100%;

  .simplebar-track.simplebar-vertical {
    right: 0;
  }

  .videos-module,
  .videos-module > .media-list {
    height: 100%;
    overflow-y: hidden;
  }

  .videos-module > .media-list {
    display: flex;
    flex-direction: column;
  }

  .media-list-outer-container {
    overflow-y: hidden;
  }

  .media-list-container {
    flex: 1;
  }

  .action-bar {
    margin-bottom: ${pxToRem(20)};
  }
`

export const VideosBulkActionBarContainer = styled(Box)`
  /* margin-bottom: ${pxToRem(20)}; */

  .action-bar--bulk {
    padding-bottom: ${pxToRem(5)};
  }
`

export const VideosNoListComponentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: calc(8vw + ${pxToRem(75)});
`

export const VideosNoListComponentInfo = styled(Typography)`
  margin-top: ${pxToRem(20)};
  padding: 0 20%;
`
