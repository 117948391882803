import { TimeObject } from '@tvi/types/timeObject'
import { format, parseISO } from 'date-fns'
import { DATE_FORMAT } from '../constants/appConstants'

export const formatDate = (date: string | number | Date) => {
  const parsed = typeof date === 'string' ? parseISO(date) : date
  return format(parsed, DATE_FORMAT)
}

export const sortByTime = (a: TimeObject, b: TimeObject) =>
  a.startTimeOffset - b.startTimeOffset
