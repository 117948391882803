import styled from '@emotion/styled'
import { Box } from '@mui/system'
import { pxToRem } from '@tvi/uikit/utils/html-units'

export const ActionsBarContainer = styled(Box)`
  display: flex;

  .action-bar__search {
    flex-grow: 1;
    padding-right: ${pxToRem(25)};
  }
`
