import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { ASSETS, ROUTES } from '../../constants/paths'
import UserMenu from '../user-menu/UserMenu'

const Header: FC = () => (
  <div className="header">
    <NavLink to={ROUTES.home}>
      <img
        className="header__logo"
        src={ASSETS.headerLogo}
        width="150"
        alt="Edisen"
      />
    </NavLink>
    <UserMenu />
  </div>
)

export default Header
