import {
  ServiceProfile,
  ServiceProfileServices,
} from '@tvi/types/serviceProfile'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userEmailSelector } from '../../../redux/auth/auth.selectors'
import { selectPrimaryBrandByClientId } from '../../../redux/brands/brands.selectors'
import { activeClientSelector } from '../../../redux/clients/clients.selectors'
import {
  createServiceProfileActions,
  deleteServiceProfileActions,
  fetchServiceProfilesActions,
  updateServiceProfileActions,
} from '../../../redux/serviceProfiles/serviceProfiles.saga'
import {
  selectServiceProfileByBrandId,
  selectServiceProfiles,
} from '../../../redux/serviceProfiles/serviceProfiles.selectors'

const useServiceProfiles = () => {
  const dispatch = useDispatch()

  const client = useSelector(activeClientSelector)

  if (!client) {
    throw new Error('Client must be defined')
  }

  const brand = useSelector(selectPrimaryBrandByClientId(`${client?.id}`))
  const state = useSelector(selectServiceProfiles)
  const serviceProfiles = useSelector(selectServiceProfileByBrandId(brand?.id))
  const email = useSelector(userEmailSelector)
  const [numConnected, setNumConnected] = useState<number>(0)

  const canRequest = useMemo(
    () => brand?.id && !state.loading && !state.loaded,
    [brand?.id, state.loading, state.loaded]
  )

  const connect = useCallback(
    (
      email: string,
      brandId: number,
      service: ServiceProfileServices,
      name?: string
    ) => {
      dispatch(
        createServiceProfileActions({
          email,
          service,
          brandId: brandId,
          name: name ?? `${service}-${new Date().getTime()}`,
        })
      )
    },
    [dispatch]
  )

  const update = useCallback(
    (serviceProfile: ServiceProfile) => {
      dispatch(updateServiceProfileActions(serviceProfile))
    },
    [dispatch]
  )

  const disconnect = useCallback(
    (id: number) => {
      dispatch(deleteServiceProfileActions({ id }))
    },
    [dispatch]
  )

  useEffect(() => {
    setNumConnected(
      serviceProfiles.reduce(
        (total, profile) => total + (profile.isConnected ? 1 : 0),
        0
      )
    )
  }, [serviceProfiles])

  useEffect(() => {
    if (!canRequest) return
    dispatch(fetchServiceProfilesActions({ brandId: brand.id }))
  }, [canRequest])

  return {
    isLoading: state.loading,
    isLoaded: state.loaded,
    serviceProfiles,
    numConnected,
    brand,
    email,
    connect,
    disconnect,
    update,
  }
}

export default useServiceProfiles
