import eviApi, { GetBrandArgs } from '@tvi/api-client'
import { createAsyncSaga } from 'create-async-saga'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { httpErrorAction } from '../errors/errors.actions'

export const fetchBrands = createAsyncSaga(
  'edisenVI/brands/fetchBrands',
  function* ({ id }: GetBrandArgs) {
    try {
      return yield call(eviApi.v1.getBrands, { clientId: id })
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const fetchBrand = createAsyncSaga(
  'edisenVI/brands/fetchClient',
  function* fetchClient({ id }: { id: number }) {
    try {
      return yield call(eviApi.v1.getBrand, { id })
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export default function* brandsSaga() {
  yield all([
    takeEvery(fetchBrands.actionType, fetchBrands.asyncSaga),
    takeEvery(fetchBrand.actionType, fetchBrand.asyncSaga),
  ])
}

export const fetchBrandsAction = fetchBrands.action
export const fetchBrandAction = fetchBrand.action
