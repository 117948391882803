import tviApi from '@tvi/api-client'
import configureAPI from './bootstrap-api'

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.tviApi = tviApi
}

/*
 * Setup the app and execute any pre-run procedures
 */

configureAPI()
