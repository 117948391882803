import { PlaylistItem } from '@tvi/types/playlist'
import Button from '@tvi/uikit/components/button'
import ActionBarBulk from '../../../../components/action-bar-bulk/ActionBarBulk'
import { useSelectedContext } from '../../../../lib/selected/SelectedProvider'

const PlaylistBulkActionBar = ({ onProcess }: { onProcess(): void }) => {
  const { selected, isAllSelected, isPartialSelected, handleSelectChange } =
    useSelectedContext<PlaylistItem>()

  return (
    <ActionBarBulk
      count={selected?.size}
      checked={isAllSelected}
      indeterminate={isPartialSelected}
      onChange={handleSelectChange}
      actions={
        Boolean(selected.size) && (
          <Button onClick={onProcess} variant="text" size="small">
            Process Selected Videos
          </Button>
        )
      }
    />
  )
}

export default PlaylistBulkActionBar
