import { isEmpty } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlaylistsActions } from '../../../redux/playlists/playlists.saga'
import {
  selectLoadedByServiceProfileIds,
  selectLoadersByServiceProfileIds,
  selectLoadingByServiceProfileIds,
  selectPlaylistsListsWithVideosSelector,
} from '../../../redux/playlists/playlists.selector'
import useServiceProfiles from '../../serviceProfiles/effects/useServiceProfiles'

type Props = {
  filters: number[]
  profiles: ReturnType<typeof useServiceProfiles> | null
}

const usePlaylists = ({ filters = [], profiles = null }: Props) => {
  const dispatch = useDispatch()
  const { brand, serviceProfiles, numConnected = 0 } = profiles ?? {}
  const hasFilters = !isEmpty(filters)

  /**
   * all service profile ids for the active brand
   */
  const profileIds = useMemo(
    () => serviceProfiles?.map((profile) => profile.id) || [],
    [serviceProfiles]
  )

  /**
   * all loaders for this active brand and its service profiles
   */
  const profileLoaders = useSelector(
    selectLoadersByServiceProfileIds(profileIds)
  )

  /**
   * selector showing where there are any playlists still loading
   * for the active brand and its service profiles
   */
  const areProfilePlaylistsLoading = useSelector(
    selectLoadingByServiceProfileIds(profileIds)
  )

  /**
   * selector showing where there All playlists are loaded
   * for the active brand and its service profiles
   */
  const areProfilePlaylistsLoaded = useSelector(
    selectLoadedByServiceProfileIds(profileIds)
  )

  /**
   * isLoading
   * if there are profileIds but no loaders - then yes
   * if there are loaders then use `areProfilePlaylistsLoading`
   */
  const isLoading = useMemo(() => {
    if (isEmpty(profileIds)) return false
    if (!isEmpty(profileIds) && numConnected === 0) return false
    if (isEmpty(profileLoaders)) return true
    if (areProfilePlaylistsLoaded) return false

    // there shouldn't be empty profileIds && empty loaders
    return areProfilePlaylistsLoading
  }, [
    numConnected,
    profileIds,
    profileLoaders,
    areProfilePlaylistsLoading,
    areProfilePlaylistsLoaded,
  ])

  /**
   * select playlists by service profile ids
   * TODO: clunky - use search filtered else selector
   */
  const playlists = useSelector(
    selectPlaylistsListsWithVideosSelector(profileIds)
  )

  /**
   * filter playlists
   */
  const filteredPlaylists = useMemo(() => {
    return (
      hasFilters
        ? playlists.filter(({ serviceProfileId }) =>
            filters.includes(serviceProfileId as number)
          )
        : playlists
    ).sort((a, b) => a.title.localeCompare(b.title))
  }, [playlists, hasFilters, filters])

  /**
   * fetch playlists when there are connected services
   * and we haven't previously fetch the playlist before
   * in this current session
   */
  useEffect(() => {
    const brandId = brand?.id
    if (!brandId) return

    // request playlists for connected service profiles
    // that aren't currently in a positive loading state
    serviceProfiles
      ?.filter(({ isConnected, id }) => {
        const loader = profileLoaders[id]
        return isConnected && !loader?.loaded && !loader?.loading
      })
      .forEach(({ service, id: serviceProfileId }) => {
        dispatch(
          fetchPlaylistsActions({
            brandId,
            service,
            serviceProfileId,
          })
        )
      })
  }, [brand, profileLoaders, serviceProfiles])

  return {
    isLoading,
    playlists: filteredPlaylists,
  }
}

export default usePlaylists
