// import { AnalysisStatus } from '@tvi/types/analysis'
import { Cut } from '@tvi/types/cuts'
import {
  DataSourceRetention,
  DataSourceRetentionType,
  DataSourceTypes,
} from '@tvi/types/dataSource'
import { Scene } from '@tvi/types/scene'
import { Tag } from '@tvi/types/tag'
import { Video } from '@tvi/types/video'
import flatten from 'lodash.flatten'
import isEmpty from 'lodash.isempty'
import orderBy from 'lodash.orderby'
import { Observable } from 'rxjs'
import { configVideoEvents } from '../../../constants/videosConfig'
import { sortByTime } from '../../../utils/date'
import { toGSResourceUrl } from '../../../utils/transform'
import { uriCleanQuery } from '../../../utils/uri'
import {
  createVideoScene,
  createVideoScenes,
  createVideoScenesFromCuts,
} from './scenes'
import { getObjFromTime } from './secondTimeObject'
import { observeAsyncEvents } from './videoEvents'

const isArray = Array.isArray

export const defaultThumbnail =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mMM/w4AAasBUOVnpEsAAAAASUVORK5CYII='

export const AnalysisStatus = {
  no_analysis: 'no analysis',
  pending_analysis: 'pending',
  complete_analysis: 'complete',
  success: 'complete',
}

type NameTaggable = { name?: string; tagList: Tag[] }

export type AnalysisStatusTypes = keyof typeof AnalysisStatus

export interface VideoEnhanced extends Video {
  analysisIsComplete?: boolean
  events?: Observable<any>
  report?: DataSourceRetentionType[keyof DataSourceRetentionType]
  scores?: any
}

export const displayAnalysisStatus = (video: Video): any => {
  return AnalysisStatus[video.analysisStatus]
}

export const analysisComplete = (video: Video): any => {
  return (
    video?.analysisStatus === 'complete_analysis' ||
    video?.analysisStatus === 'success'
  )
}

export const getRetentionData = (
  video: VideoEnhanced
): { data: DataSourceRetention; type: DataSourceTypes }[] => {
  if (!video.analytics) return []
  return (
    video?.analytics?.services?.youtube
      ?.map((report) => {
        const key = Object.keys(report).pop() as DataSourceTypes
        return {
          data: report[key].data,
          type: key,
        }
      })
      .filter((data) => !isEmpty(data?.data)) ?? []
  )
}

export const hasRetentionAnalytics = (video: VideoEnhanced): any => {
  const analytics = getRetentionData(video)
  if (!Boolean(analytics?.length)) return false
  return true
}

export const getThumbnail = (video: Video): any => {
  return uriCleanQuery(video?.thumbnailUri ?? defaultThumbnail)
}

const getDuration = (v: Video) => {
  return v.duration
    ? v.duration
    : v.cuts && isArray(v.cuts)
    ? orderBy([...v.cuts], ['endTimeOffset', 'desc'])?.pop()?.endTimeOffset
    : 0
}

const getCuts = (v: Video): Cut[] => {
  if (!v.cuts) return []
  return [...v.cuts].sort(sortByTime).map((cut, index) => {
    const thumbnailUri =
      cut.thumbnailUri ??
      getObjFromTime<{ uri: string; startTimeOffset: number }>(
        v.thumbnails,
        cut.startTimeOffset
      )?.uri ??
      defaultThumbnail

    return {
      ...cut,
      thumbnailUri,
      index,
    }
  })
}

/**
 * For each cut create a scene if video.scenes does not exist
 * Generally it should as the api creates them by default
 * @param { Video } video
 * @returns { Scene[] }
 */
export const createInitialScenesArrayFromCuts = (video: Video): Scene[] => {
  if (!video?.cuts) return []
  if (!isEmpty(video.scenes)) {
    const len = video.scenes.length
    return video.scenes
      .slice()
      .sort(sortByTime)
      .map((scene, index) => createVideoScene(scene, index, len))
  }
  return createVideoScenesFromCuts(getCuts(video))
}

/*
 * Get a flattened list of taggable objects by names split by spaces
 * with optional name split by spaces
 */
const getTaggable = (arr: NameTaggable[] = []): string[] => {
  return arr.reduce?.((list: any[], obj) => {
    return [
      ...list,
      ...[
        ...(obj.name?.toLocaleLowerCase().split(' ') || []),
        ...flatten(
          (obj.tagList || [])?.map((tag) =>
            tag.name.toLocaleLowerCase().split(' ')
          )
        ),
      ],
    ]
  }, [])
}

/*
 * Filter videos by some matching properties to `query`
 */
export const videosSearchFilter = (videos: Video[], query: string): Video[] => {
  return videos.filter((video) => {
    return (
      video.name.toLocaleLowerCase().match(query) ||
      getTaggable(video.scenes as NameTaggable[]).includes(query) ||
      getTaggable(video.cuts as NameTaggable[]).includes(query)
    )
  })
}

/*
 * createVideo
 * Decorate a raw video object with some convenience properties
 */
export const createVideo = (video: Video): Partial<Video> => {
  // when we have a list of ids we'll want to create a partial sparce object
  // and then fill it in when we get additional details

  if (!video)
    return {
      scenes: [],
    } as Partial<Video>

  /*
   * Temporarily stored edit videos until we figure out api
   */
  const metaVideo = video.meta?.edit?.video
    ? {
        ...(video.meta?.edit?.video || {}),
        uri: video.meta?.edit?.url,
      }
    : {}

  return {
    ...video,
    detailed: true,
    duration: getDuration(video),
    scenes: !isEmpty(video.scenes)
      ? createVideoScenes(video.scenes)
      : createInitialScenesArrayFromCuts(video),
    analysisIsComplete: analysisComplete(video),
    thumbnailUri: getThumbnail(video),
    uri: toGSResourceUrl(video.uri),

    // when given a meta video its properties should replace
    // any existing properties
    ...metaVideo,
  } as Video
}

/*
 * This should not be stored in redux, it adds some subscriptions for some things
 * like pusher notifications and scoring that we don't necessarily
 * want to cause a complete redraw, we only want to update those
 * views that need that data.
 */
export const createEnhancedVideo = (
  v: VideoEnhanced
): Partial<VideoEnhanced> => {
  if (isEmpty(v)) return v
  const video = createVideo(v) as VideoEnhanced

  return {
    ...video,
    events: isEmpty(video?.events)
      ? observeAsyncEvents(video, configVideoEvents.slice())
      : video?.events,
  }
}
