import PreviewIcon from '@mui/icons-material/Visibility'
import { CardMedia, LinearProgress, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Button from '@tvi/uikit/components/button'
import MediaCard from '@tvi/uikit/components/media-card'
import clsx from 'clsx'
import { FC } from 'react'
import { SelectedCardMedia } from '../../../../lib/selected/components/Selected.style'
import { PlaylistVideoItem } from '../../../../redux/playlist-videos/playlistVideos.selectors'
import { formatDate } from '../../../../utils/date'

export type PlaylistGridProps = {
  video: PlaylistVideoItem
  selected: boolean
  chooseVideo: (video: PlaylistVideoItem) => void
  previewVideo: (video: PlaylistVideoItem) => void | undefined
  toggleSelect: (video: PlaylistVideoItem) => void
}

const PlaylistGridItem: FC<PlaylistGridProps> = ({
  video,
  toggleSelect,
  chooseVideo,
  previewVideo = () => void 0,
  selected = false,
}) => {
  const theme = useTheme()
  return (
    <MediaCard
      progress={
        video.isDownloadPending && (
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          />
        )
      }
      className="playlists__video-card"
      image={video.thumbnailUrl}
      title={video.title}
      header={
        <Button
          sx={{
            color: theme.palette.common.white,
            width: 20,
            height: 20,
            minWidth: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            ':hover': {
              color: theme.palette.common.white,
              filter: 'none',
            },
          }}
          onClick={() => previewVideo(video)}
        >
          <PreviewIcon fontSize="inherit" color="inherit" />
        </Button>
      }
      headerProps={{
        sx: {
          p: 0,
          boxSizing: 'border-box',
          display: 'inline-block',
          right: '5px !important',
          top: '5px !important',
          left: 'inherit !important',
          zIndex: 1,
          borderRadius: '4px',
          width: '20px !important',
          height: '20px !important',
          background: theme.palette.grey[800],
          ':hover': {
            background: theme.palette.primary.main,
          },
        },
      }}
      info={
        <Typography variant="body2">
          <span>{formatDate(video.publishedAt)}</span>
          {video.isDownloadComplete && (
            <Typography
              color="success.main"
              fontWeight={600}
              variant="body2"
              lineHeight={1.8}
            >
              In queue
            </Typography>
          )}
        </Typography>
      }
      actions={
        <>
          {video.isDownloadNone && (
            <Button
              onClick={() => chooseVideo(video)}
              sx={{ p: 0 }}
              variant="text"
              size="small"
            >
              Choose Video
            </Button>
          )}
          {video.isDownloadPending && (
            <Typography
              color="theme.palette.grey.600"
              fontWeight={600}
              variant="body2"
              lineHeight={1.8}
            >
              Pending
            </Typography>
          )}
        </>
      }
      CardMedia={
        <SelectedCardMedia
          className={clsx(selected && 'selected')}
          onClick={() => toggleSelect(video)}
        >
          <CardMedia component="img" height="94" image={video.thumbnailUrl} />
        </SelectedCardMedia>
      }
    />
  )
}

export default PlaylistGridItem
