import { Video } from '@tvi/types/video'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ROUTES } from '../../../constants/paths'
import {
  fetchVideoAction,
  fetchVideoAnalyticAction,
} from '../../../redux/videos/videos.saga'
import {
  selectVideoById,
  selectVideosLoading,
} from '../../../redux/videos/videos.selectors'
import { useBrandsProvider } from '../../brands/components/BrandsProvider'
import { createVideo } from '../models/video'
import useScoredVideo from './useScoredVideo'

type UseVideoProps = {
  id: number
}

const useVideo = ({ id }: UseVideoProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const brand = useBrandsProvider()
  const isLoading = useSelector(selectVideosLoading)
  const video = useSelector(selectVideoById(id)) as Video

  /*
   * score video if it hasn't been scored yet
   */
  useScoredVideo(createVideo(video) as Video)

  /*
   * fetch video
   */
  useEffect(() => {
    if (!id || video) return
    dispatch(fetchVideoAction({ id }))
  }, [id, video])

  /*
   * fetch analytics
   */
  useEffect(() => {
    if (!video || video.analytics) return
    dispatch(fetchVideoAnalyticAction({ videoId: id }))
  }, [dispatch, id, video])

  /*
   * if the current video exists and
   * it isn't in the brand then go back
   * to the index page
   */
  useEffect(() => {
    if (!video?.brandId || !brand?.id) return
    if (video?.brandId !== brand?.id) {
      history.push(ROUTES.videos)
    }
  }, [video, brand])

  return {
    video,
    isLoading,
  }
}

export default useVideo
