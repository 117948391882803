import { ComponentType, FC } from 'react'
import {
  Redirect as RedirectDefault,
  Route as RouteDefault,
  RouteProps,
} from 'react-router-dom'

export type AuthRouteProps = Omit<RouteProps, 'path'> & {
  path: string
  redirectPath?: string
  Route?: ComponentType
  Redirect?: ComponentType
}

const AuthRoute: FC<AuthRouteProps> = ({
  Route = RouteDefault,
  Redirect = RedirectDefault,
  path,
  redirectPath,
  ...rest
}) => {
  // const isAuthorized = useAppSelector(isAuthorizedSelector)

  // return isAuthorized ? (
  //   <Route {...rest} />
  // ) : (
  //   <Redirect
  //     from={path}
  //     to={{
  //       pathname: redirectPath || REDIRECT.authRoute,
  //       search: `redirect=${path}`,
  //     }}
  //   />
  // )

  return <Route {...rest} />
}

export default AuthRoute
