export class UnKnownError extends Error {
  status = 400
  static type = 'UNKNOWN'
  constructor(message: string = UnKnownError.type) {
    super(message)
  }
}

export class NotAuthorizedError extends Error {
  status = 401
  static type = 'NOT_AUTHORIZED'
  constructor(message: string = NotAuthorizedError.type) {
    super(message)
  }
}

export class NotFoundError extends Error {
  status = 404
  static type = 'NOT_FOUND'
  constructor(message: string = NotFoundError.type) {
    super(message)
  }
}

export class UnprocessibleError extends Error {
  status = 422
  static type = 'UNPROCESSIBLE'
  constructor(message: string = UnprocessibleError.type) {
    super(message)
  }
}

export class MissingAuthCodeError extends Error {
  status = 422
  static type = 'MISSING_AUTH_CODE'
  constructor(message: string = MissingAuthCodeError.type) {
    super(message)
  }
}
