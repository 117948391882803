import { Box, Divider, Typography } from '@mui/material'
import { Video } from '@tvi/types/video'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PALETTE } from '../../../../constants/uiColors'
import { fetchProjectsAction } from '../../../../redux/projects/projects.saga'
import { deleteVideoAction } from '../../../../redux/videos/videos.saga'
import { InlineEllipsesStyle } from '../../../../styled/global/text'
import { useBrandsProvider } from '../../../brands/components/BrandsProvider'
import { defaultThumbnail } from '../../models/video'
import {
  VidoesConfirmDeleteDialog,
  VidoesConfirmDeleteMediaCard,
} from './VideosConfirmDelete.style'

export interface Props {
  videos: Video[]
  onClose(): void
}

const MAX_PREVIEW = 4

export const VideosConfirmDelete: FC<Props> = ({ videos, onClose }) => {
  const dispatch = useDispatch()
  const brand = useBrandsProvider()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const videosDeletePreview = useMemo(
    () => videos.slice(0, MAX_PREVIEW),
    [videos]
  )
  const videosDeletePreviewMore = useMemo(
    () => videos.length - MAX_PREVIEW,
    [videos]
  )

  const handleDelete = useCallback(() => {
    setIsDeleting(true)
    videos.forEach((video) => dispatch(deleteVideoAction({ id: video.id })))
  }, [videos])

  const handleFinished = useCallback(() => {
    dispatch(fetchProjectsAction({ brandId: brand.id }))
  }, [brand])

  const handleClose = useCallback(() => {
    if (isDeleting) {
      handleFinished()
    }
    setIsDeleting(false)
    setIsOpen(false)
    onClose?.()
  }, [isDeleting])

  const handleCancel = useCallback(() => handleClose(), [])

  useEffect(() => {
    if (Boolean(videos.length)) {
      setIsOpen(Boolean(videos.length))
    } else {
      handleCancel()
    }
  }, [videos])

  return (
    <VidoesConfirmDeleteDialog
      isOpen={isOpen}
      isDeleting={isDeleting}
      handleClose={handleClose}
      handleDelete={handleDelete}
      handleCancel={handleCancel}
      TitleComponent={
        <Typography textAlign={'center'} fontSize={36} variant="h2">
          Are you sure?
        </Typography>
      }
      ContentComponent={
        <>
          <Typography marginX="auto" maxWidth={300} textAlign={'center'}>
            Are you sure you want to delete
          </Typography>
          <Box
            mt={20}
            sx={{
              display: 'flex',
              gap: 10,
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            {videosDeletePreview?.map((previewVideo, index) => (
              <Box
                sx={{
                  width: 70,
                  height: 40,
                  mb: 20,
                  background: PALETTE.gray500,
                }}
              >
                <VidoesConfirmDeleteMediaCard
                  key={index}
                  image={previewVideo.thumbnailUri || defaultThumbnail}
                  sx={{ width: 70, height: 40 }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    ...InlineEllipsesStyle,
                  }}
                >
                  {previewVideo.name}
                </Typography>
              </Box>
            ))}
          </Box>
          {videosDeletePreviewMore > 0 && (
            <>
              <Typography variant="h6" my={10} mx="auto" textAlign={'center'}>
                + {videosDeletePreviewMore} more
              </Typography>
              <Divider />
            </>
          )}
          <Typography mt={10} mx="auto" maxWidth={300} textAlign={'center'}>
            This process CAN NOT BE UNDONE, please proceed with caution.
          </Typography>
        </>
      }
    />
  )
}

export default VideosConfirmDelete
