import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

export interface UseSelectedType<T, K> {
  list: T[]
  listKey?(item: T): T | K
  listMap?<T>(array: T[] | null | undefined): T[]
}

export interface UseSelectedResponseType<T, K> {
  all: T[]
  selected: Map<T | K, T>
  isAllSelected: boolean
  isPartialSelected: boolean
  selectAll(): void
  deselectAll(): void
  handleSelect(item: T): void
  handleDeselect(item: T): void
  handleSelectChange: (
    event?: ChangeEvent<HTMLInputElement> | undefined
  ) => void
  toggleSelect(item: T): void
  listKey(item: T): T | K
  toggleSelectByIndex(index: number): void
  handleDeselectByIndex(index: number): void
  handleSelectByIndex(index: number): void
}

export const useSelected = <T, K>({
  list,
  listMap,
  listKey = (item) => item,
}: UseSelectedType<T, K>): UseSelectedResponseType<T, K> => {
  const [selected, setSelected] = useState<Map<T | K, T>>(new Map())
  const all = useMemo(() => (listMap ? listMap(list) : list), [list])
  const allArray = useMemo(() => Array.from(all), [all])

  const isAllSelected = useMemo(
    () => Boolean(selected?.size) && all.length === selected?.size,
    [selected, all]
  )

  const isPartialSelected = useMemo(
    () => Boolean(selected?.size) && !isAllSelected,
    [selected, isAllSelected]
  )

  const selectAll = useCallback(() => {
    list.forEach((item) => selected.set(listKey(item), item))
    setSelected(new Map(selected))
  }, [selected, all])

  const deselectAll = useCallback(() => {
    setSelected(new Map())
  }, [])

  const handleSelect = useCallback(
    (item: T) => {
      selected.set(listKey(item), item)
      setSelected(new Map(selected))
    },
    [selected, setSelected]
  )

  const handleSelectByIndex = useCallback(
    (index: number) => {
      const item: T = allArray[index]
      if (item) handleSelect(item)
    },
    [allArray]
  )

  const handleDeselect = useCallback(
    (item: T) => {
      selected.delete(listKey(item))
      setSelected(new Map(selected))
    },
    [selected, setSelected]
  )

  const handleDeselectByIndex = useCallback(
    (index: number) => {
      const item: T = allArray[index]
      if (item) handleDeselect(item)
    },
    [selected, setSelected]
  )

  const toggleSelect = useCallback(
    (item: T, e?: SyntheticEvent) => {
      if (e) {
        e.nativeEvent?.stopImmediatePropagation?.()
      }
      if (selected.has(listKey(item))) {
        handleDeselect(item)
      } else {
        handleSelect(item)
      }
    },
    [selected, setSelected]
  )

  const toggleSelectByIndex = useCallback(
    (index: number) => {
      toggleSelect(allArray[index] as unknown as T)
    },
    [selected, allArray, setSelected]
  )

  const handleSelectChange = useCallback(
    (event?: ChangeEvent<HTMLInputElement>) => {
      if (event?.target.checked) {
        selectAll()
      } else {
        deselectAll()
      }
    },
    [setSelected, list]
  )

  return {
    all,
    selected,
    isAllSelected,
    isPartialSelected,
    selectAll,
    deselectAll,
    handleSelect,
    handleDeselect,
    handleSelectChange,
    toggleSelect,
    listKey,
    toggleSelectByIndex,
    handleSelectByIndex,
    handleDeselectByIndex,
  }
}
