import { Box } from '@mui/system'
import { Video } from '@tvi/types/video'
import clsx from 'clsx'
import { FC } from 'react'
import { useSelectedContext } from '../../../../lib/selected/SelectedProvider'
import { createVideo, VideoEnhanced } from '../../models/video'
import VideoGridItem from './VideoGridItem'

type VideosGridProps = {
  list: VideoEnhanced[]
  itemClicked: (video: VideoEnhanced) => void
}

const VideosGrid: FC<VideosGridProps> = ({ list, itemClicked }) => {
  const { selected, listKey, toggleSelect } = useSelectedContext<Video>()

  return (
    <Box
      className="media-list-container videos"
      sx={{
        display: 'grid',
        gridGap: 0,
        gridTemplateColumns: 'repeat(3, 1fr)',
      }}
    >
      {list.map((video: VideoEnhanced) => {
        const v = createVideo(video) as Video
        return (
          <VideoGridItem
            className={clsx(video.analysisStatus)}
            key={v.id}
            video={v as VideoEnhanced}
            selected={selected.has(listKey(v))}
            toggleSelect={toggleSelect}
            onClick={itemClicked}
          />
        )
      })}
    </Box>
  )
}

export default VideosGrid
