import { styled } from '@mui/material/styles'
import SimpleBar from 'simplebar-react'
import {
  simpleBarSmallStyles,
  simpleBarStyles,
  SimpleBarStylesProps,
} from '../../styled/vendor/simplebar'

export type CustomScrollProps = {
  small?: boolean
} & Omit<SimpleBarStylesProps, 'theme'>

export const CustomScroll = styled(SimpleBar)(
  ({ small, ...rest }: CustomScrollProps) =>
    small
      ? simpleBarSmallStyles(rest as SimpleBarStylesProps)
      : simpleBarStyles(rest as SimpleBarStylesProps)
)
