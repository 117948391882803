import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Switch } from 'react-router'
import AuthRoute from '../../components/auth-route/AuthRoute'
import { ROUTES } from '../../constants/paths'
import ColumnLayout from '../../layouts/column-layout/ColumnLayout'
import { BrandsProvider } from '../../modules/brands/components/BrandsProvider'
import PlaylistModule from '../../modules/playlist/components/playlist-module/PlaylistModule'
import { ServiceProfilesProvider } from '../../modules/serviceProfiles/providers/ServiceProfilesProvider'
import VideosDetailModule from '../../modules/videos/components/videos-detail-module/VideosDetailModule'
import VideosModule from '../../modules/videos/components/videos-module/VideosModule'
import { VideosModuleContainer } from './Videos.style'

const SelectVideosPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>Select Videos</title>
      </Helmet>
      <BrandsProvider>
        <ServiceProfilesProvider>
          <Switch>
            <AuthRoute exact path={ROUTES.videos}>
              <ColumnLayout
                className="select-video"
                cols={[
                  {
                    header:
                      'Choose Videos that you would like to Analyze and Remix',
                    content: (
                      <VideosModuleContainer>
                        <PlaylistModule />
                      </VideosModuleContainer>
                    ),
                  },
                  {
                    header: 'Videos that are ready to Analyze and Remix',
                    content: (
                      <VideosModuleContainer>
                        <VideosModule />
                      </VideosModuleContainer>
                    ),
                  },
                ]}
              />
            </AuthRoute>
            <AuthRoute path={`${ROUTES.videos}/:id`}>
              <VideosDetailModule />
            </AuthRoute>
          </Switch>
        </ServiceProfilesProvider>
      </BrandsProvider>
    </>
  )
}

export default SelectVideosPage
