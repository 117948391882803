import {
  createAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { AnalyticReport } from '@tvi/types/analytic'
import { Video } from '@tvi/types/video'
import { sortEntityByDate } from '../../lib/entities'
import { BrandEventNotification } from '../../modules/brands/models/brand'
import { createVideo } from '../../modules/videos/models/video'
import { VideosEventNotification } from '../../modules/videos/models/videoEvents'
import {
  afterFetchProjects,
  deleteVideo,
  fetchVideo,
  fetchVideoAnalytic,
} from './videos.saga'

const VIDEOS_DATA_KEY = 'videos'

/**
 * Videos Entity Adapter
 */
const videosAdapter = createEntityAdapter<Video>({
  selectId: (video) => video.id,
  sortComparer: sortEntityByDate,
})

export const brandVideoEvent = createAction<BrandEventNotification>(
  `edisenVI/${VIDEOS_DATA_KEY}/events`
)

export const videoEvent = createAction<VideosEventNotification>(
  `edisenVI/${VIDEOS_DATA_KEY}/video/events`
)

/**
 * Videos Slice
 */
export const videosSlice = createSlice({
  name: VIDEOS_DATA_KEY,
  initialState: videosAdapter.getInitialState({
    ui: {
      // we're not hydrating this from storage as we want to make sure
      // we only show the latest, without temporarily showing a previous state
      isLoading: true,
    },
  }),
  reducers: {
    add: videosAdapter.addOne,
    upsert: videosAdapter.upsertOne,
    update: videosAdapter.updateOne,
    remove: videosAdapter.removeOne,
    removeMany: videosAdapter.removeMany,
    set: videosAdapter.setAll,
    addMany: videosAdapter.addMany,
    setMany: videosAdapter.setMany,
    upsertMany: videosAdapter.upsertMany,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVideo.fulfilled, (state, action: any) => {
      videosAdapter.upsertOne(
        state,
        createVideo({
          ...action.payload,
          brandId: action.meta?.arg?.brandId,
        }) as Video
      )
      state.ui = {
        ...state.ui,
        isLoading: false,
      }
    })
    builder.addCase(deleteVideo.pending, (state, action: any) => {
      const video = {
        ...state.entities[action.meta.arg.id],
        isDeleting: true,
      } as Video
      videosAdapter.upsertOne(state, video)
    })
    builder.addCase(deleteVideo.fulfilled, (state, action: any) => {
      videosAdapter.removeOne(state, action.meta.arg.id as number)
    })
    builder.addCase(fetchVideoAnalytic.fulfilled, (state, action: any) => {
      const video = {
        ...state.entities[action.meta.arg.videoId as number],
        analytics: action.payload as AnalyticReport,
      }
      videosAdapter.upsertOne(state, createVideo(video as Video) as Video)
    })
    builder.addCase(afterFetchProjects.fulfilled, (state) => {
      state.ui = {
        ...state.ui,
        isLoading: false,
      }
    })
  },
})

// prettier-ignore
export const {
  add,
  update,
  upsert,
  remove,
  removeMany,
  set,
  setMany,
  addMany,
  upsertMany
} = videosSlice.actions

export const { selectById } = videosAdapter.getSelectors()
export const videosReducer = videosSlice.reducer
