import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Video } from '@tvi/types/video'
import { Button, Checkbox } from '@tvi/uikit'
import { pxToRem } from '@tvi/uikit/utils/html-units'
import {
  ChangeEvent,
  FC,
  forwardRef,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'
import { videoEvent } from '../../../../redux/videos/videos.slice'
import { formatDate } from '../../../../utils/date'
import useVideoWithEvents from '../../effects/useVideoWithEvents'
import { VideoEnhanced } from '../../models/video'
import { VideosEventNotification } from '../../models/videoEvents'
import VideoProgress from '../video-progress/VideoProgress'
import { VideoListItemContainer } from './VideoListItem.style'

export type VideoListItemProps = {
  video: Video
  selected: boolean
  onClick: (video: Video) => void
  onToggle: (video: Video, e?: SyntheticEvent) => void
  onSelect?: (video: Video) => void
  onDeselect?: (video: Video) => void
}

const VideoListItem: FC<VideoListItemProps> = ({
  video: inputVideo,
  onToggle,
  onClick,
  selected = false,
}) => {
  const dispatch = useDispatch()

  /*
   * attach async events (pusher)
   * listen for events from remixes
   */
  const { enhancedVideo } = useVideoWithEvents({
    video: inputVideo,
    handleVideoEvents: (notification: VideosEventNotification) => {
      dispatch(videoEvent(notification))
    },
  })

  const video = useMemo(
    () => (enhancedVideo || inputVideo) as VideoEnhanced,
    [inputVideo, enhancedVideo]
  )

  const handleChange = useCallback(
    (e: ChangeEvent) => {
      onToggle(video, e)
    },
    [video]
  )

  const isLoading = useMemo(() => {
    return (
      !video?.analysisIsComplete && video?.meta?.edit?.status !== 'complete'
    )
  }, [video])

  return (
    <VideoListItemContainer>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: pxToRem(1),
        }}
        key={video.id}
        className="playlist__row"
      >
        <Box
          sx={{
            flex: '1',
          }}
        >
          <Checkbox
            checked={selected}
            onChange={handleChange}
            label={video.name}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            paddingRight: pxToRem(20),
          }}
        >
          <Typography
            variant="body2"
            sx={{
              paddingRight: pxToRem(10),
            }}
          >
            <div>{formatDate(video.createdAt as string)}</div>
          </Typography>
          {video.createdBy && (
            <Typography variant="body2">by: {video.createdBy}</Typography>
          )}
        </Box>
        <Box>
          {(video.analysisIsComplete ||
            video.meta?.edit?.status === 'complete') &&
            !isLoading &&
            !video.isDeleting && (
              <Button
                onClick={() => onClick(video)}
                sx={{ p: 0 }}
                variant="text"
                size="small"
                color="success"
              >
                Analyze &amp; Remix
              </Button>
            )}
          {video.meta?.edit?.status && video.meta?.edit?.status !== 'complete' && (
            <Typography
              variant="body2"
              mt={4}
              fontSize={12}
              fontWeight={600}
              color="grey.600"
              textTransform="capitalize"
            >
              Remix {video.meta?.edit?.status}
            </Typography>
          )}
        </Box>
      </Box>
      {isLoading && (
        <Box>
          <VideoProgress video={video} />
        </Box>
      )}
    </VideoListItemContainer>
  )
}

export default forwardRef((props: VideoListItemProps, ref) => {
  return <VideoListItem {...props} forwardedRef={ref} />
})
