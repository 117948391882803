import eviApi from '@tvi/api-client'
import { Client } from '@tvi/types/client'
import { createAsyncSaga } from 'create-async-saga'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { httpErrorAction } from '../errors/errors.actions'
import { afterClientSwitchedCommand } from '../sagas/commands/client-switcher.command'

export const fetchClients = createAsyncSaga(
  'edisenVI/clients/fetchClients',
  function* (): any {
    try {
      return yield call(eviApi.v1.getClients)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const fetchClient = createAsyncSaga(
  'edisenVI/clients/fetchClient',
  function* fetchClient({ id }: { id: number }) {
    try {
      return yield call(eviApi.v1.getClient, { id })
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const initiateClient = createAsyncSaga(
  'edisenVI/clients/initiate',
  function* initiateClient({ id, name = '' }: { id: number; name?: string }) {
    try {
      return yield call(eviApi.v1.initiateClient, { id, name })
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const setActiveClient = createAsyncSaga(
  'edisenVI/clients/setActiveClient',
  function* setActiveClient(client: Client) {
    // we're only using this as async so we get
    // the lifecycle automatically and dont need
    // to coordinate that ourselves
    // we break out the update into a separate actions
    // (afterClientSwitchedCommand)
    // this way (afterClientSwitchedCommand) is
    // reusable
    return client
  }
)

export default function* clientsSaga() {
  yield all([
    takeEvery(fetchClients.actionType, fetchClients.asyncSaga),
    takeEvery(fetchClient.actionType, fetchClient.asyncSaga),
    takeEvery(setActiveClient.actionType, setActiveClient.asyncSaga),
    takeEvery(initiateClient.actionType, initiateClient.asyncSaga),

    // after the user has switched clients
    takeEvery(setActiveClient.fulfilled, afterClientSwitchedCommand),
  ])
}

export const fetchClientsAction = fetchClients.action
export const fetchClientAction = fetchClient.action
export const setActiveClientAction = setActiveClient.action
export const initiatedClientAction = initiateClient.action
