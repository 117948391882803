import { Box, SxProps } from '@mui/system'
import { pxToRem } from '@tvi/uikit/utils/html-units'
import { FC } from 'react'
import ContentLoader from 'react-content-loader'
import { rangeArray } from '../../utils/array'

type LoaderGridProps = {
  width?: number
  height?: number
  gap?: number
  num?: number
  col?: number
  sx?: SxProps
  viewBox?: string
}

const LoaderGrid: FC<LoaderGridProps> = ({
  width = '100%',
  height = '100%',
  gap: gridGap = 20,
  num = 3,
  col = 1,
  sx,
  viewBox = '0 0 450 300',
}) => (
  <Box
    sx={{
      display: 'grid',
      gridGap,
      gridTemplateColumns: `repeat(${col}, ${col}fr)`,
      marginTop: pxToRem(50),
      ...sx,
    }}
  >
    {rangeArray(num).map((i) => (
      <ContentLoader
        key={i}
        width={width}
        height={height}
        viewBox={viewBox}
        backgroundColor="#838383"
        foregroundColor="#c7c7c7"
        opacity={0.8}
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="217" />
        <rect x="0" y="245" rx="4" ry="4" width="75%" height="9" />
      </ContentLoader>
    ))}
  </Box>
)

export default LoaderGrid
