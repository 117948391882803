import { DataSourceRetentionTypes } from '@tvi/types/dataSource'
import { Score } from '@tvi/types/score'
import { Video } from '@tvi/types/video'
import { BehaviorSubject, Observable } from 'rxjs'
import scoringConfig from '../../../constants/scoring'
import { getRetentionData, VideoEnhanced } from '../models/video'
import { computeRetentionAnalytics } from '../workers/scoring.worker'

export class ScoringService {
  public static BenchmarkScores = scoringConfig.benchmarkScores
  public static BenchmakarkLevel = scoringConfig.benchmarkLevels
  public static BenchmarkAdvanced = scoringConfig.benchmarkAdvanced

  private _scores: BehaviorSubject<Map<any, any> | null> =
    new BehaviorSubject<Map<any, any> | null>(null)
  public scores: Observable<Map<any, any> | null> = this._scores.asObservable()

  score(
    video: Video,
    type: DataSourceRetentionTypes | undefined = undefined
  ): Score[] {
    if (!video.analytics) return []

    const analytics = getRetentionData(video)
    const analytic = type
      ? analytics?.find((analytic) => analytic.type === type)
      : analytics?.[0]

    if (!analytic) return []

    const scores = computeRetentionAnalytics({
      ...video,
      report: analytic,
    } as unknown as VideoEnhanced) as Score[]

    return scores
  }
}

export default ScoringService
