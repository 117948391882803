import { Box } from '@mui/system'
import { Video } from '@tvi/types/video'
import { FC } from 'react'
import { useSelectedContext } from '../../../../lib/selected/SelectedProvider'
import { createVideo } from '../../models/video'
import VideoListItem from './VideoListItem'

type VideosListProps = {
  list: Video[]
  itemClicked: (video: Video) => void
}

const VideosList: FC<VideosListProps> = ({ list, itemClicked }) => {
  const { selected, handleSelect, handleDeselect, toggleSelect, listKey } =
    useSelectedContext<Video>()

  return (
    <Box className="media-list-container videos">
      {list.map((video: Video) => {
        const v = createVideo(video) as Video
        return (
          <VideoListItem
            className="video-item"
            key={`${v.id}-${v.name}`}
            onClick={itemClicked}
            onToggle={toggleSelect}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            selected={selected.has(listKey!(v))}
            video={v}
          />
        )
      })}
    </Box>
  )
}

export default VideosList
