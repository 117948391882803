import arrMerge from 'array-objects-merge'

// given an array of T return a new array
// with just `min` items in array
// ex: stepsInArray([0, 1, 2, 3, 4, 5, 6], 3) = [0, 2, 4]
export const stepsInArray = <T>(arr: T[] = [], min: number = 1): T[] => {
  const rMin = Math.min(arr.length, min)
  const delta = Math.floor(arr.length / rMin)
  return [...Array(rMin)].map((x, i) => arr[i * delta])
}

// ex: rangeArray(5) = [0, 1, 2, 3, 4]
export const rangeArray = (range: number): number[] => {
  return [...Array(range).keys()]
}

// ex: rangeInStepsArray<number>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], 3) =
// [[0, 1, 2], [3, 4, 5], [6, 7, 8], [9]]
export const rangeInStepsArray = <T>(
  arr: any[] = [],
  steps: number = 1
): T[] => {
  return arr.reduce((acc, val, i) => {
    const inc = Math.ceil((i - (steps - 1)) / steps)
    acc[inc] = (acc[inc] || []).concat(val)
    return acc
  }, [])
}

// merge objects where target keys overwrite arr keys
export const mergeArrayObjects = <T>(arr: T[] = [], target: T[] = []): T[] => {
  return arrMerge({}, arr, target)
}

export const insertAtPos = <T>(arr: T[] = [], index: number, item: T) => {
  return [...arr.slice(0, index), item, ...arr.slice(index)]
}

export const deleteAtPos = <T>(arr: T[] = [], index: number) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export function* arrayRangeStep(start: number, end?: number, step: number = 1) {
  if (end === undefined) [end, start] = [start, 0]
  for (let n = start; n < end; n += step) yield n
}

export const arrayRange = (n: number) => Array.from(arrayRangeStep(n))

export const isArrayNotEmpty = (maybeArr: any): boolean =>
  Array.isArray(maybeArr) && Boolean(maybeArr.length)

export const isArrayEmpty = (maybeArr: any): boolean =>
  !Boolean(maybeArr?.length)
