import Fade from '@mui/material/Fade'
import { Box } from '@mui/system'
import { Button } from '@tvi/uikit'
import { ViewType } from '@tvi/uikit/components/toggle-view'
import {
  forwardRef,
  ForwardRefExoticComponent,
  LegacyRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import SimpleBar from 'simplebar-react'
import { UiSelectorType } from '../../redux/ui/ui.selector'
import { UiUpdateActionType } from '../../redux/ui/ui.slice'
import { ActionBarBulkProps } from '../action-bar-bulk/ActionBarBulk'
import { ActionBarProps } from '../action-bar/ActionBar'

export type MediaListRef = {
  get scroller(): LegacyRef<SimpleBar | undefined>
}

export type MediaListProps = {
  viewSelector?: UiSelectorType
  viewAction?: UiUpdateActionType
  selectedView: ViewType
  ActionBarComponent: ReactElement<ActionBarProps>
  ActionBarBulkComponent?: ReactElement<ActionBarBulkProps>
  GridComponent: ReactNode
  ListComponent: ReactNode
  NoListComponent?: ReactNode
  isLoading?: boolean
  LoadingComponent?: ReactNode
  list: any[]
}

const MediaList: ForwardRefExoticComponent<MediaListProps> = forwardRef<
  MediaListRef,
  PropsWithChildren<MediaListProps>
>(
  (
    {
      list,
      selectedView,
      ListComponent,
      GridComponent,
      ActionBarComponent,
      NoListComponent,
      ActionBarBulkComponent = null,
      LoadingComponent = null,
      isLoading = true,
    },
    ref
  ) => {
    const ANIMATION_TIMEOUT = 750
    const scrollRef = useRef<SimpleBar>()
    const [selected] = useState<any[]>()
    const hasItems = !isLoading && list?.length > 0
    const hasSelections = Boolean(selected?.length)
    const active = hasItems && !isLoading

    useImperativeHandle(
      ref,
      () => ({
        get scroller() {
          return scrollRef
        },
      }),
      [ref, scrollRef]
    )

    return (
      <div className="media-list">
        {ActionBarComponent}
        {ActionBarBulkComponent}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {hasSelections && (
            <Box role="menu">
              {
                <Button variant="text" size="small">
                  Process
                </Button>
              }
            </Box>
          )}
        </Box>

        <Fade in={!active} timeout={ANIMATION_TIMEOUT}>
          <Box>
            {isLoading && LoadingComponent}
            {!isLoading && !hasItems && NoListComponent}
          </Box>
        </Fade>

        <Fade in={active} timeout={ANIMATION_TIMEOUT}>
          <Box className="media-list-outer-container">
            <SimpleBar
              style={{ height: '100%' }}
              ref={scrollRef as LegacyRef<SimpleBar>}
            >
              {active && selectedView === 'list' && ListComponent}
              {active && selectedView === 'grid' && GridComponent}
            </SimpleBar>
          </Box>
        </Fade>
      </div>
    )
  }
)

export default MediaList
