import { createSelector } from '@reduxjs/toolkit'
import { Brand } from '@tvi/types/brand'
import toArray from 'lodash/toArray'
import { AppState } from '../reducers'

/**
 * selectBrandsSelector - generic selection of brands (ids, entities)
 */
export const selectBrandsSelector = (state: AppState) => state.brands

/**
 * hasBrands - are there any brands
 */
export const hasBrands = (state: AppState): boolean =>
  state?.brands?.ids?.length > 0

/**
 * brandList - array of brands
 */
export const brandListSelector = createSelector(
  selectBrandsSelector,
  (brands): Brand[] => (toArray(brands?.entities) || []) as Brand[]
)

/**
 * selectBrandsByClientId
 */
export const selectBrandsByClientId = (clientId: string) => {
  return createSelector(brandListSelector, (brands) =>
    brands.filter((brand) => `${brand.clientId}` === `${clientId}`)
  )
}

/**
 * select primary brand by client id
 * for now its just the first brand but later this can
 * be from a saved selection
 */
export const selectPrimaryBrandByClientId = (clientId: string) => {
  return createSelector(
    selectBrandsByClientId(clientId),
    (brands) => brands?.[0]
  )
}

/**
 * primaryBrandSelector - get primary brand for now just the first one
 */
export const primaryBrandSelector = createSelector(
  brandListSelector,
  (brands): Brand | null => brands[0] ?? null
)
