import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material/styles/createTheme'

export const VideoPlayerContainer = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    '> div': {
      width: '100% !important',
    },
    'video, iframe': {
      width: '100%',
    },
  })
)
