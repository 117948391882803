import { FC } from 'react'
import { LinearProgress } from '@mui/material'

const VideosDetailLoader: FC<{
  isLoading: boolean
}> = ({ isLoading = true, children }) => (
  <>
    {isLoading && <LinearProgress />}
    {!isLoading && children}
  </>
)

export default VideosDetailLoader
