import { EnhancedStore } from '@reduxjs/toolkit'
import { addRequestInterceptor, addResponseInterceptor } from '@tvi/http'
import { SessionToken } from '@tvi/types/api'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { RootState } from '..'
import * as appConfig from '../../../constants/appConfig'
import http from '../../../utils/http'
import { sessionAction } from '../../auth/auth.saga'
import { sessionFromHeader } from '../../auth/auth.slice'

/**
 * Session Interceptors - Request and Response
 */
export const addStoreSessionInterceptor = (store: EnhancedStore<RootState>) => {
  /*
   * Add Session Request Interceptor
   */
  addRequestInterceptor(http)((req) => {
    // todo fix how this works
    // this may need to be reversed and added as a redux
    // middleware instead of http middleware so that we
    // don't have to use the imported store
    // that will fail if this is used on the server side
    // as the store will be a singleton shared between users
    // -- will update in next pass

    const session = store.getState().auth.session

    // ensure app verion is set for every request
    req.headers = {
      ...req.headers,
      Accept: `application/json; request_version=${appConfig.api.version}`,
    }
    if (session?.accessToken) {
      req.headers = {
        ...req.headers,
        'access-token': session.accessToken,
        'token-type': session.tokenType,
        'content-type': `application/json`,
        client: session.client,
        uid: session.uid,
      }
    }
    if (!session?.accessToken && session?.uid) {
      req.headers = {
        ...req.headers,
        'content-type': `application/json`,
        uid: session.uid,
      }
    }
    return req
  })

  /*
   * Add Session Response Interceptor
   * On each API response ensure session token is refreshed
   * This is returned by the API on each request
   */
  addResponseInterceptor(http)((res) => {
    try {
      if (res?.headers) {
        const session = omitBy(
          sessionFromHeader(res?.headers),
          (value) => isNil(value) || value === ''
        ) as SessionToken

        // if edisen user
        if (session.uid?.match?.(/eid/)) {
          store.dispatch(sessionAction(session))
        }
        // if login user
        else if (session.accessToken) {
          store.dispatch(sessionAction(session))
        }
      }
    } catch (error) {
      //
    }
    return res
  })
}
