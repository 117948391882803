// @ts-ignore
;(function disableEmotionWarnings() {
  const log = console.error.bind(console)
  console.error = (...args) => {
    if (
      args.indexOf('The pseudo class') &&
      args.indexOf(
        'is potentially unsafe when doing server-side rendering. Try changing it to'
      )
    ) {
      return
    }
    log(...args)
  }
})()
