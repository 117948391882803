import { Video } from '@tvi/types/video'
import { toArray } from 'lodash'
import { createSelector } from 'reselect'
import { sortEntityByDate } from '../../lib/entities'
import { createEnhancedVideo } from '../../modules/videos/models/video'
import { AppState } from '../reducers'
import { transformEntities } from '../utils/entities'
import { selectById } from './videos.slice'

/**
 * selectVideosSelector - generic selection of videos (ids, entities)
 */
export const selectVideosSelector = (state: AppState) => {
  const data = state.entities.videos
  const entities = transformEntities<Video>(data.entities, (entity) => ({
    ...entity,
    isDeleted: Boolean(entity.deletedAt),
  }))
  return {
    ...data,
    entities,
  }
}

/**
 * selectVideosListsSelector - array of videos
 */
export const selectVideosListsSelector = createSelector(
  selectVideosSelector,
  (videos): Video[] =>
    ((toArray(videos?.entities) as Video[]) || []).sort(sortEntityByDate)
)

/**
 * selectVideosByBrandId - array of videos
 */
export const selectVideosByBrandId = (brandId: number) =>
  createSelector(selectVideosListsSelector, (videos): Video[] =>
    videos.filter((video) => video.brandId === brandId)
  )

export const selectVideosLoading = createSelector(
  selectVideosSelector,
  (videos): boolean => videos?.ui?.isLoading
)

export const selectVideoById = (id: number) => {
  return createSelector(selectVideosSelector, (state) => selectById(state, id))
}

/**
 * selectVideoByEditId - find an edit video by its edit id
 */
export const selectVideoByEditId = (key: string | undefined) => {
  return createSelector(selectVideosListsSelector, (videos) =>
    videos.find((video) => key && video.meta?.edit?.id === key)
  )
}

/**
 * selectProjectVideosById - array of videos from a specific project
 */
export const selectByProjectId = (projectId: number) => {
  return createSelector(selectVideosListsSelector, (videos: Video[]) => {
    return videos.filter((video) => video.projectId === projectId)
  })
}

export const selectEnhancedVideoById = (id: number) => {
  return createSelector(selectVideosSelector, (state) => {
    const video = selectById(state, id)
    return video ? createEnhancedVideo(video) : null
  })
}
