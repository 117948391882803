// Downloads
// prettier-ignore
export const configBrandEventsDelete = [
  'youtube_video_destroyed',
] as const

export const configBrandEventsDownloads = [
  'video_download_update',
  'video_creation_update',
] as const

// Video Creation and Processing
export const configBrandEventsPipeline = [
  'pipeline_started',
  'pipeline_analytics_update',
  'pipeline_google_vi_update',
  'pipeline_thumbnails_update',
  'pipeline_tags_update',
  'pipeline_completed',
] as const

export const configBrandEvents = [
  ...configBrandEventsDelete,
  ...configBrandEventsPipeline,
  ...configBrandEventsDownloads,
] as const
