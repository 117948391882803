import { ServiceProfile } from '@tvi/types/serviceProfile'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useServiceProfilesProvider } from '../../serviceProfiles/providers/ServiceProfilesProvider'

export const usePlaylistFilters = () => {
  const location = useLocation()
  const history = useHistory()
  const { serviceProfiles } = useServiceProfilesProvider()
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )
  const queryString = query.toString()

  const playlistFilters = useMemo(
    () =>
      ((query?.get('filters') as string) ?? '')
        .split?.(',')
        .reduce((list: number[], group: string) => {
          const [key, value] = group.split(':')
          const filter = key === 'p' ? [Number(value)] : []
          return [...list, ...filter]
        }, []),
    [serviceProfiles, queryString]
  )

  const buildPlaylistFilters = useMemo(
    () => (selected: (string | number)[] | undefined) => {
      const ids = selected
        ? selected
        : serviceProfiles
            .filter((profile) => profile.isConnected)
            .map((profile) => profile.id)
      return ids.map((id) => `p:${id}`).join(',')
    },
    [serviceProfiles]
  )

  const profilesFromFilters = useMemo(
    () =>
      playlistFilters
        .map((filterId) => serviceProfiles.find(({ id }) => id === filterId))
        .filter((profile) => profile),
    [playlistFilters]
  ) as ServiceProfile[]

  const filterTitle = useMemo(() => {
    const len = profilesFromFilters.length ?? 0
    if (len === 1) return profilesFromFilters[0]?.name
    return 'All'
  }, [profilesFromFilters])

  const setPlaylistFilters = useCallback(
    (filters: string = '') => {
      if (filters) {
        query.set('filters', filters)
      } else {
        query.delete('filters')
      }
      history.push({ search: query.toString() })
    },
    [query, history]
  )

  return {
    playlistFilters,
    buildPlaylistFilters,
    setPlaylistFilters,
    profilesFromFilters,
    filterTitle,
  }
}

export default usePlaylistFilters
