import { PlaylistItem } from '@tvi/types/playlist'
import { VideoPlayer } from '@tvi/uikit'
import { FC } from 'react'
import { VideoPlayerContainer } from './PlaylistVideoPreview.style'

export type PlaylistVideoContentPreviewProps = {
  playlistVideo: PlaylistItem
}

const PlaylistVideoContentPreview: FC<PlaylistVideoContentPreviewProps> = ({
  playlistVideo,
}) => {
  return (
    <VideoPlayerContainer>
      <VideoPlayer
        microProgress={200}
        muted={true}
        playing={false}
        config={{ controls: true }}
        style={{
          width: '95%',
        }}
        url={`https://www.youtube.com/watch?v=${playlistVideo.id}`}
      />
    </VideoPlayerContainer>
  )
}

export default PlaylistVideoContentPreview
