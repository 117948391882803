import { createSelector } from '@reduxjs/toolkit'
import { Client } from '@tvi/types/client'
import toArray from 'lodash/toArray'
import { AppState } from '../reducers'

/**
 * selectClientsSelector - generic selection of clients (ids, entities)
 */
export const selectClientsSelector = (state: AppState) => state.clients

/**
 * hasClients - are there any clients
 */
export const hasClients = (state: AppState): boolean =>
  state?.clients?.ids?.length > 0

/**
 * clientList - array of clients
 */
export const clientListSelector = createSelector(
  selectClientsSelector,
  (clients): Client[] =>
    (toArray(clients?.entities || []) as Client[]).sort((a, b) =>
      b?.createdAt.localeCompare(a.createdAt)
    )
)

/**
 * activeClientSelector - return active client
 */
export const activeClientSelector = createSelector(
  (state: AppState): Client | null => state.clients.activeClient,
  clientListSelector,
  (activeClient, clients): Client | null => activeClient ?? clients?.[0]
)

/**
 * primaryClientSelector - return only primary client
 * for now just the first one
 */
export const primaryClientSelector = createSelector(
  activeClientSelector,
  (activeClient): Client | null => activeClient
)
