import { FC } from 'react'

export type CenterLayoutProps = {}

const CenterLayout: FC<CenterLayoutProps> = ({ children }) => {
  return (
    <main className="main">
      <div className="center-layout container">
        <main className="main">{children}</main>
      </div>
    </main>
  )
}

export default CenterLayout
