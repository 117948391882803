import { createAction } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'
import { ROUTES } from '../../../constants/paths'
import { NotAuthorizedError } from '../../../lib/errors'
import browserHistory from '../../../utils/browserHistory'
import { userByUID } from '../../auth/auth.saga'
import { authSelector, isAuthorizedSelector } from '../../auth/auth.selectors'
import { fetchBrands } from '../../brands/brands.saga'
import {
  hasBrands,
  selectPrimaryBrandByClientId,
} from '../../brands/brands.selectors'
import { fetchClients, initiateClient } from '../../clients/clients.saga'
import { primaryClientSelector } from '../../clients/clients.selectors'
import { fetchServiceProfiles } from '../../serviceProfiles/serviceProfiles.saga'
import { hasConnectedServiceSelector } from '../../serviceProfiles/serviceProfiles.selectors'

/*
 * This Saga Command coordinates what happens after login
 * A user must belong to a client/brand and have a valid session
 * - Check session
 * - Get Clients for user
 * - Get Brands for user
 * - Get Service Profiles (Connected Services)
 * - Service Profiles (does user have any connected services)
 * ---- if no then redirect them to connect
 * ---- if yes then redirect them to their dashboard (whatever state that is)
 */

export const key = 'command/afterLogin'

function* afterLoginCommand(): any {
  const isAuthorized = yield select(isAuthorizedSelector)
  // const isAuthorized = false
  const userHasBrands = yield select(hasBrands)

  if (!isAuthorized || !userHasBrands) {
    while (true) {
      yield put(fetchClients.action(void 0))
      yield take(fetchClients.fulfilled)
      const client = yield select(primaryClientSelector)
      if (!client) {
        throw new NotAuthorizedError()
      }
      const auth = yield select(authSelector)
      if (!auth.session?.uid) {
        throw new NotAuthorizedError()
      }
      if (!auth?.user?.email) {
        yield put(userByUID.action({ uid: auth.session.uid }))
      }
      yield put(fetchBrands.action({ id: client.id }))
      yield take(fetchBrands.fulfilled)
      let brand = yield select(selectPrimaryBrandByClientId(client.id))
      if (!brand) {
        yield put(initiateClient.action({ id: client.id, name: nanoid() }))
        const response = yield take(initiateClient.fulfilled)
        // brand = yield select(selectPrimaryBrandByClientId(client.id))
        brand = response?.payload?.brands?.[0]
        if (!brand) {
          throw new NotAuthorizedError()
        }
      }
      yield put(fetchServiceProfiles.action({ brandId: brand.id }))
      yield take(fetchServiceProfiles.fulfilled)
      break
    }
  }

  const hasConnectedServices: boolean = yield select(
    hasConnectedServiceSelector
  )

  const path = hasConnectedServices
    ? ROUTES.videos
    : ROUTES.settingsLinkedAccounts
  yield call(browserHistory.push, path)
}

/*
 * ======================================================
 * SAGA ACTIONS
 * ======================================================
 */

export const afterLoginCommandAction = createAction(key)

/*
 * ======================================================
 * SAGA LISTENERS
 * ======================================================
 */

export default function* afterLoginCommandSaga() {
  yield all([takeEvery(key, afterLoginCommand)])
}
