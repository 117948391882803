import eviApi from '@tvi/api-client'
import { RequestConfig } from '@tvi/api-client/TviApiService'
import { addRequestInterceptor, addResponseInterceptor } from '@tvi/http'
import * as appConfig from './constants/appConfig'
import {
  MissingAuthCodeError,
  NotAuthorizedError,
  NotFoundError,
  UnprocessibleError,
} from './lib/errors'
import http from './utils/http'
import { toCamelCaseObj, toSnakeCaseObj } from './utils/transform'

http.defaults.withCredentials = true

/*
 * Configure EVI API and any interceptors needed
 */
const configureAPI = () => {
  /*
   * Add Request Data Transform Interceptor
   * Ensure all data passed to the server is snake cased
   * TODO: This should only be used on EVI APIs
   */
  addRequestInterceptor(http)((req) => {
    if (req.data) {
      req.data = toSnakeCaseObj(req.data)
    }
    return req
  })

  /*
   * Add Response Data Transform Interceptor
   * Ensure all data passed received from the server is camelCased
   * TODO: This should only be used on EVI APIs
   */
  addResponseInterceptor(http)((res) => {
    if (res?.data) {
      try {
        res.data = toCamelCaseObj(res.data)
      } catch (error) {
        //
      }
    }
    return res
  })

  /*
   * Add +400 Status Errors Response Interceptor
   * Handle api errors
   */
  addResponseInterceptor(http)((res) => {
    try {
      if (res.status >= 400) {
        const message =
          res.data.error?.message ?? res.data.message ?? res.statusText

        if (res.status === 404) {
          throw new NotFoundError(message)
        }
        if (res.status === 401) {
          // handle un-authorized errors
          throw new NotAuthorizedError(message)
        }
        if (res.status === 422) {
          // for now send them to the login page with a redirect
          // but we should probably have this open a modal
          // where they can easily continue working after signin

          if (message === 'Missing authorization code') {
            // this is a special case that should be resovled in the api
            // where an OAuth token has expired and has not been refreshed
            throw new MissingAuthCodeError(message)
          }
          throw new UnprocessibleError(message)
        }
      }
    } catch (error) {
      throw error
    }
    return res
  })

  eviApi.configure({
    versions: {
      v1: appConfig.api.url,
    },
    requestInstance: (conf: RequestConfig) =>
      http({
        url: conf.url,
        method: conf.method,
        data: conf.body,
        onUploadProgress: conf.onUploadProgress,
        onDownloadProgress: conf.onDownloadProgress,
      }),
  })
}

export default configureAPI
