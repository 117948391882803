import { configureStore } from '@reduxjs/toolkit'
import * as Redux from 'redux'
import { createLogger } from 'redux-logger'
import { persistReducer } from 'redux-persist'
import MemoryStorage from 'redux-persist-memory-storage'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { appSession } from '../../constants/appConfig'
import { authReducer } from '../auth/auth.slice'
import { brandsReducer } from '../brands/brands.slice'
import { clientsReducer } from '../clients/clients.slice'
import reducers, { entities } from '../reducers'
import sagas from '../sagas'
import { uiReducer } from '../ui/ui.slice'
import { addStoreSessionInterceptor } from './middlewares/sessionInterceptor'

const sagaMiddleware = createSagaMiddleware()
const middlewares: Redux.Middleware[] = [sagaMiddleware]

if (process.env.REACT_APP_REDUX_LOGGER) {
  middlewares.push(
    createLogger({
      collapsed: true,
    })
  )
}

// prettier-ignore
export const store = configureStore({
  reducer: Redux.combineReducers({
    auth: persistReducer({
      key: `${appSession.key}/auth`,
      storage,
    }, authReducer),
    ui: persistReducer({
      key: `${appSession.key}/ui`,
      storage,
    }, uiReducer),
    brands: persistReducer({
      key: `${appSession.key}/brand`,
      storage,
    }, brandsReducer),
    clients: persistReducer({
      key: `${appSession.key}/clients`,
      storage,
    }, clientsReducer),
    entities: persistReducer({
      key: `${appSession.key}/entities`,
      storage: new MemoryStorage(),
      blacklist: ['brands', 'clients'],
    }, Redux.combineReducers(entities)),
  }),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ 
      thunk: false,
    }),
    ...middlewares,
  ],
})

sagaMiddleware.run(sagas)

export type RootState = ReturnType<typeof reducers>
export type AppDispatch = typeof store.dispatch

// ensure store is updated with a new session object
// everytime there is an api request
addStoreSessionInterceptor(store as any)
