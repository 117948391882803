import PreviewIcon from '@mui/icons-material/Visibility'
import { Box, LinearProgress, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Button, Checkbox } from '@tvi/uikit'
import { ChangeEvent, FC, useCallback } from 'react'
import { PlaylistVideoItem } from '../../../../redux/playlist-videos/playlistVideos.selectors'
import { formatDate } from '../../../../utils/date'
import { PlaylistListItemContainer } from './PlaylistListItem.style'

export type PlaylistListItemProps = {
  video: PlaylistVideoItem
  selected: boolean
  chooseVideo: (video: PlaylistVideoItem) => void
  onSelect: (video: PlaylistVideoItem) => void
  onDeselect: (video: PlaylistVideoItem) => void
  previewVideo: (video: PlaylistVideoItem) => void | undefined
}

const PlaylistListItem: FC<PlaylistListItemProps> = ({
  video,
  onSelect,
  onDeselect,
  chooseVideo,
  previewVideo = () => void 0,
  selected = false,
}) => {
  const theme = useTheme()
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const func = e.target.checked ? onSelect : onDeselect
      func(video)
    },
    [video]
  )

  return (
    <Box width="100%">
      <PlaylistListItemContainer className="playlist__list-item playlist__row">
        <div className="playlist__col playlist__col--name">
          <Checkbox
            checked={selected}
            onChange={handleChange}
            label={
              <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                  sx={{
                    color: theme.palette.primary.main,
                    width: 20,
                    height: 20,
                    minWidth: 0,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 14,
                    marginRight: 5,
                    ':hover': {
                      color: theme.palette.common.white,
                      filter: 'none',
                    },
                  }}
                  onClick={() => previewVideo(video)}
                >
                  <PreviewIcon fontSize="inherit" color="inherit" />
                </Button>
                <span>{video.title}</span>
              </Box>
            }
          />
        </div>
        {video.isDownloadPending && <span />}
        <Typography
          className="playlist__col playlist__col--date"
          variant="body2"
        >
          {formatDate(video.publishedAt)}
        </Typography>
        {video.isDownloadNone && (
          <Box className="playlist__col playlist__col--action">
            <Button
              onClick={() => chooseVideo(video)}
              variant="text"
              size="small"
              textAlign="right"
            >
              Choose Video
            </Button>
          </Box>
        )}
        {video.isDownloadPending && (
          <Box className="playlist__col playlist__col--action">
            <Typography
              px={10}
              textAlign={'right'}
              color="theme.palette.grey.600"
              fontWeight={600}
              variant="body2"
            >
              Pending
            </Typography>
          </Box>
        )}
        {video.isDownloadComplete && (
          <div className="playlist__col playlist__col--action">
            <Typography
              px={10}
              textAlign={'right'}
              color="success.main"
              fontWeight={600}
              variant="body2"
            >
              In queue
            </Typography>
          </div>
        )}
      </PlaylistListItemContainer>
      {video.isDownloadPending && <LinearProgress />}
    </Box>
  )
}

export default PlaylistListItem
