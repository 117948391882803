import camelCaseKeys from 'camelcase-keys'
import { snakeCase } from 'snake-case'
import snakeCaseKeys from 'snakecase-keys'

export const toCamelCaseObj = (obj: {}) =>
  camelCaseKeys(obj, { deep: true, pascalCase: false })

export const toSnakeCaseObj = (obj: {}) => snakeCaseKeys(obj)

export const toSnakeCaseArray = (obj: string[]) =>
  (obj as string[]).map((item) => snakeCase(item))

export const toSnakeCase = (obj: {} | string[]) =>
  Array.isArray(obj) ? toSnakeCaseArray(obj) : toSnakeCaseObj(obj)

export const toCapitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)

export const toGSResourceUrl = (value: string = '') => {
  if (!value) return value
  if (value.match(/storage\.googleapis\.com/)) return value
  return 'https://storage.googleapis.com/' + value.substring(5)
}
