import { all, fork } from 'redux-saga/effects'
import assetsSaga from '../assets/assets.saga'
import userSaga from '../auth/auth.saga'
import brandsSaga from '../brands/brands.saga'
import clientsSaga from '../clients/clients.saga'
import errorSaga from '../errors/errors.saga'
import playlistVideosSaga from '../playlist-videos/playlistVideos.saga'
import playlistsSaga from '../playlists/playlists.saga'
import projectsSaga from '../projects/projects.saga'
import serviceProfilesSaga from '../serviceProfiles/serviceProfiles.saga'
import videosSaga from '../videos/videos.saga'
import afterLoginCommandSaga from './commands/after-login.command'
import clientSwitchCommandSaga from './commands/client-switcher.command'

const rootSaga = function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(playlistsSaga),
    fork(playlistVideosSaga),
    fork(serviceProfilesSaga),
    fork(clientsSaga),
    fork(brandsSaga),
    fork(errorSaga),
    fork(projectsSaga),
    fork(videosSaga),
    fork(assetsSaga),

    // global coordinated tasks/commands below
    fork(afterLoginCommandSaga),
    fork(clientSwitchCommandSaga),
  ])
}

export default rootSaga
