import { Brand } from '@tvi/types/brand'
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { selectPrimaryBrandByClientId } from '../../../redux/brands/brands.selectors'
import { activeClientSelector } from '../../../redux/clients/clients.selectors'
import { afterLoginCommandAction } from '../../../redux/sagas/commands/after-login.command'
import { useAppSelector } from '../../../redux/utils/hooks'
import { brandVideoEvent } from '../../../redux/videos/videos.slice'
import useBrandWithEvents from '../effects/useBrandWithEvents'
import { BrandEventNotification } from '../models/brand'

export const BrandsContext = createContext<Brand | undefined>(undefined)

type Props = {}

/*
 * BrandsProvider
 * provide activeBrand (for now just the first one of the activeClient)
 * primary = the preferred brand
 * active = the selected brand
 * for now primary and active are the same until/unless there is
 * brand switcher
 */
export const BrandsProvider: FC<Props> = ({ children }) => {
  const activeClient = useAppSelector(activeClientSelector)
  const activeBrand = useAppSelector(
    selectPrimaryBrandByClientId(`${activeClient?.id}`)
  )

  const hasActives = Boolean(activeClient) && Boolean(activeBrand)
  const dispatch = useDispatch()

  /*
   * listen to brand events
   * on brand events update events that are affected
   */
  const handleBrandEvents = useCallback(
    (notification: BrandEventNotification) => {
      dispatch(brandVideoEvent(notification))
    },
    [dispatch]
  )

  useBrandWithEvents({
    brand: activeBrand as Brand,
    handleBrandEvents,
  })

  /*
   * Ensure there is an active client and active brand
   * if so then skip, if not it means we're in a weird
   * state so load fetch all clients and associated brands
   */
  useEffect(() => {
    if (hasActives) return
    dispatch(afterLoginCommandAction())
  }, [hasActives, dispatch])

  // todo: loader should go where null is
  return !hasActives ? null : (
    <BrandsContext.Provider value={activeBrand as Brand}>
      {children}
    </BrandsContext.Provider>
  )
}

export const useBrandsProvider = () => {
  const context = useContext<Brand | undefined>(BrandsContext)
  if (context === undefined) {
    throw new Error('useBrandsProvider must be used within a BrandsProvider')
  }

  return context
}

export default BrandsContext
