import { Brand } from '@tvi/types/brand'
import {
  ServiceProfile,
  ServiceProfileServices,
} from '@tvi/types/serviceProfile'
import React, { createContext, FC, useContext, useMemo } from 'react'
import useServiceProfiles from '../effects/useServiceProfiles'

export type ConnectServiceType = (
  email: string,
  brandId: number,
  service: ServiceProfileServices,
  name?: string
) => void

export type DisconnectServiceType = (id: number) => void
export type UpdateServiceType = (profile: ServiceProfile) => void

export type ServiceProfilesContextType = {
  email: string
  serviceProfiles: ServiceProfile[]
  connect: ConnectServiceType
  disconnect: DisconnectServiceType
  update: UpdateServiceType
  numConnected: number
  isLoading: boolean
  isLoaded: boolean
  brand: Brand
}

export const ServiceProfilesContext = createContext<
  ServiceProfilesContextType | undefined
>(undefined)

type Props = {}

export const ServiceProfilesProvider: FC<Props> = ({ children }) => {
  const {
    serviceProfiles,
    email,
    connect,
    disconnect,
    update,
    numConnected,
    isLoading,
    isLoaded,
    brand,
  } = useServiceProfiles()

  const value = useMemo(
    () => ({
      email,
      serviceProfiles,
      connect,
      disconnect,
      update,
      numConnected,
      isLoading,
      isLoaded,
      brand,
    }),
    [
      email,
      serviceProfiles,
      connect,
      disconnect,
      update,
      numConnected,
      isLoading,
      isLoaded,
      brand,
    ]
  )

  return (
    <ServiceProfilesContext.Provider value={value}>
      {children}
    </ServiceProfilesContext.Provider>
  )
}

export const useServiceProfilesProvider = () => {
  const context = useContext<ServiceProfilesContextType | undefined>(
    ServiceProfilesContext
  )
  if (context === undefined) {
    throw new Error(
      'useServiceProfilesProvider must be used within a ServiceProfilesProvider'
    )
  }
  return context
}

export default ServiceProfilesContext
