import CancelIcon from '@mui/icons-material/CancelOutlined'
import { CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, SxProps } from '@mui/system'
import { Button } from '@tvi/uikit'
import React, { FC, ReactNode } from 'react'
import { PALETTE } from '../../constants/uiColors'
import { DialogContainer } from './Dialog.style'

export interface DialogDeleteProps {
  handleClose(): void
  handleDelete(): void
  handleCancel(): void
  cancelBtnText?: string
  confirmBtnText?: string
  ContentComponent: ReactNode
  TitleComponent?: ReactNode
  Actions?: ReactNode
  isOpen?: boolean
  isDeleting?: boolean
  id?: string
  sx?: SxProps
}

export const DialogDelete: FC<DialogDeleteProps> = ({
  handleClose,
  handleDelete,
  handleCancel,
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Delete',
  ContentComponent = null,
  TitleComponent = null,
  isOpen = false,
  isDeleting = false,
  id = '',
  sx = {},
}) => {
  const theme = useTheme()

  return (
    <DialogContainer
      onClose={handleClose}
      aria-labelledby={id}
      open={isOpen}
      fullWidth={true}
      maxWidth="sm"
      sx={{
        '& .MuiDialogContent-root': {
          padding: theme.spacing(4),
        },
      }}
    >
      <Paper
        sx={{
          background: PALETTE.gray400,
          padding: theme.spacing(40),
          minHeight: 0,
          ...sx,
        }}
      >
        <Box my={20}>
          <Typography
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize={100}
            height={100}
            lineHeight={0}
            color="error.main"
          >
            {isDeleting && <CircularProgress color="inherit" size={80} />}
            {!isDeleting && <CancelIcon fontSize="inherit" />}
          </Typography>
        </Box>
        <DialogTitle sx={{ m: 0, mb: 6, p: 2 }}>
          {TitleComponent ? (
            TitleComponent
          ) : (
            <Typography variant="h1">Are you sure?</Typography>
          )}
        </DialogTitle>
        <DialogContent>{ContentComponent}</DialogContent>
        <DialogActions>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            gap={20}
            sx={{ py: 20 }}
          >
            <Button
              onClick={handleCancel}
              variant="contained"
              sx={{ background: theme.palette.grey[800] }}
            >
              {cancelBtnText}
            </Button>
            <Button
              onClick={handleDelete}
              color="secondary"
              variant="contained"
            >
              {confirmBtnText}
            </Button>
          </Box>
        </DialogActions>
      </Paper>
    </DialogContainer>
  )
}

export default DialogDelete
