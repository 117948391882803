import Typography from '@mui/material/Typography'
import { FC } from 'react'

const PlaylistListHeader: FC = () => {
  return (
    <>
      <div className="playlist__row playlist__row-header">
        <Typography
          className="playlist__col playlist__col--name"
          variant="subtitle2"
        >
          Name
        </Typography>
        {/* <Typography
          className="playlist__col playlist__col--views"
          variant="body2"
        >
          Views
        </Typography> */}
        <Typography
          className="playlist__col playlist__col--date"
          variant="body2"
        >
          Date Uploaded
        </Typography>
        <div className="playlist__col playlist__col--action" />
      </div>
    </>
  )
}

export default PlaylistListHeader
