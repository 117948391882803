import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice'
import { EntityState } from '@reduxjs/toolkit/src/entities/models'
import { Client } from '@tvi/types/client'
import { fetchClients, setActiveClient } from './clients.saga'

const CLIENTS_DATA_KEY = 'clients'

export type ClientsState = EntityState<Client> & {
  activeClient: Client | null
  ui: {
    isLoading: boolean
    error?: string
  }
}

/**
 * Clients Entity Adapter
 */
const clientsAdapter = createEntityAdapter<Client>({
  selectId: (client) => client.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

/**
 * Clients Slice
 */
export const clientsSlice = createSlice<
  ClientsState,
  SliceCaseReducers<ClientsState>,
  'clients'
>({
  name: CLIENTS_DATA_KEY,
  initialState: clientsAdapter.getInitialState({
    activeClient: null,
    ui: {
      isLoading: false,
      error: '',
    },
  }),
  reducers: {
    add: clientsAdapter.addOne,
    update: clientsAdapter.updateOne,
    remove: clientsAdapter.removeOne,
    set: clientsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.fulfilled, (state, action: any) => {
      clientsAdapter.upsertMany(state, action.payload)
      if (state.activeClient) return

      const clients = (action.payload as Client[]).sort((a, b) =>
        b?.createdAt.localeCompare(a.createdAt)
      ) as any

      state.activeClient = clients?.[0] ?? null
    })
    builder.addCase(setActiveClient.actionType, (state, action: any) => {
      state.activeClient = action.payload
    })
  },
})

export const clientsReducer = clientsSlice.reducer
