import { createSelector } from '@reduxjs/toolkit'
import { AppState } from '../reducers'

export const uiSelector = (state: AppState) => state.ui

/**
 * uiVideosPlaylistView - 'ui/select/playlist/view'
 */
export const uiVideosPlaylistView = createSelector(
  uiSelector,
  (ui) => ui.views.videos.playlist.view
)

/**
 * uiVideosAnalyzeView - 'ui/select/analyze/view'
 */
export const uiVideosAnalyzeView = createSelector(
  uiSelector,
  (ui) => ui.views.videos.analyze.view
)

/**
 * uiSettingsSelector - ui settings
 */
export const uiSettingsSelector = createSelector(
  uiSelector,
  (ui) => ui.settings
)

export type UiSelectorType = typeof uiVideosPlaylistView
