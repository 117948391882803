import { Redirect, Route, Switch } from 'react-router-dom'
import AuthRoute from './components/auth-route/AuthRoute'
import { ROUTES } from './constants/paths'
import Connect from './pages/connect/Connect'
import ErrorPage from './pages/error-page/ErrorPage'
import Login from './pages/login/Login'
import Logout from './pages/logout/Logout'
import Settings from './pages/settings/Settings'
// import SignUp from './pages/signup/SignUp'
import Videos from './pages/videos/Videos'

function AppRouter() {
  return (
    <Switch>
      {/* authorized routes */}
      {/* this redirect is temporary */}
      <AuthRoute exact path={ROUTES.app}>
        <Redirect to={ROUTES.videos} />
      </AuthRoute>
      <AuthRoute path={ROUTES.videos}>
        <Videos />
      </AuthRoute>
      <AuthRoute path={ROUTES.settings}>
        <Settings />
      </AuthRoute>
      <AuthRoute exact path={ROUTES.connect}>
        <Connect />
      </AuthRoute>
      {/* authorized routes End */}

      <Route exact path={ROUTES.home}>
        <Redirect to={ROUTES.videos} />
      </Route>
      <Route exact path={ROUTES.login}>
        <Login />
      </Route>
      {/* <Route exact path={ROUTES.signup}>
        <SignUp />
      </Route> */}
      <Route exact path={ROUTES.logout}>
        <Logout />
      </Route>
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  )
}

export default AppRouter
