import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewType } from '@tvi/uikit/components/toggle-view'

export interface UiState {
  settings: {
    showTips: boolean
    advancedView: boolean
  }
  views: {
    videos: {
      playlist: {
        view: ViewType
      }
      analyze: {
        view: ViewType
      }
    }
  }
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    settings: {
      showTips: true,
      advancedView: true,
    },
    views: {
      videos: {
        playlist: {
          view: 'grid',
        },
        analyze: {
          view: 'grid',
        },
      },
    },
  } as UiState,
  reducers: {
    updateUiSelectViewPlaylist: (state, action: PayloadAction<ViewType>) => {
      state.views.videos.playlist.view = action.payload
    },
    updateUiSelectViewAnalyze: (state, action: PayloadAction<ViewType>) => {
      state.views.videos.analyze.view = action.payload
    },
    updateSettings: (
      state,
      action: PayloadAction<Partial<UiState['settings']>>
    ) => {
      state.settings = {
        ...state.settings,
        ...action.payload,
      }
    },
  },
})

export const uiReducer = uiSlice.reducer
export const {
  updateUiSelectViewPlaylist,
  updateUiSelectViewAnalyze,
  updateSettings,
} = uiSlice.actions

export type UiUpdateActionType = typeof updateUiSelectViewPlaylist
