import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Video } from '@tvi/types/video'
import { ViewType } from '@tvi/uikit/components/toggle-view'
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ActionBar from '../../../../components/action-bar/ActionBar'
import LoaderGrid from '../../../../components/loader-grid/LoaderGrid'
import LoaderList from '../../../../components/loader-list/LoaderList'
import MediaListComponent from '../../../../components/media-list/MediaList'
import { ROUTES } from '../../../../constants/paths'
import { SelectedProvider } from '../../../../lib/selected/SelectedProvider'
import { useSelected } from '../../../../lib/selected/useSelected'
import {
  VideosBulkActionBarContainer,
  VideosNoListComponentContainer,
  VideosNoListComponentInfo,
} from '../../../../pages/videos/Videos.style'
import { uiVideosAnalyzeView } from '../../../../redux/ui/ui.selector'
import { updateUiSelectViewAnalyze } from '../../../../redux/ui/ui.slice'
import { selectVideosByBrandId } from '../../../../redux/videos/videos.selectors'
import { useBrandsProvider } from '../../../brands/components/BrandsProvider'
import useProjects from '../../../projects/effects/useProjects'
import { VideoEnhanced, videosSearchFilter } from '../../models/video'
import VideosBulkActionBar from '../videos-bulk-action/VideosBulkActionBar'
import VideosConfirmDelete from '../videos-confirm-delete/VideosConfirmDelete'
import VideosGrid from '../videos-grid/VideosGrid'
import VideosList from '../videos-list/VideosList'

type VideosModuleProps = {}

const VideosModule: FC<VideosModuleProps> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const brand = useBrandsProvider()
  const selectedView = useSelector(uiVideosAnalyzeView)
  const videos = useSelector(selectVideosByBrandId(brand.id))
  const [filteredVideos, setFilteredVideos] = useState<Video[]>(videos)
  const isGrid = useMemo(() => selectedView === 'grid', [selectedView])

  /**
   * key for rerenders
   */
  const videoCache = useMemo(() => JSON.stringify(videos), [videos])

  const selectedData = useSelected<Video, string>({
    list: filteredVideos,
    listKey: (item: Video): string => {
      return `${item.id}-${item.name}`
    },
  })

  const setView = useCallback(
    (view: ViewType) => dispatch(updateUiSelectViewAnalyze(view)),
    []
  )

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const query = value.toLocaleLowerCase()
      setFilteredVideos(videosSearchFilter(videos, query))
    },
    [videos]
  )

  const onItemClicked = useCallback(
    (video: VideoEnhanced) => {
      history.push(`${ROUTES.videos}/${video.id}`)
    },
    [history]
  )

  useEffect(() => setFilteredVideos(videos), [videoCache])

  // fetch all projects by brand
  const { isLoading } = useProjects({ excludeVideos: undefined })

  // prettier-ignore
  return (
    <Box className="videos-module">
      <SelectedProvider<Video, string> data={selectedData}>
        <MediaListComponent
          list={selectedData.all}
          isLoading={isLoading}
          selectedView={selectedView}
          GridComponent={
            <VideosGrid
              itemClicked={onItemClicked}
              list={filteredVideos}
            />
          }
          ListComponent={
            <VideosList
              itemClicked={onItemClicked}
              list={filteredVideos}
            />
          }
          LoadingComponent={
            isGrid ?
              <LoaderGrid num={9} col={3} /> :
              <LoaderList num={3} />
          }
          NoListComponent={
            <VideosNoListComponentContainer>
              <Typography variant="h3">
                Analyze &amp; Edit Videos
              </Typography>
              <VideosNoListComponentInfo>
                When videos are ready to go they will be added here.
                Click "Analyze &amp; Edit" to get started.
              </VideosNoListComponentInfo>
            </VideosNoListComponentContainer>
          }
          ActionBarComponent={
            <ActionBar
              className="action-bar"
              placeholder="Search for Videos"
              defaultView={selectedView}
              onSearch={onSearch}
              onView={setView}
              disabled={Boolean(!filteredVideos?.length)}
            />
          }
          ActionBarBulkComponent={
            <VideosBulkActionBarContainer>
              <VideosBulkActionBar DeleteConfirm={VideosConfirmDelete} />
              <Divider className="divider" flexItem light textAlign="center" />
            </VideosBulkActionBarContainer>
          }
        />
      </SelectedProvider>
    </Box>
  )
}

export default VideosModule
