import { Brand } from '@tvi/types/brand'
import { useEffect, useState } from 'react'
import { toCamelCaseObj } from '../../../utils/transform'
import {
  BrandEnhanced,
  BrandEventNotification,
  enhancedBrands,
} from '../models/brand'

/*
 * Listen to brand event notifications (currently through Pusher)
 * then dispatch actions that can react to specific brand events.
 * Typically these are videos of the brand
 */
const useBrandWithEvents = ({
  brand,
  handleBrandEvents,
}: {
  brand: Brand
  handleBrandEvents?(event: BrandEventNotification): void
}) => {
  const [enhancedBrand, setEnhancedBrand] = useState<BrandEnhanced>(brand)

  useEffect(() => {
    if (!brand) return

    const brandWithEvents = enhancedBrands(brand) as BrandEnhanced
    const subscriber = brandWithEvents.events!.subscribe(
      (event: BrandEventNotification) =>
        handleBrandEvents &&
        handleBrandEvents(toCamelCaseObj(event) as BrandEventNotification)
    )
    setEnhancedBrand(brandWithEvents)

    return () => subscriber.unsubscribe()
  }, [brand])

  return {
    brand: enhancedBrand,
  }
}

export default useBrandWithEvents
