import { Container } from '@mui/material'
import { Box } from '@mui/system'
import { ButtonBack } from '@tvi/uikit'
import { FC, ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router'
import { ROUTES_TYPES } from '../../constants/paths'
import { DetailLayoutTopMenu } from './DetailLayout.style'

export type DetailLayoutProps = {
  parentRoute?: ROUTES_TYPES
  parentRouteText?: string
  menu?: ReactElement
}

const DetailLayout: FC<DetailLayoutProps> = ({
  children,
  parentRoute = null,
  menu = null,
  parentRouteText = 'Back',
}) => {
  const history = useHistory()

  const handleBack = useCallback(() => {
    if (!parentRoute) return
    history.push(parentRoute)
  }, [parentRoute])

  return (
    <main className="main">
      <div className="detail-layout container">
        <DetailLayoutTopMenu className="detail-layout__top-menu">
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {parentRoute && (
                <ButtonBack onClick={handleBack} text={parentRouteText} />
              )}
              {menu && menu}
            </Box>
          </Container>
        </DetailLayoutTopMenu>
        <main className="main">{children}</main>
      </div>
    </main>
  )
}

export default DetailLayout
