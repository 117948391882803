import { Divider, Paper, useTheme } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Box, SxProps } from '@mui/system'
import React, { FC, ReactNode } from 'react'
import { DialogContainer } from './Dialog.style'
import { DialogHeader } from './DialogHeader'

export interface DialogStandardProps {
  handleClose(): void
  ContentComponent: ReactNode
  TitleComponent?: ReactNode
  Actions?: ReactNode
  isOpen?: boolean
  id?: string
  sx?: SxProps
}

export const DialogStandard: FC<DialogStandardProps> = ({
  handleClose,
  ContentComponent = null,
  TitleComponent = null,
  Actions = null,
  isOpen = false,
  id = '',
  sx = {},
}) => {
  const theme = useTheme()

  return (
    <DialogContainer
      onClose={handleClose}
      aria-labelledby={id}
      open={isOpen}
      fullWidth={true}
      maxWidth="sm"
    >
      <Paper sx={{ background: theme.palette.common.white, ...sx }}>
        <DialogHeader id={id} onClose={handleClose}>
          {TitleComponent}
        </DialogHeader>
        <DialogContent sx={{ minHeight: '30vh' }}>
          {ContentComponent}
        </DialogContent>
        <Divider sx={{ background: theme.palette.grey[300], my: 10 }} />
        {Actions && (
          <DialogActions>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              sx={{ py: 20 }}
            >
              {Actions}
            </Box>
          </DialogActions>
        )}
      </Paper>
    </DialogContainer>
  )
}

export default DialogStandard
