import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { FC, ReactNode } from 'react'
import './Connect.scss'

const ConnectCardList: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Table className="connect-card connect-card--list">
      <TableBody>{children}</TableBody>
    </Table>
  )
}

export default ConnectCardList
