export const ROUTES = {
  home: '/',
  app: '/app',
  login: '/login',
  videos: '/videos',
  projects: '/projects',
  signup: '/signup',
  logout: '/logout',
  connect: '/connect',
  settings: '/settings',
  settingsLinkedAccounts: '/settings/linked-accounts',
}

export type ROUTES_TYPES = keyof typeof ROUTES

// note: we can't really use REDIRECT cause these change
// depending on different states and what access
// the user will have to different features
// will be removed soon

export const REDIRECT = {
  afterSignUp: ROUTES.settingsLinkedAccounts,
  afterLogout: ROUTES.login,
  authRoute: ROUTES.login,
  toSignupFromLoginPage: ROUTES.signup,
}

export const ASSETS = {
  headerLogo: process.env.PUBLIC_URL + '/img/logo-white.svg',
}
