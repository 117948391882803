import Box from '@mui/material/Box'
import { Button } from '@tvi/uikit'
import { FC } from 'react'
import { useBrandsProvider } from '../../brands/components/BrandsProvider'
import { useServiceProfilesProvider } from '../providers/ServiceProfilesProvider'

export type ConnectActionModuleProps = {
  ButtonComponent?: typeof Button
}

const ConnectServiceActionModule: FC<ConnectActionModuleProps> = ({
  ButtonComponent = Button,
}) => {
  const brand = useBrandsProvider()
  const { email, connect } = useServiceProfilesProvider()

  return (
    <Box className="connect-module__actions">
      <ButtonComponent
        loading={!brand}
        onClick={() => connect(email, brand!.id, 'youtube')}
        size="small"
        variant="contained"
      >
        Connect A New Account
      </ButtonComponent>
    </Box>
  )
}

export default ConnectServiceActionModule
