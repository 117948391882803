import { Dictionary } from '@reduxjs/toolkit'
import { Entity } from '@tvi/types/entity'

/*
 * transformEntities
 * Transform entities mapper
 * typically used in a base entities selector hash
 * typically used to set computed properties on an entity
 */
export const transformEntities = <T = Entity, K = T>(
  entities: Dictionary<T>,
  transformFn: (entity: T) => K
) => {
  return Object.keys(entities).reduce<Dictionary<T | K>>(
    (mapped: Dictionary<T | K>, id: number | string) => {
      const entity = entities[id] as T
      return {
        ...mapped,
        [id]: transformFn(entity),
      }
    },
    {}
  )
}
