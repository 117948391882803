import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import {
  Autocomplete,
  Chip,
  Divider,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { Playlist, PlaylistItem } from '@tvi/types/playlist'
import { ServiceProfile } from '@tvi/types/serviceProfile'
import { Button } from '@tvi/uikit'
import { ViewType } from '@tvi/uikit/components/toggle-view'
import { flatten } from 'lodash'
import {
  ChangeEvent,
  FC,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink as RouterLink } from 'react-router-dom'
import ActionBar from '../../../../components/action-bar/ActionBar'
import LoaderGrid from '../../../../components/loader-grid/LoaderGrid'
import LoaderList from '../../../../components/loader-list/LoaderList'
import MediaListComponent, {
  MediaListRef,
} from '../../../../components/media-list/MediaList'
import { ROUTES } from '../../../../constants/paths'
import { SelectedProvider } from '../../../../lib/selected/SelectedProvider'
import { useSelected } from '../../../../lib/selected/useSelected'
import {
  VideosBulkActionBarContainer,
  VideosNoListComponentContainer,
  VideosNoListComponentInfo,
} from '../../../../pages/videos/Videos.style'
import {
  downloadVideoActions,
  fetchPlaylistItems,
} from '../../../../redux/playlists/playlists.saga'
import { uiVideosPlaylistView } from '../../../../redux/ui/ui.selector'
import { updateUiSelectViewPlaylist } from '../../../../redux/ui/ui.slice'
import { useBrandsProvider } from '../../../brands/components/BrandsProvider'
import { useServiceProfilesProvider } from '../../../serviceProfiles/providers/ServiceProfilesProvider'
import usePlaylistFilters from '../../effects/usePlaylistFilters'
import usePlaylists from '../../effects/usePlaylists'
import { playlistSearchFilter } from '../../models/playlist'
import PlaylistBulkActionBar from '../playlist-bulk-action-bar/PlaylistBulkActionBar'
import PlaylistGrid from '../playlist-grid/PlaylistGrid'
import PlaylistList from '../playlist-list/PlaylistList'
import PlaylistVideoPreview from '../playlist-video-preview/PlaylistVideoPreview'
import { PlaylistModuleContainer } from './PlaylistModule.style'

export type PlaylistModuleProps = {}

const PlaylistModule: FC<PlaylistModuleProps> = () => {
  const dispatch = useDispatch()
  const brand = useBrandsProvider()
  const selectedView = useSelector(uiVideosPlaylistView)
  const mediaListRef = useRef<MediaListRef>()
  const [selectedPlaylistItem, setSelectedPlaylistItem] =
    useState<PlaylistItem>()

  const {
    playlistFilters,
    profilesFromFilters,
    buildPlaylistFilters,
    setPlaylistFilters,
  } = usePlaylistFilters()

  const profiles = useServiceProfilesProvider()
  const { serviceProfiles, numConnected } = profiles
  const { playlists, isLoading } = usePlaylists({
    filters: playlistFilters,
    profiles,
  })

  /**
   * key for rerenders
   */
  const playlistCache = useMemo(() => JSON.stringify(playlists), [playlists])

  /**
   * selectedProfiles
   * list of Service Profiles from filters
   */
  const selectedProfiles = useMemo(
    () =>
      profilesFromFilters.map(({ id, name, isExpired }) => ({
        id,
        name,
        isExpired,
      })),
    [profilesFromFilters]
  )

  /**
   * filteredPlaylists
   * filtered by search and other possible filtering
   * prettier-ignore
   */
  const [filteredPlaylists, setFilteredPlaylists] =
    useState<Playlist[]>(playlists)

  const isGrid = useMemo(() => selectedView === 'grid', [selectedView])

  /**
   * selectedData
   * playlist items from filtered playlists, this becomes
   * the source of data for renders and selections
   */
  const selectedData = useSelected<PlaylistItem, PlaylistItem>({
    list: flatten(
      filteredPlaylists.map((playlist) => playlist.videos)
    ) as PlaylistItem[],
  })

  /**
   * onSearch
   * this is a bit clunky as we are really just filtering for now
   * ideally there is a true search in the future
   */
  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const query = value.toLocaleLowerCase()
      setFilteredPlaylists(playlistSearchFilter(playlists, query))
    },
    [playlists]
  )

  /**
   * handleChangeServiceProfile
   * when a selects a service profile (connection)
   * then update the filters to only show the playlists/videos
   * for that service profile
   */
  const handleChangeServiceProfile = useCallback(
    (_: any, profiles: Partial<ServiceProfile>[] = []) => {
      const filters = buildPlaylistFilters(
        (profiles || []).map((profile) => profile.id) as number[]
      )
      setPlaylistFilters(filters)
    },
    [serviceProfiles, setPlaylistFilters]
  )

  /**
   * handleChooseVideo
   * - if a preview is open then close it
   * - trigger processing on the selected playlist video
   */
  const handleChooseVideo = useCallback(
    (video: PlaylistItem) => {
      const { id: resourceId, serviceProfileId } = video
      handleClosePreview()
      dispatch(
        downloadVideoActions({
          brandId: brand.id,
          resourceId,
          serviceProfileId,
        })
      )
    },
    [brand, dispatch]
  )

  /**
   * bulkProcessVideo
   * fairly naive implementation for now
   * just loop through selected and trigger
   * the processing action on a playlist video
   */
  const bulkProcessVideo = useCallback(() => {
    Array.from(selectedData.selected.values())
      .filter((video) => video.isDownloadNone)
      .forEach((video) => {
        const { id: resourceId, serviceProfileId } = video
        dispatch(
          downloadVideoActions({
            brandId: brand.id,
            resourceId,
            serviceProfileId,
          })
        )
      })
  }, [brand, dispatch, selectedData.selected])

  /**
   * handlePreviewVideo
   * opens the playlist video in a modal
   */
  const handlePreviewVideo = useCallback(
    (video: PlaylistItem) => {
      setSelectedPlaylistItem(video)
    },
    [brand, dispatch]
  )

  /**
   * handleClosePreview
   */
  const handleClosePreview = useCallback(
    () => setSelectedPlaylistItem(undefined),
    []
  )

  /**
   * When a Playlist accordion is toggled "open"
   * then fetch the playlist items (videos)
   * playlist items (videos) are lazy loaded by default
   */
  const handleOpenPlaylist = useCallback(
    (playlist: Playlist) => {
      if (Boolean(playlist.videos?.length)) return
      if (Boolean(playlist.error)) return
      const { id: playlistId, serviceProfileId } = playlist
      dispatch(
        fetchPlaylistItems.action({
          brandId: brand.id,
          service: 'youtube',
          serviceProfileId,
          playlistId,
        })
      )
    },
    [brand, dispatch]
  )

  /**
   * switch between grid and list view
   */
  const setView = useCallback((view: ViewType) => {
    dispatch(updateUiSelectViewPlaylist(view))
  }, [])

  // todo: fix this, using playlists.length isn't correct
  // will need to redo how search works
  useEffect(() => setFilteredPlaylists(playlists), [playlistCache])

  return (
    <PlaylistModuleContainer className="videos-module videos-module--playlist">
      <SelectedProvider<PlaylistItem, PlaylistItem> data={selectedData}>
        {selectedPlaylistItem && (
          <PlaylistVideoPreview
            playlistVideo={selectedPlaylistItem}
            closePreview={handleClosePreview}
            chooseVideo={handleChooseVideo}
          />
        )}
        <MediaListComponent
          ref={mediaListRef as Ref<MediaListRef>}
          list={filteredPlaylists}
          isLoading={isLoading}
          selectedView={selectedView}
          GridComponent={
            <PlaylistGrid
              list={filteredPlaylists}
              chooseVideo={handleChooseVideo}
              previewVideo={handlePreviewVideo}
              handleOpenPlaylist={handleOpenPlaylist}
            />
          }
          ListComponent={
            <PlaylistList
              list={filteredPlaylists}
              chooseVideo={handleChooseVideo}
              previewVideo={handlePreviewVideo}
              handleOpenPlaylist={handleOpenPlaylist}
            />
          }
          // prettier-ignore
          LoadingComponent={
            isGrid ? 
              <LoaderGrid num={9} col={3} /> : 
              <LoaderList num={3} />
          }
          NoListComponent={
            <VideosNoListComponentContainer>
              <Typography variant="h3">
                You have no playlists or videos
              </Typography>
              <VideosNoListComponentInfo>
                {Boolean(numConnected) && (
                  <span>Add some videos to your playlists</span>
                )}
                {!Boolean(numConnected) && (
                  <>
                    <Typography mb={15}>
                      Your connections have expired, connect again to access
                      your playlists and videos
                    </Typography>
                    <Button
                      component={RouterLink}
                      size="small"
                      variant="contained"
                      to={ROUTES.settings}
                    >
                      Connect A New Account
                    </Button>
                  </>
                )}
              </VideosNoListComponentInfo>
            </VideosNoListComponentContainer>
          }
          ActionBarComponent={
            <ActionBar
              className="action-bar"
              placeholder="Search for Playlists or Videos"
              defaultView={selectedView}
              onSearch={onSearch}
              onView={setView}
              disabled={Boolean(!filteredPlaylists.length)}
            />
          }
          ActionBarBulkComponent={
            <Box>
              <VideosBulkActionBarContainer>
                <PlaylistBulkActionBar onProcess={bulkProcessVideo} />
                <Divider flexItem light textAlign="center" />
              </VideosBulkActionBarContainer>
              {serviceProfiles.length > 1 && (
                <Box display="flex" alignItems="center" my={40}>
                  <Autocomplete
                    multiple
                    size="small"
                    value={selectedProfiles}
                    popupIcon={
                      serviceProfiles.length ===
                      selectedProfiles.length ? null : (
                        <ArrowDropDownIcon />
                      )
                    }
                    clearIcon={
                      selectedProfiles.length < 2 ? null : <CloseIcon />
                    }
                    options={serviceProfiles.map(({ id, name, isExpired }) => ({
                      id,
                      name,
                      isExpired,
                    }))}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={handleChangeServiceProfile}
                    getOptionLabel={(option) => option.name as string}
                    getOptionDisabled={(option) =>
                      option.isExpired ||
                      Boolean(
                        profilesFromFilters.find(
                          (profile) => profile.id === option.id
                        )
                      )
                    }
                    renderInput={(params) => (
                      <>
                        {Boolean(numConnected) && (
                          <TextField
                            {...params}
                            disabled={true}
                            label="Filter By Accounts"
                            placeholder={
                              Boolean(selectedProfiles.length) ? '' : 'Accounts'
                            }
                          />
                        )}
                      </>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          color="primary"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Box>
              )}
            </Box>
          }
        />
      </SelectedProvider>
    </PlaylistModuleContainer>
  )
}

export default PlaylistModule
