import { createSelector } from '@reduxjs/toolkit'
import { SessionToken } from '@tvi/types/api'
import { hasBrands } from '../brands/brands.selectors'
import { hasClients } from '../clients/clients.selectors'
import { AppState } from '../reducers'
import { AuthState } from './auth.slice'

export const authSelector = (state: AppState): AuthState | void => state?.auth

export const sessionSelector = (state: AppState): SessionToken | void =>
  state?.auth?.session

/*
 * isAuthorizedSelector
 * must have a valid session
 */
export const isAuthorizedSelector = (state: AppState): boolean => {
  return Boolean(state?.auth?.session?.uid)
}

/*
 * authUser
 * authenticated user
 */
export const authUser = (state: AppState): AuthState['user'] => {
  return state?.auth?.user
}

/*
 * isAuthorizedStrictSelector
 * must have a valid non-expired session
 */
export const isAuthorizedStrictSelector = createSelector(
  isAuthorizedSelector,
  sessionSelector,
  (isAuthorized, session): boolean =>
    isAuthorized &&
    Boolean(session?.expiry) &&
    // unix time stamp to JS Date is > NOW
    new Date(session!.expiry * 1000) > new Date()
)

/*
 * isAuthorizedStrictestSelector
 * must have a valid, non expired session and belong to a client and brand
 */
export const isAuthorizedStrictestSelector = createSelector(
  isAuthorizedSelector,
  hasClients,
  hasBrands,
  (isAuthorized, clients, brands): boolean => isAuthorized && clients && brands
)

export const userEmailSelector = (state: AppState): string =>
  state.auth?.user!.email
