import Box, { BoxProps } from '@mui/material/Box'
import { FC } from 'react'
import { PALETTE } from '../../constants/uiColors'
import ContentContainer from '../content-container/ContentContainer'

type BackPanelLayoutProps = {} & BoxProps

const BackPanelLayout: FC<BackPanelLayoutProps> = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        ...rest.sx,
        height: '56px',
        borderBottom: '2px solid',
        borderBottomColor: PALETTE.gray500,
      }}
    >
      <ContentContainer
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {children}
      </ContentContainer>
    </Box>
  )
}

export default BackPanelLayout
