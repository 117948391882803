import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { Asset } from '@tvi/types/asset'
import { deleteAsset, fetchAssets, uploadAsset } from './assets.saga'

const ASSETS_DATA_KEY = 'assets'

/**
 * Playlists Entity Adapter
 */
const assetsAdapter = createEntityAdapter<Asset>({
  selectId: (asset) => asset.id,
  sortComparer: (a, b) => a.mimeType.localeCompare(b.mimeType),
})

/**
 * Playlists Slice
 */
export const assetsSlice = createSlice({
  name: ASSETS_DATA_KEY,
  initialState: assetsAdapter.getInitialState({
    ui: {
      isLoading: true,
    },
  }),
  reducers: {
    add: assetsAdapter.addOne,
    update: assetsAdapter.updateOne,
    remove: assetsAdapter.removeOne,
    set: assetsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteAsset.fulfilled, (state, action: any) => {
      assetsAdapter.removeOne(state, action.payload.id)
    })
    builder.addCase(uploadAsset.fulfilled, (state, action: any) => {
      assetsAdapter.addOne(state, action.payload)
    })
    builder.addCase(fetchAssets.fulfilled, (state, action: any) => {
      const { projectId } = action.meta.arg
      const assets = (action.payload as Asset[]).map((asset) => ({
        ...asset,
        projectId,
      }))
      assetsAdapter.upsertMany(state, assets)
      state.ui = {
        isLoading: false,
      }
    })
    builder.addMatcher(isAnyOf(fetchAssets.pending), (state) => {
      state.ui = {
        isLoading: true,
      }
    })
  },
})

export const assetsReducer = assetsSlice.reducer
