import { Typography, useTheme } from '@mui/material'
import { PlaylistItem } from '@tvi/types/playlist'
import { Button } from '@tvi/uikit'
import { FC, useEffect, useState } from 'react'
import DialogStandard from '../../../../components/dialog/DialogStandard'
import PlaylistVideoContentPreview from './PlaylistVideoPreviewContent'

export type PlaylistVideoPreviewProps = {
  playlistVideo: PlaylistItem
  closePreview(): void
  chooseVideo(playlistVideo: PlaylistItem): void
}

const PlaylistVideoPreview: FC<PlaylistVideoPreviewProps> = ({
  playlistVideo,
  chooseVideo,
  closePreview,
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState<boolean>()

  useEffect(() => {
    setIsOpen(Boolean(playlistVideo))
  }, [playlistVideo])

  return (
    <DialogStandard
      sx={{
        p: 20,
      }}
      isOpen={isOpen}
      handleClose={closePreview}
      TitleComponent={
        <Typography mb={20} color={theme.palette.common.black} fontWeight={600}>
          {playlistVideo.title}
        </Typography>
      }
      ContentComponent={
        playlistVideo ? (
          <PlaylistVideoContentPreview playlistVideo={playlistVideo} />
        ) : null
      }
      Actions={
        <>
          {playlistVideo.isDownloadNone && (
            <Button
              onClick={() => chooseVideo(playlistVideo)}
              variant="contained"
            >
              Choose Video
            </Button>
          )}
          {!playlistVideo.isDownloadNone && (
            <Button onClick={() => closePreview()} variant="outlined">
              Close
            </Button>
          )}
        </>
      }
    />
  )
}

export default PlaylistVideoPreview
