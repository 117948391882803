import { toArray } from 'lodash'
import { TimeObject } from '@tvi/types/timeObject'

type OffsetTimeType = Pick<TimeObject, 'startTimeOffset'>

export const stdPrecision = (num: number) => Number(num.toPrecision(5))

export interface SecondTimeObject {
  startTime?: string
  endTime?: string
  startTimeOffset: number
  endTimeOffset: number
  startTimeSeconds?: number
  endTimeSeconds?: number
  displayStartTime?: string
  displayEndTime?: string
  name?: string
}

export type SecondTimeRecord = Record<number, TimeObject>

export const timeObjTotimeArray = <T = SecondTimeRecord, K = TimeObject>(
  obj: SecondTimeRecord
): K[] => {
  if (!obj) return []
  return (Object.keys(obj) as Array<keyof T>)
    .reduce<any>((arr, key: any) => [...arr, obj[key]], [])
    .sort(secondTimeObjectSort)
}

export const secondTimeObjectSort = (a: TimeObject, b: TimeObject) =>
  parseFloat(`${a.startTimeOffset}`) - parseFloat(`${b.startTimeOffset}`)

export const getObjFromTime = <T = {}>(
  obj: (T & OffsetTimeType)[],
  startTimeOffset: number
): (T & OffsetTimeType) | undefined => {
  if (!obj || !obj.length) return undefined
  return obj.find(
    (item) =>
      parseFloat(`${stdPrecision(item.startTimeOffset)}`) ===
      stdPrecision(startTimeOffset)
  )
}

export const getClosestObjToTime = <T extends TimeObject>(
  obj: T[],
  seconds: number
): T | undefined => {
  if (!obj || !obj.length) return undefined
  return obj.find((item) => {
    return (
      stdPrecision(item.startTimeOffset) <= stdPrecision(seconds) &&
      stdPrecision(item.endTimeOffset) >= stdPrecision(seconds)
    )
  })
}

export const getObjsBetweenTime = (
  obj: OffsetTimeType[],
  startTimeOffset: number,
  endTimeOffset: number
): OffsetTimeType[] | undefined => {
  if (!obj || !obj.length) return undefined
  return obj.filter(
    (item) =>
      parseFloat(`${stdPrecision(item.startTimeOffset)}`) >=
        stdPrecision(startTimeOffset) &&
      parseFloat(`${stdPrecision((item as any).startTimeOffset)}`) <
        stdPrecision(endTimeOffset)
  )
}

export const getFirstTimeObjInTimeHash = (
  obj: SecondTimeRecord
): SecondTimeObject | undefined => {
  if (!obj || !Object.keys(obj)?.length) return undefined
  return toArray(obj).sort(secondTimeObjectSort).shift()
}
