import { Cut } from '@tvi/types/cuts'
import { Scene } from '@tvi/types/scene'
import isNil from 'lodash.isnil'
import omitBy from 'lodash.omitby'
import { quartile } from '../../../utils/number'
import { uriCleanQuery } from '../../../utils/uri'

const defaultThumbnail =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mMM/w4AAasBUOVnpEsAAAAASUVORK5CYII='

const getThumbnail = (scene: Scene): string => {
  return uriCleanQuery(scene?.thumbnailUri ?? defaultThumbnail)
}

export const sortByScore = (type: 'asc' | 'desc' = 'asc') => {
  const asc = type === 'asc'
  return (a: Scene, b: Scene) =>
    ((asc ? a : b).score as number) - ((asc ? b : a).score as number)
}

export const createVideoScene = (
  scene: Scene,
  index: number,
  numScenes: number
): Scene => {
  const name = scene.name ?? `scene ${index + 1}`
  const thumbnailUri = getThumbnail(scene)
  const quartileIndex = quartile(index + 1, numScenes)

  return omitBy(
    {
      ...scene,
      index,
      name,
      thumbnailUri,
      quartileIndex,
    },
    isNil
  ) as unknown as Scene
}

export const createVideoScenes = (scenes: Scene[]): Scene[] => {
  const len = scenes?.length ?? 0
  return scenes.map((scene, index) => createVideoScene(scene, index, len))
}

export const createVideoScenesFromCuts = (cuts: Cut[]): Scene[] => {
  const len = cuts?.length ?? 0
  return cuts?.map((cut) => {
    const { index, startTimeOffset, endTimeOffset, thumbnailUri, tagList } = cut
    return createVideoScene(
      {
        index,
        startTimeOffset,
        endTimeOffset,
        thumbnailUri,
        tagList,
        cuts: [cut],
      } as Scene,
      index as number,
      len
    )
  }) as Scene[]
}
