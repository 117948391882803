import { createAction } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import { all, put, select, take, takeEvery } from 'redux-saga/effects'
import { NotAuthorizedError } from '../../../lib/errors'
import { fetchBrands } from '../../brands/brands.saga'
import { selectPrimaryBrandByClientId } from '../../brands/brands.selectors'
import { initiateClient } from '../../clients/clients.saga'
import { activeClientSelector } from '../../clients/clients.selectors'
import { fetchServiceProfiles } from '../../serviceProfiles/serviceProfiles.saga'

export const key = 'command/clientSwitcher'

/**
 * afterClientSwitchedCommand
 * after a user has switched clients, we need to ensure
 * that it has brands, we select the primary brand
 * and load the service profiles for that brand
 * todo: we may want to use query params to update state
 * instead of relying on redux. This will change url structure
 * FLOW:
 * - remove needed query params
 * - get active client
 * - fetch brands for that client
 * - get primary/active brand
 * - get service profiles for that brand
 * - wait until service profiles has been fulfilled
 * - break
 */
export function* afterClientSwitchedCommand(): any {
  while (true) {
    const client = yield select(activeClientSelector)
    yield put(fetchBrands.action({ id: client.id }))
    yield take(fetchBrands.fulfilled)
    let brand = yield select(selectPrimaryBrandByClientId(client.id))
    if (!brand) {
      yield put(initiateClient.action({ id: client.id, name: nanoid() }))
      const response = yield take(initiateClient.fulfilled)
      // brand = yield select(selectPrimaryBrandByClientId(client.id))
      brand = response?.payload?.brands?.[0]
      if (!brand) {
        throw new NotAuthorizedError()
      }
    }
    yield put(fetchServiceProfiles.action({ brandId: brand.id }))
    yield take(fetchServiceProfiles.fulfilled)
    break
  }
}

/*
 * ======================================================
 * SAGA ACTIONS
 * ======================================================
 */

export const afterClientSwitchedCommandAction = createAction(key)

/*
 * ======================================================
 * SAGA LISTENERS
 * ======================================================
 */

export default function* clientSwitcherCommandSaga() {
  yield all([takeEvery(key, afterClientSwitchedCommand)])
}
