/// <reference types='../../../types' />

import { Score } from '@tvi/types/score'
import * as kurtosis from 'compute-kurtosis'
import * as skewness from 'compute-skewness'
import isEmpty from 'lodash.isempty'
import toArray from 'lodash.toarray'
import { VideoEnhanced } from '../models/video'

export const savg = (n1: number, n2: number) => (n1 + n2) / 2
export const normalDist = (
  x: number,
  mean: number,
  sd: number,
  cumulative: number
) => {
  if (isNaN(x) || isNaN(mean) || isNaN(sd)) return 0
  if (sd <= 0) return 0
  return cumulative ? jstat.ncdf(x, mean, sd) : jstat.pdf(x, mean, sd)
}
export const getNumWithSetDec = (num: number, numOfDec = 100) => {
  const pow10s = 10 ** (numOfDec || 0)
  return numOfDec ? Math.round(pow10s * num) / pow10s : num
}
export const getAverageFromNumArr = (numArr: number[], numOfDec = 100) => {
  if (!Array.isArray(numArr)) {
    return false
  }
  let i = numArr.length
  let sum = 0
  while (i--) {
    sum += numArr[i]
  }
  return getNumWithSetDec(sum / numArr.length, numOfDec)
}
export const getVariance = (numArr: number[], numOfDec = 100) => {
  if (!Array.isArray(numArr)) {
    return 0
  }
  const avg = getAverageFromNumArr(numArr, numOfDec) || 0
  let i = numArr.length
  let v = 0
  while (i--) {
    v += (numArr[i] - avg) ** 2
  }
  v /= numArr.length
  return getNumWithSetDec(v, numOfDec)
}
export const getStandardDeviation = (numArr: number[], numOfDec = 100) => {
  if (!Array.isArray(numArr)) {
    return false
  }
  const stdDev = Math.sqrt(getVariance(numArr, numOfDec))
  return getNumWithSetDec(stdDev, numOfDec)
}
export const average = (data: number[]) =>
  data.reduce((sum, value) => sum + value) / data.length
export const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0)
export const mean = (arr: number[]) => sum(arr) / arr.length
export const standardDeviation = (arr: number[]) => {
  const n = arr.length
  const m = mean(arr)
  return Math.sqrt(
    arr.map((x) => Math.pow(x - m, 2)).reduce((a, b) => a + b) / n
  )
}
export const sumSquare = (args: number[]) => Math.hypot(...args) ** 2
export const avg = (n1: number, n2: number) => (n1 + n2) / 2
export const se = (n: number) => 1 / Math.sqrt(n)
export const smoothedZScore = (
  y: number[],
  lag = 2,
  threshold = 1,
  influence = 1
) => {
  if (y === undefined || y.length < lag + 2) {
    throw new AnalyticsScoringError(
      `## y data array to short(${y.length}) for given lag of ${lag}`
    )
  }
  const signals = Array(y.length).fill(0)
  const filteredY = y.slice(0)
  const lead_in = y.slice(0, lag)
  const avgFilter = []
  avgFilter[lag - 1] = mean(lead_in)
  const stdFilter = []
  stdFilter[lag - 1] = standardDeviation(lead_in)
  for (let i = lag; i < y.length; i++) {
    if (Math.abs(y[i] - avgFilter[i - 1]) > threshold * stdFilter[i - 1]) {
      if (y[i] > avgFilter[i - 1]) {
        signals[i] = +1
      } else {
        signals[i] = -1
      }
      filteredY[i] = influence * y[i] + (1 - influence) * filteredY[i - 1]
    } else {
      signals[i] = 0
      filteredY[i] = y[i]
    }
    const y_lag = filteredY.slice(i - lag, i)
    avgFilter[i] = mean(y_lag)
    stdFilter[i] = standardDeviation(y_lag)
  }
  return signals
}
const jstat = {
  erfc: function erfc(x: number) {
    return 1 - this.erf(x)
  },
  erfcinv: function erfcinv(p: number) {
    var j = 0
    var x, err, t, pp
    if (p >= 2) return -100
    if (p <= 0) return 100
    pp = p < 1 ? p : 2 - p
    t = Math.sqrt(-2 * Math.log(pp / 2))
    x =
      -0.70711 *
      ((2.30753 + t * 0.27061) / (1 + t * (0.99229 + t * 0.04481)) - t)
    for (; j < 2; j++) {
      err = this.erfc(x) - pp
      x += err / (1.12837916709551257 * Math.exp(-x * x) - x * err)
    }
    return p < 1 ? x : -x
  },
  erf: function erf(x: number) {
    var cof = [
      -1.3026537197817094, 6.4196979235649026e-1, 1.9476473204185836e-2,
      -9.561514786808631e-3, -9.46595344482036e-4, 3.66839497852761e-4,
      4.2523324806907e-5, -2.0278578112534e-5, -1.624290004647e-6,
      1.30365583558e-6, 1.5626441722e-8, -8.5238095915e-8, 6.529054439e-9,
      5.059343495e-9, -9.91364156e-10, -2.27365122e-10, 9.6467911e-11,
      2.394038e-12, -6.886027e-12, 8.94487e-13, 3.13092e-13, -1.12708e-13,
      3.81e-16, 7.106e-15, -1.523e-15, -9.4e-17, 1.21e-16, -2.8e-17,
    ]
    var j = cof.length - 1
    var isneg = false
    var d = 0
    var dd = 0
    var t, ty, tmp, res

    if (x < 0) {
      x = -x
      isneg = true
    }

    t = 2 / (2 + x)
    ty = 4 * t - 2

    for (; j > 0; j--) {
      tmp = d
      d = ty * d - dd + cof[j]
      dd = tmp
    }

    res = t * Math.exp(-x * x + 0.5 * (cof[0] + ty * d) - dd)
    return isneg ? res - 1 : 1 - res
  },
  pdf: function pdf(x: number, mean: number, std: number) {
    return Math.exp(
      -0.5 * Math.log(2 * Math.PI) -
        Math.log(std) -
        Math.pow(x - mean, 2) / (2 * std * std)
    )
  },
  cdf: function cdf(x: number, mean: number, std: number) {
    return 0.5 * (1 + this.erf((x - mean) / Math.sqrt(2 * std * std)))
  },
  inv: function (p: number, mean: number, std: number) {
    return -1.41421356237309505 * std * this.erfcinv(2 * p) + mean
  },
  mean: function (mean: number /*, std*/) {
    return mean
  },
  median: function median(mean: number /*, std*/) {
    return mean
  },
  mode: function (mean: number /*, std*/) {
    return mean
  },
  ncdf: function (x: number, mean: number, std: number) {
    x = (x - mean) / std
    var t = 1 / (1 + 0.2315419 * Math.abs(x))
    var d = 0.3989423 * Math.exp((-x * x) / 2)
    var prob =
      d *
      t *
      (0.3193815 +
        t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))))
    if (x > 0) prob = 1 - prob
    return prob
  },
}

export const AnalyticsUtils = {
  mathjs: {
    mean: mean,
    std: standardDeviation,
    variance: (arr: number[]) => {
      var m = mean(arr)
      return mean(
        arr.map(function (num) {
          return Math.pow(num - m, 2)
        })
      )
    },
  },
  kurtosis,
  skewness,
}

export class AnalyticsScoringError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AnalyticsScoringError'
  }
}

export class AnalyticsComputeACFScoringError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AnalyticsComputeACFScoringError'
  }
}

export interface BaseRetentionObj {
  index: number
  relative: number
  absolute: number
  nlog: number
  dropoff: number
  userloss: number
  r_userloss: number
  duration: number
  seconds: number
  startSeconds: number
  rowLength: number
  next?: BaseRetentionObj
}

export const retentionAnalytics = (
  index: number,
  rel: number,
  abs: number,
  next: BaseRetentionObj,
  type = 'rel',
  duration: number,
  rowLength: number,
  seconds: number,
  startSeconds: number
) => {
  const retention = type === 'rel' ? rel : abs

  // retention should not go below zero
  // and we can"t take the log(0) so use a very small substitute
  let nlog = Math.log(Math.max(0.00001, retention))
  const idx = index + 1

  return {
    index: idx,
    relative: rel,
    absolute: abs,
    nlog,
    dropoff: next
      ? parseFloat((-1 * (next.nlog / nlog - 1)).toFixed(3)) * 100
      : 0,
    userloss: next ? next.nlog / nlog : 0,
    r_userloss: next ? 1 / (next.nlog / nlog) : 0,
    rowLength,
    duration,
    seconds,
    startSeconds,
  }
}

export const retentionAnalyticsFromArray = (
  [index, rel, abs]: [index: number, rel: number, abs: number],
  next: any,
  type = 'rel',
  duration: number,
  rowLength: number,
  seconds: number,
  startSeconds: number
): BaseRetentionObj => {
  const r = parseFloat(String(rel).trim())
  const a = parseFloat(String(abs).trim())

  return retentionAnalytics(
    parseInt(`${index}`),
    r,
    a,
    next,
    type,
    duration,
    rowLength,
    seconds,
    startSeconds
  )
}

const computeACF = (
  base: Array<BaseRetentionObj>,
  baseIndex: number,
  propKey: keyof BaseRetentionObj = 'userloss',
  range = 10,
  acfType: 'acf' | 'racf' = 'acf'
) => {
  const baseRange = (a: number, b: number) => base.slice(a, b)
  const nBase = baseRange(0, base.length - 1)
  const calcMean = mean(nBase.map<number>((b) => b[propKey] as number))
  const n = nBase.length
  const s_e = 1 / Math.sqrt(nBase.length)
  const variance = getVariance(nBase.map<number>((b) => b[propKey] as number))
  const devsq = variance * nBase.length

  const sumproduct = (
    a: Array<BaseRetentionObj>,
    b: Array<BaseRetentionObj>,
    plus: number = 0
  ) => {
    var inc = 0

    return a.reduce((acc, c) => {
      const i = inc
      inc = inc + 1

      if (!b[i]) {
        throw new AnalyticsComputeACFScoringError(
          `ComputeACF failed, expecting there to be enough retention objects to calculate sumproduct, ${i}, ${c}`
        )
      }
      return (
        acc +
        ((c[propKey] as number) + plus) * ((b[i][propKey] as number) + plus)
      )
    }, 0)
  }

  const calcSumList = (
    nameKey = 'k',
    population = 100,
    startIndex = 1,
    divisor = n
  ): Record<string, number> => {
    return [...Array(population).keys()].reduce(
      (a, ii) => ({
        ...a,
        [`${nameKey}${ii + startIndex}`]:
          sumproduct(
            nBase.slice(0, population - ii - 1),
            nBase.slice(ii + 1, population),
            -calcMean
          ) / divisor,
      }),
      {}
    )
  }

  const crit = jstat.inv(1 - 0.1 / 2, 0, s_e)
  const kCalcs = calcSumList('k', nBase.length, 1, devsq)

  const kNormals = (
    inputCalcs: Record<string, number>,
    s_e: number
  ): Record<string, number> => {
    const a = toArray(inputCalcs)
    return a.reduce(
      (k, a, i) => ({
        ...k,
        [`kn${i + 1}`]: 1 - normalDist(a, 0, s_e, 1),
      }),
      {}
    )
  }

  const kBoolean = (
    inputCalcs: Record<string, number>,
    crit: number
  ): Record<string, boolean> => {
    const a = toArray(inputCalcs)
    return a.reduce(
      (k, a, i) => ({
        ...k,
        [`kb${i + 1}`]: a > crit ? true : false,
      }),
      {}
    )
  }

  const sumAcfDivisor = (start = 0, end = 0) => {
    return toArray(kCalcs)
      .slice(start, end)
      .reduce((a, c) => a + c, 0)
  }

  const sumRacfDivisor = (start = 0, end = 0) => {
    return toArray(kCalcs)
      .slice(start, end)
      .reduce((a, c) => a + c, 0)
  }

  const sumAcf = (index = 0, offsetIdx = 0) => {
    const arr = toArray(kCalcs).slice(0, index)
    const aggregate = arr.slice(0, index).reduce((a, c, innerIndex) => {
      const i = 1 + innerIndex + offsetIdx

      // this is to account for issues with the spreadsheet formulas
      if (!base[i] || !base[i][propKey]) {
        return a + (base[i - innerIndex - 1][propKey] as number) * c
      }
      return a + (base[i][propKey] as number) * c
    }, 0)

    return (base[0 + offsetIdx][propKey] as number) + aggregate
  }

  const sumRacf = (xIndex = 0, yIndex = 0) => {
    const aggregate = toArray(kCalcs)
      .slice(0, xIndex)
      .reduce((a, c, innerIndex) => {
        let i = innerIndex + 1
        return a + (base[yIndex - i][propKey] as number) * c
      }, 0)
    return (base[yIndex][propKey] as number) + aggregate
  }

  const sceneAcfValues = (index: number, offsetIdx: number) => {
    const divisor = 1 + sumAcfDivisor(0, index + 1)
    return sumAcf(index + 1, offsetIdx) / divisor
  }

  const sceneRacfValues = (
    xIndex: number,
    yIndex: number,
    sceneRange: number
  ) => {
    // todo: this should be configurable??
    if (yIndex === 0) {
      return base[0][propKey]
    }

    let start = 0
    let end = xIndex + 1
    let dStart = end

    if (yIndex < sceneRange && xIndex >= yIndex) {
      end = yIndex
      dStart = end
    }

    const denominator = sumRacf(dStart, yIndex)
    const divisor = 1 + sumRacfDivisor(start, end)
    return denominator / divisor
  }

  const calcScenes = (
    propKey = 'userloss',
    startIndex = 1,
    sceneRange = 1,
    offsetIdx = 0
  ): Record<string, number> => {
    const valuesFunc = propKey === 'userloss' ? sceneAcfValues : sceneRacfValues
    return [...Array(sceneRange).keys()].reduce((a, ii, index) => {
      return {
        ...a,
        [`${acfType}${ii + startIndex}_scene`]: valuesFunc(
          index,
          offsetIdx,
          sceneRange
        ),
      }
    }, {})
  }

  const kn = kNormals(kCalcs, s_e)
  const kb = kBoolean(kCalcs, crit)

  return {
    mean: calcMean,
    devsq,
    n,
    s_e,
    crit,
    s0: variance,
    ...kCalcs,
    ...kn,
    ...kb,
    ...calcScenes(propKey, 1, range, baseIndex),
  }
}

export const computeRetentionAnalytics = (
  video: VideoEnhanced,
  opts = { windsor: 5 }
): Score[] => {
  if (isEmpty(video.scenes)) return []
  if (isEmpty(video.report?.data)) return []

  const windsor = video.meta ? video.meta.windsor || opts.windsor : 5
  const totalDuration = video.duration
  const report = video.report
  const rows = report?.data?.details.rows || []
  const rowLength = rows.length
  const getSeconds = (i: number) =>
    Number(((totalDuration / rowLength) * (i + 1)).toFixed(2))
  const getStartSeconds = (i: number) =>
    Number(((totalDuration / rowLength) * i).toFixed(2))

  // ===============================================
  // used to compute the scene correlation and subsequent scores
  // these are the raw analytics and some dependent factors

  const baseAnalytics: Array<BaseRetentionObj> = rows.map(
    (r: any, i: number) => {
      const seconds = getSeconds(i)
      const startSeconds = getStartSeconds(i)
      const nextSeconds = rows[i + 1] ? getSeconds(i + 1) : 0
      const nextStartSeconds = rows[i + 1] ? getStartSeconds(i + 1) : 0
      const next = rows[i + 1]
        ? retentionAnalyticsFromArray(
            rows[i + 1],
            null,
            'rel',
            totalDuration,
            rowLength,
            nextSeconds,
            nextStartSeconds
          )
        : null

      return retentionAnalyticsFromArray(
        r,
        next,
        'rel',
        totalDuration,
        rowLength,
        seconds,
        startSeconds
      )
    }
  )

  // ===============================================
  // compute ACF / rACF number

  const acfVal = (data: Record<string, boolean>, range: number) => {
    let j = 1
    const len = range
    for (j; j <= len; j++) {
      if (data[`kb${j}`] === false) {
        return j - 1
      }
    }
  }

  // ===============================================
  // compute TVI

  const computeTVI = (data: { scene_combined: number }[], idx: number) => {
    const range = data.length
    const sIdx = idx === 0 ? 0 : 1
    const eIdx = idx === 0 ? range - 2 : range - 1 // arrays are zero index so remove the extra 1
    const ag = data[idx].scene_combined
    const combinedInRange = data.slice(sIdx, eIdx).map((d) => d.scene_combined)
    const min = Math.min(...combinedInRange)
    const max = Math.max(...combinedInRange)
    const val = Math.round(((ag - min) / (max - min)) * 100) // should TVI be rounded??
    return val > 100 ? 0 : val
  }

  // ===============================================
  // compute TVI

  const computeAdjustedTVI = (
    data: { windsoredTvi: number }[],
    idx: number
  ) => {
    const range = data.length
    const ag = data[idx].windsoredTvi
    const combinedInRange = data.slice(0, range - 1).map((d) => d.windsoredTvi)
    const min = Math.min(...combinedInRange)
    const max = Math.max(...combinedInRange)
    const val = Math.round(((ag - min) / (max - min)) * 100) // should TVI be rounded??
    return val > 100 ? 100 : val
  }

  // ===============================================
  // decorate baseAnalytics with ACF

  const data = baseAnalytics.map((r: any, i: number) => {
    // const range = scenes.length
    const range = baseAnalytics.length
    const racf = computeACF(
      baseAnalytics,
      i,
      'r_userloss',
      range,
      'racf'
    ) as any
    const acf = computeACF(baseAnalytics, i, 'userloss', range, 'acf') as any
    const aVal = Math.max(1, acfVal(acf, range) || 0)
    const rVal = Math.max(1, acfVal(racf, range) || 0)

    if (isNaN(aVal) || isNaN(rVal)) {
      throw new AnalyticsScoringError(
        `Failed ACF/rACF value: ${JSON.stringify(baseAnalytics)}`
      )
    }

    const combined = savg(
      1 / racf[`racf${rVal}_scene`],
      acf[`acf${aVal}_scene`]
    )

    return {
      ...r,
      // todo: test why we need this (infinity - likely divide by zero)
      scene_combined: combined > Number.MAX_SAFE_INTEGER ? 0 : combined,
      range,
      acf,
      racf,
      acfVal: aVal,
      racfVal: rVal,
    }
  })

  const scoredData = data.map((d, idx) => {
    const tvi = Math.max(0, computeTVI(data, idx))

    return {
      tvi,
      windsoredTvi: tvi < windsor ? windsor : tvi,
      ...d,
    }
  })

  const adjustedScoredData = scoredData.map((d, idx) => {
    const adjustedTvi = computeAdjustedTVI(scoredData, idx)
    return {
      adjustedTvi,
      ...d,
    }
  })

  return adjustedScoredData
}

// eslint-disable-next-line
const ctx: Worker = self as any
ctx.addEventListener('message', (e) => {
  const { id, video, report } = e.data
  if (!id || !video || !report) return

  postMessage({
    id,
    type: 'computeRetentionAnalytics',
    scores: computeRetentionAnalytics(video, report),
  })
})
