import { Video } from '@tvi/types/video'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Observable } from 'rxjs'
import { selectVideoById } from '../../../redux/videos/videos.selectors'
import { toCamelCaseObj } from '../../../utils/transform'
import { createEnhancedVideo, VideoEnhanced } from '../models/video'
import { VideosEventNotification } from '../models/videoEvents'

/*
 * Listen to video event notifications (currently through Pusher)
 * then dispatch actions that can react to specific video events.
 */
const useVideoWithEvents = ({
  video: inputVideo,
  handleVideoEvents,
}: {
  video: Video
  handleVideoEvents?(event: VideosEventNotification): void
}) => {
  const video = useSelector(selectVideoById(inputVideo?.id))
  const [events, setEvents] = useState<Observable<any>>()

  useEffect(() => {
    if (!handleVideoEvents) return
    if (!video) return
    if (events) return

    const videoWithEvents = createEnhancedVideo(
      video as VideoEnhanced
    ) as VideoEnhanced

    videoWithEvents?.events?.subscribe((event: VideosEventNotification) => {
      handleVideoEvents?.(toCamelCaseObj(event) as VideosEventNotification)
    })
    setEvents(videoWithEvents?.events)
  }, [video, handleVideoEvents, setEvents])

  if (video) {
    ;(video as VideoEnhanced).events = events
  }

  return {
    enhancedVideo: video as VideoEnhanced,
  }
}

export default useVideoWithEvents
