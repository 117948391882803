import { createSelector } from '@reduxjs/toolkit'
import { PlaylistItem } from '@tvi/types/playlist'
import toArray from 'lodash/toArray'
import { createVideo, VideoEnhanced } from '../../modules/videos/models/video'
import { AppState } from '../reducers'
import { transformEntities } from '../utils/entities'
import { selectVideosSelector } from '../videos/videos.selectors'
import { selectById } from './playlistVideos.slice'
import { transformPlaylistVideoEntity } from './transformers'

export type PlaylistVideoItem = PlaylistItem

/**
 * selectPlaylistVideossSelector - generic selection of playlistVideos (ids, entities)
 */
export const selectPlaylistVideosSelector = (state: AppState) => {
  const data = state.entities.playlistVideos
  const entities = transformEntities<PlaylistItem>(
    data.entities,
    transformPlaylistVideoEntity
  )
  return {
    ...data,
    entities,
  }
}

/**
 * selectPlaylistsListsSelector - array of playlists
 */
export const selectPlaylistVideosListsSelector = createSelector(
  selectPlaylistVideosSelector,
  (videos): PlaylistItem[] =>
    ((toArray(videos?.entities) || []) as PlaylistItem[]).sort(
      (a: PlaylistItem, b: PlaylistItem) =>
        // sort first by sort order
        (b.sort ?? 0) - (a.sort ?? 0) ||
        // then by publishedAt date
        new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime() ||
        // then by title
        a.title.localeCompare(b.title)
    )
)

/**
 * selectPlaylistsListsSelector - array of playlists
 * mapped with corresponding analyzed video
 */
export const selectPlaylistVideosListsMappedSelector = createSelector(
  selectPlaylistVideosListsSelector,
  selectVideosSelector,
  (playlistVideos, projectVideos): PlaylistItem[] => {
    return playlistVideos.map((playlistVideo: PlaylistItem) => {
      const { videoId } = playlistVideo
      const projectVideo = videoId
        ? createVideo(
            (projectVideos.entities as Record<number, VideoEnhanced>)[videoId]
          )
        : undefined
      return {
        ...playlistVideo,
        projectVideo,
      }
    })
  }
)

/**
 * selectPlaylistVideoById
 */
export const selectPlaylistVideoById = (id: string) => {
  return createSelector(selectPlaylistVideosSelector, (state) =>
    selectById(state, id)
  )
}
