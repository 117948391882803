import { LinearProgress, Typography } from '@mui/material'
import Fade from '@mui/material/Fade'
import { Playlist, PlaylistItem } from '@tvi/types/playlist'
import { Accordion } from '@tvi/uikit'
import { FC, useEffect } from 'react'
import { useSelectedContext } from '../../../../lib/selected/SelectedProvider'
import { PlaylistVideoItem } from '../../../../redux/playlist-videos/playlistVideos.selectors'
import PlaylistGridItem from './PlaylistGridItem'

export type PlaylistGridProps = {
  list: Playlist[]
  chooseVideo: (video: PlaylistVideoItem) => void
  previewVideo: (video: PlaylistVideoItem) => void
  handleOpenPlaylist(playlist: Playlist): void
  defaultExpanded?: number
}

const PlaylistGrid: FC<PlaylistGridProps> = ({
  list,
  chooseVideo,
  previewVideo,
  handleOpenPlaylist,
  defaultExpanded = 1,
}) => {
  const { selected, listKey, toggleSelect } = useSelectedContext<PlaylistItem>()

  useEffect(() => {
    list
      ?.filter?.((_, index) => index < defaultExpanded)
      .forEach((playlist) => handleOpenPlaylist(playlist))
  }, [list?.length])

  return (
    <div className="media-list-container playlists">
      {list.map((playlist: Playlist, index) => (
        <>
          {Boolean(playlist.itemCount) && (
            <Accordion
              text={`${playlist.title} (${playlist.itemCount})`}
              defaultExpanded={index < defaultExpanded}
              onChange={() => handleOpenPlaylist(playlist)}
              TransitionProps={{ unmountOnExit: true }}
            >
              {playlist.isLoading && <LinearProgress variant="indeterminate" />}
              <Fade in={!playlist.isLoading} timeout={750}>
                <div>
                  {Boolean(playlist.videos?.length) && (
                    <div className="playlists__items playlists__items--grid">
                      {playlist.videos?.map?.((video) => (
                        <PlaylistGridItem
                          video={video}
                          selected={selected.has(listKey(video))}
                          toggleSelect={toggleSelect}
                          chooseVideo={chooseVideo}
                          previewVideo={previewVideo}
                        />
                      ))}
                    </div>
                  )}
                  {playlist.error && (
                    <Typography
                      py={4}
                      fontWeight={600}
                      fontSize={11}
                      color="error"
                    >
                      No videos are viewable for this playlist
                    </Typography>
                  )}
                </div>
              </Fade>
            </Accordion>
          )}
        </>
      ))}
    </div>
  )
}

export default PlaylistGrid
