import { lazy } from 'react'
import { ROUTES } from '../../../../constants/paths'

const routes = (url: string, pathname: string) => [
  {
    index: 0,
    name: 'Overview',
    route: url,
    pathname,
    path: `${ROUTES.videos}/:id`,
    component: lazy(() => import('../videos-overview/VideosOverviewModule')),
  },
  {
    index: 1,
    name: 'Analytics',
    route: `${url}/analytics`,
    pathname,
    path: `${ROUTES.videos}/:id/analytics`,
    component: lazy(() => import('../videos-analytics/VideosAnalyticsModule')),
  },
  {
    index: 2,
    name: 'Edit Video',
    route: `${url}/edit`,
    pathname,
    path: `${ROUTES.videos}/:id/edit`,
    component: lazy(() => import('../videos-edit/VideosEditModule')),
  },
]

export default routes
