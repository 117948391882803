import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { pxToRem } from '@tvi/uikit/utils/html-units'
import { FC } from 'react'
import ConnectCardList from '../../../components/connect/ConnectCardList'
import ConnectCardListItem from '../../../components/connect/ConnectCardListItem'
import ConnectServiceActionModule from '../../../modules/serviceProfiles/components/ConnectServiceActionModule'
import ConnectServiceModule from '../../../modules/serviceProfiles/components/ConnectServiceModule'
import { useServiceProfilesProvider } from '../../../modules/serviceProfiles/providers/ServiceProfilesProvider'

export type LinkedAccountsProps = {}

const LinkedAccountsContainer = styled.div`
  width: 100%;

  .connect-module__actions {
    margin: ${pxToRem(20)} 0;
  }
`

const LinkedAccounts: FC<LinkedAccountsProps> = () => {
  const { isLoaded, numConnected } = useServiceProfilesProvider()
  return (
    <>
      {isLoaded && (
        <LinkedAccountsContainer>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h3">Linked Accounts</Typography>
              {numConnected === 0 && (
                <Typography lineHeight={1.4} mt={pxToRem(8)} mb={pxToRem(30)}>
                  To begin, connect your Youtube account to allow videos in your
                  playlists to be analyzed and remixed.
                </Typography>
              )}
              <Typography mt={pxToRem(24)} mb={pxToRem(20)}>
                Manage your Linked Accounts
              </Typography>
            </Box>
            <Box whiteSpace="nowrap" ml={20}>
              <ConnectServiceActionModule />
            </Box>
          </Box>
          <Divider component="div" />
          <ConnectServiceModule
            CardList={ConnectCardList}
            CardItem={ConnectCardListItem}
          />
        </LinkedAccountsContainer>
      )}
    </>
  )
}

export default LinkedAccounts
