import { Video } from '@tvi/types/video'
import Button from '@tvi/uikit/components/button'
import { JSXElementConstructor, useCallback, useMemo, useState } from 'react'
import ActionBarBulk from '../../../../components/action-bar-bulk/ActionBarBulk'
import { useSelectedContext } from '../../../../lib/selected/SelectedProvider'

export type VideosBulkActionBarProps = {
  onDelete?(videos: Video[]): void
  DeleteConfirm?: JSXElementConstructor<{ videos: Video[]; onClose(): void }>
}

const VideosBulkActionBar = ({
  onDelete,
  DeleteConfirm,
}: VideosBulkActionBarProps) => {
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false)
  const { selected, isAllSelected, isPartialSelected, handleSelectChange } =
    useSelectedContext<Video, string>()

  const videos = useMemo(() => Array.from(selected.values()), [selected])

  const handleDelete = useCallback(() => {
    onDelete?.(videos)
    setConfirmIsOpen(true)
  }, [videos, confirmIsOpen])

  const handleCloseConfirm = useCallback(() => {
    setConfirmIsOpen(false)
  }, [])

  return (
    <>
      <ActionBarBulk
        count={selected?.size}
        checked={isAllSelected}
        indeterminate={isPartialSelected}
        onChange={handleSelectChange}
        actions={
          Boolean(selected.size) && (
            <Button
              onClick={handleDelete}
              variant="text"
              color="error"
              size="small"
            >
              Delete Selected Videos
            </Button>
          )
        }
      />
      {DeleteConfirm && confirmIsOpen && (
        <DeleteConfirm onClose={handleCloseConfirm} videos={videos} />
      )}
    </>
  )
}

export default VideosBulkActionBar
