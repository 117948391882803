import { CardMedia, Typography } from '@mui/material'
import { Video } from '@tvi/types/video'
import Button from '@tvi/uikit/components/button'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { SelectedCardMedia } from '../../../../lib/selected/components/Selected.style'
import { videoEvent } from '../../../../redux/videos/videos.slice'
import { formatDate } from '../../../../utils/date'
import useVideoWithEvents from '../../effects/useVideoWithEvents'
import { VideoEnhanced } from '../../models/video'
import { VideosEventNotification } from '../../models/videoEvents'
import VideoProgress from '../video-progress/VideoProgress'
import { MediaCard } from './VideoGridItem.style'

export type VideoGridItemProps = {
  video: Video
  selected: boolean
  onClick: (video: Video) => void
  toggleSelect: (video: Video) => void
}

const VideoGridItem: FC<VideoGridItemProps> = ({
  video: inputVideo,
  toggleSelect,
  onClick,
  selected = false,
}) => {
  const dispatch = useDispatch()

  /*
   * attach async events (pusher)
   * listen for events from remixes
   */
  const { enhancedVideo } = useVideoWithEvents({
    video: inputVideo,
    handleVideoEvents: (notification: VideosEventNotification) => {
      if (notification.event.id !== inputVideo.id) return
      dispatch(videoEvent(notification))
    },
  })

  const video = useMemo(
    () => (enhancedVideo || inputVideo) as VideoEnhanced,
    [inputVideo, enhancedVideo]
  )

  const isLoading = useMemo(() => {
    return (
      !video?.analysisIsComplete &&
      video?.meta?.edit?.status !== 'complete' &&
      !video?.meta?.edit?.error
    )
  }, [video])

  return (
    <MediaCard
      className={clsx(video.isDeleting && 'deleting')}
      progress={isLoading && <VideoProgress video={video} />}
      image={video.thumbnailUri}
      title={video.name}
      info={
        <Typography variant="body2">
          <div>{formatDate(video.createdAt as string)}</div>
          {video.createdBy && <div>by: {video.createdBy}</div>}
          {(video.analysisIsComplete ||
            video.meta?.edit?.status === 'complete') &&
            !isLoading &&
            !video.isDeleting && (
              <Button
                onClick={() => onClick(video)}
                sx={{ p: 0 }}
                variant="text"
                size="small"
                color="success"
              >
                Analyze &amp; Remix
              </Button>
            )}

          {video.meta?.edit?.status && video.meta?.edit?.status !== 'complete' && (
            <Typography
              variant="body2"
              mt={4}
              fontSize={12}
              fontWeight={600}
              color="grey.600"
              textTransform="capitalize"
            >
              Remix {video.meta?.edit?.status}
            </Typography>
          )}
          {video.meta?.edit?.error && (
            <Typography
              variant="body2"
              mt={4}
              fontSize={12}
              fontWeight={600}
              color="error.main"
              textTransform="capitalize"
            >
              Remix Error: try again
            </Typography>
          )}
        </Typography>
      }
      CardMedia={
        <SelectedCardMedia
          className={clsx(selected && 'selected')}
          onClick={() => toggleSelect(video)}
        >
          <CardMedia component="img" height="94" image={video.thumbnailUri} />
        </SelectedCardMedia>
      }
    />
  )
}

export default VideoGridItem
