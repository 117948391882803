import {
  NotificationServiceInteface,
  NotificationServiceObjectInterface,
} from './types'

export class NotificationError extends Error {
  static type = 'NOTIFICATION_ERROR'
  constructor(message: string = NotificationError.type) {
    super(message)
  }
}

export class NotificationService implements NotificationServiceInteface {
  public service: NotificationServiceObjectInterface
  private _channels: Map<string, any> = new Map()

  constructor(service: NotificationServiceObjectInterface) {
    try {
      this.service = service
    } catch (error) {
      throw new NotificationError(
        `Notification error: ${(error as Error)?.message}`
      )
    }
  }

  getChannel(key: string): any {
    if (!this._channels.has(key)) {
      this.addChannel(key)
    }
    return this._channels.get(key)
  }

  addChannel(key: string) {
    this._channels.set(key, this.service.subscribe(key))
  }
}

export default NotificationService
