import eviApi, {
  GetServiceProfilesArgs,
  PostServiceProfilesArgs,
} from '@tvi/api-client'
import { PostServiceProfilesResponse } from '@tvi/api-client/services/serviceProfiles/ServiceProfilesService'
import { ServiceProfile } from '@tvi/types/serviceProfile'
import { createAsyncSaga } from 'create-async-saga'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { httpErrorAction } from '../errors/errors.actions'
import { selectServiceProfileById } from './serviceProfiles.selectors'
import { serviceProfileUpsertOne } from './serviceProfiles.slice'

/*
 * createServiceProfile
 * create a service profile, the backend will trigger oauth
 * to connect to the specified service, after connecting
 * there will be a redirect to /oauth/{service}
 */
export const createServiceProfile = createAsyncSaga(
  'edisenVI/serviceProfiles/createServiceProfile',
  function* createServiceProfile(args: PostServiceProfilesArgs): any {
    try {
      const response: PostServiceProfilesResponse = yield call(
        eviApi.v1.createServiceProfile,
        args
      )
      const { authorizationUri } = response

      // we likely don't need this as we get the
      // list of serviceProfiles after completion
      // of the Oauth redirect
      setTimeout(function* () {
        yield put(
          createServiceProfileActions({
            ...args,
            authorizationUri,
          })
        )
      }, 0)

      // send the window to the OAuth url
      // on success will be redirected back to `/oauth/{service}`
      // todo: this likely should be done in a popopen window
      // that will allow us to remove the setTimeout
      window.location.href = authorizationUri
      return response
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

/*
 * fetchServiceProfiles
 * get a list of service profiles
 */
export const fetchServiceProfiles = createAsyncSaga(
  'edisenVI/serviceProfiles/getServiceProfiles',
  function* fetchServiceProfiles(args: GetServiceProfilesArgs) {
    try {
      // const loading = yield select(selectServiceProfilesLoading)
      // console.log('======== getServiceProfiles', loading)
      // if (loading) return
      return yield call(eviApi.v1.getServiceProfiles, args)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

/*
 * updateServiceProfiles
 */
export const updateServiceProfile = createAsyncSaga(
  'edisenVI/serviceProfiles/updateServiceProfile',
  function* updateServiceProfile(profile: ServiceProfile) {
    const originalProfile = yield select(selectServiceProfileById(profile.id))
    try {
      // optimistic update
      yield put(serviceProfileUpsertOne(profile))
      // api update
      return yield call(eviApi.v1.updateServiceProfile, { profile })
    } catch (error) {
      // if there is an error revert and throw error
      yield put(serviceProfileUpsertOne(originalProfile))
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

/*
 * deleteServiceProfile
 */
export const deleteServiceProfile = createAsyncSaga(
  'edisenVI/serviceProfiles/deleteServiceProfile',
  function* deleteServiceProfile(args: { id: number }) {
    try {
      return yield call(eviApi.v1.deleteServiceProfile, args)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export default function* serviceProfilesSaga() {
  yield all([
    takeEvery(fetchServiceProfiles.actionType, fetchServiceProfiles.asyncSaga),
    takeEvery(createServiceProfile.actionType, createServiceProfile.asyncSaga),
    takeEvery(deleteServiceProfile.actionType, deleteServiceProfile.asyncSaga),
    takeEvery(updateServiceProfile.actionType, updateServiceProfile.asyncSaga),
  ])
}

export const fetchServiceProfilesActions = fetchServiceProfiles.action
export const createServiceProfileActions = createServiceProfile.action
export const deleteServiceProfileActions = deleteServiceProfile.action
export const updateServiceProfileActions = updateServiceProfile.action
