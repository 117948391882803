import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material/styles/createTheme'
import { flexFullContainer } from './styled/global/flex'
import { simpleBarStyles } from './styled/vendor/simplebar'

// Global App and Vendor Styles (Overrides)
export const StyleContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  ...flexFullContainer(),
  ...simpleBarStyles({ theme }),
}))
