import { ChangeEvent, FC, ReactNode } from 'react'
import { Box } from '@mui/system'
import { Checkbox } from '@tvi/uikit'

export type ActionBarBulkProps = {
  active?: boolean
  ariaLabel?: string
  checkLabel?: string
  disabled?: boolean
  indeterminate?: boolean
  checked?: boolean
  count?: number
  onChange?(event?: ChangeEvent<HTMLInputElement>): void
  actions?: ReactNode
}

const ActionBarBulk: FC<ActionBarBulkProps> = ({
  onChange = () => void 0,
  active = true,
  disabled = false,
  indeterminate = false,
  checked = false,
  ariaLabel = 'bulk actions',
  checkLabel = 'Select All',
  actions = null,
  count = 0,
}) => {
  return !active ? null : (
    <Box
      aria-label={ariaLabel}
      role="toolbar"
      className="action-bar--bulk"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        opacity: disabled ? 0.8 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
        userSelect: 'none',
      }}
    >
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        disabled={disabled}
        onChange={onChange}
        label={`${checkLabel} (${count})`}
      />
      {actions && <Box role="menu">{actions}</Box>}
    </Box>
  )
}

export default ActionBarBulk
