import { createAction, PayloadAction } from '@reduxjs/toolkit'
import eviApi, { GetAnalyticArgs, IdType } from '@tvi/api-client'
import { create } from '@tvi/http'
import { Video } from '@tvi/types/video'
import { createAsyncSaga } from 'create-async-saga'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { configBrandEventsPipeline } from '../../constants/brandsConfig'
import { videoEditApi } from '../../constants/videosConfig'
import { BrandEventNotification } from '../../modules/brands/models/brand'
import { createVideo, VideoEnhanced } from '../../modules/videos/models/video'
import {
  CreateEditProps,
  createEditVideo,
} from '../../modules/videos/models/videoEdit'
import { httpErrorAction } from '../errors/errors.actions'
import { deleteProject, fetchProjects } from '../projects/projects.saga'
import { selectProjectVideos } from '../projects/projects.selector'
import { selectByProjectId, selectVideoById } from './videos.selectors'
import { brandVideoEvent, videoEvent, videosSlice } from './videos.slice'

export const fetchVideo = createAsyncSaga(
  'edisenVI/video/getVideo',
  function* (args: IdType & { projectId?: number }): any {
    try {
      return yield call(eviApi.v1.getVideo, args)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const deleteVideo = createAsyncSaga(
  'edisenVI/video/delete',
  function* (args: IdType): any {
    try {
      return yield call(eviApi.v1.deleteVideo, args)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const fetchVideoAnalytic = createAsyncSaga(
  'edisenVI/video/getVideoAnalytic',
  function* (args: GetAnalyticArgs): any {
    try {
      return yield call(eviApi.v1.getAnalytic, args)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

/*
 * createEdit
 * creating an edit is 2 actions
 * 1. create a video object in API
 * 2. process to assemble the actual video in editor API
 */
export const createEdit = createAsyncSaga(
  'edisenVI/video/createEdit',
  function* ({ id, video: v, clips }: CreateEditProps): any {
    const video = createEditVideo({ id, video: v, clips })
    const { projectId } = video

    try {
      /*
       * save new edit video to api
       * processing is done is a separate action
       */
      const editVideo = (yield call(eviApi.v1.createVideo, {
        projectId: projectId as number,
        video,
      })) as Video

      yield put(
        videosSlice.actions.upsert(
          createVideo({
            ...editVideo,
            brandId: video.brandId,
          }) as Video
        )
      )

      /*
       * kick off edit processing
       */
      const data = {
        version: videoEditApi.edit.version,
        key: id,
        id: editVideo.id,
        clips,
      }

      const post = () => {
        const http = create()
        return http.post(videoEditApi.edit.url as string, data)
      }

      yield call(post)
      yield
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

/*
 * On Brand Video Notifications
 * mainly used for tracking pipeline analysis events
 * Pipeline events are on the brand
 */
const onBrandVideoNotification = function* onBrandVideoNotification(
  action: PayloadAction<BrandEventNotification>
) {
  const { event, type } = action.payload
  const fetchAction = put(
    fetchVideoAction({
      id: event.videoId as number,
      projectId: event.projectId,
      brandId: event.brandId,
    })
  )

  // each time there is an event change fetch that video to update its current state
  if ((configBrandEventsPipeline.slice() as string[]).includes(type)) {
    yield fetchAction
  }
}

/*
 * On Videos Notifications
 * mainly used for tracking editor analysis events
 * Editor events are on the video
 */
const onVideosNotification = function* onVideosNotification(
  event: PayloadAction<{
    event: {
      key: string
      id: number
      status?: 'creating' | 'download' | 'formatting' | 'complete'
      url?: string
      error?: string
    }
  }>
): any {
  const { key, id, status, url, error } = { ...event.payload.event }
  const video = yield select(selectVideoById(id))
  const updatedVideo = {
    ...video,
    meta: {
      // make sure we don't wipe out other props
      // this probably should be done in the slice
      // since it allow mutations directly on the prop
      ...(video.meta || {}),
      edit: {
        ...(video.meta?.edit || {}),
        key,
        error,
        url,
        status: url ? 'complete' : status,
      },
    },
  }
  yield put(videosSlice.actions.upsert(updatedVideo))
}

/*
 * On Videos Notifications
 * mainly used for tracking editor analysis events
 * Editor events are on the video
 */
const handleSubscriptionDeletion = function* onSubscriptionDeletion(
  event: PayloadAction<{ video: VideoEnhanced }>
) {
  if (event.payload.video?.events) {
    const video = event.payload.video
    delete video.events
    yield put(videosSlice.actions.upsert(video))
  }
}

/*
 * After Fetch Projects
 * - we want to pull out the videos and store those separately
 * - note this does not scale, its a first implementation
 * - this will need to be optimized
 *
 */
// prettier-ignore
export const afterFetchProjects = createAsyncSaga(
  'edisenVI/videos/afterFetchProjects', function* afterFetchProjects(): any {
  const videos = yield select(selectProjectVideos)
  return yield put(videosSlice.actions.upsertMany(videos))
})

/*
 * After Delete Project
 * - remove any video entities for that project
 */
// prettier-ignore
export const afterDeleteProject = createAsyncSaga(
  'edisenVI/videos/afterDeleteProject', function* afterDeleteproject(params: IdType): any {
  const videos = (yield select(selectByProjectId(params.id))) as Video[]
  for (const video of videos) {
    yield put(deleteVideoAction({ id: video.id}))
  }
  return
})

export default function* videosSaga() {
  yield all([
    takeEvery(fetchVideo.actionType, fetchVideo.asyncSaga),
    takeEvery(deleteVideo.actionType, deleteVideo.asyncSaga),
    takeEvery(createEdit.actionType, createEdit.asyncSaga),
    takeEvery(fetchVideoAnalytic.actionType, fetchVideoAnalytic.asyncSaga),
    takeEvery(fetchProjects.fulfilled, afterFetchProjects.asyncSaga),
    takeEvery(deleteProject.fulfilled, afterDeleteProject.asyncSaga),
    takeEvery(brandVideoEvent.type, onBrandVideoNotification),
    takeEvery(videoEvent.type, onVideosNotification),
    takeEvery(subscriptionDeletionAction.type, handleSubscriptionDeletion),
  ])
}

export const fetchVideoAction = fetchVideo.action
export const deleteVideoAction = deleteVideo.action
export const fetchVideoAnalyticAction = fetchVideoAnalytic.action
export const createEditAction = createEdit.action
export const subscriptionDeletionAction = createAction<{
  video: VideoEnhanced
}>('edisenVI/videos/subscription/deleted')
