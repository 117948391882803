import { Search, ToggleView } from '@tvi/uikit'
import { ViewType } from '@tvi/uikit/components/toggle-view'
import { ChangeEventHandler, FC } from 'react'
import { ActionsBarContainer } from './ActionBar.style'

export type ActionBarProps = {
  placeholder?: string
  view?: ViewType
  defaultView?: ViewType
  onSearch: ChangeEventHandler
  onView(view: ViewType): void
}

const ActionBar: FC<ActionBarProps> = ({
  placeholder,
  onSearch,
  onView,
  view,
  defaultView = 'grid',
}) => {
  return (
    <ActionsBarContainer className="action-bar">
      <Search
        className="action-bar__search"
        onChange={onSearch}
        placeholder={placeholder}
      />
      <ToggleView onChange={onView} value={view} defaultValue={defaultView} />
    </ActionsBarContainer>
  )
}

export default ActionBar
