// import { AnalysisStatus } from '@tvi/types/analysis'

import { Video } from '@tvi/types/video'
import camelcaseKeys from 'camelcase-keys'
import { Observable } from 'rxjs'
import { pusherEditor } from '../../../constants/notifications'
import { configVideoEvents } from '../../../constants/videosConfig'
import NotificationService from '../../notifications/services'
import { getNotificationServiceFactory } from '../../notifications/services/services'

// Event keys that we should subscribe to
export type VideosEventType = typeof configVideoEvents[number]
export type VideosEventStatus =
  | 'creating'
  | 'downloading'
  | 'formatting'
  | 'complete'

// Notification event object - currently Pusher
export interface BrandEventNotificationInterface {
  key: string
  id?: number
  status?: VideosEventStatus
}

export interface VideosEventNotification {
  type: VideosEventType
  event: BrandEventNotificationInterface
}

/*
 * For each VideosEventType for a user's video listen
 * for events from notification service and publish that event
 */
export const observeAsyncEvents = (
  video: Video,
  keys: VideosEventType[]
): Observable<VideosEventNotification> => {
  const observable = new Observable<VideosEventNotification>((observer) => {
    const service = getNotificationServiceFactory(
      'pusherEditor',
      pusherEditor
    ) as NotificationService

    const channelName = `${pusherEditor.keys.editor}_${video.id}`
    const channel = service.getChannel(channelName)

    keys.forEach((key) => {
      channel.unbind(key)
      channel.bind(key, (event: BrandEventNotificationInterface) => {
        if (event) {
          observer.next({
            type: key,
            event: camelcaseKeys(event),
          })
        } else {
          observer.complete()
        }
      })
    })
  })
  return observable
}
