import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { FC, HTMLAttributes, ReactNode } from 'react'

export type ColumnItem = {
  key?: string
  header: ReactNode
  content: ReactNode
}

export type ColumnLayoutProps = {
  cols: ColumnItem[]
  scrollable?: boolean
} & HTMLAttributes<HTMLDivElement>

const ColumnLayout: FC<ColumnLayoutProps> = ({
  cols,
  className,
  scrollable = false,
  ...rest
}) => {
  return (
    <main className="main main--no-scroll">
      <div
        {...rest}
        className={clsx(
          'column-layout',
          scrollable && 'column-layout--scrollable',
          className
        )}
      >
        {cols.map((item, index) => (
          <div key={item.key || index} className="column-layout__col">
            <div
              className={clsx('column-layout__header', {
                'column-layout__header--text': typeof item.header === 'string',
              })}
            >
              <div className="column-layout__col-content">
                {typeof item.header === 'string' ? (
                  <Typography variant="h6">{item.header}</Typography>
                ) : (
                  item.header
                )}
              </div>
            </div>
            <div className="column-layout__content">
              <div className="column-layout__col-content">
                <div className="column-layout__content-main">
                  {item.content}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </main>
  )
}

export default ColumnLayout
