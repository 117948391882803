import { CircularProgress, Container } from '@mui/material'
import { Box } from '@mui/system'
import { Checkbox } from '@tvi/uikit'
import { ChangeEvent, FC, Suspense, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router'
import AuthRoute from '../../../../components/auth-route/AuthRoute'
import { ROUTES, ROUTES_TYPES } from '../../../../constants/paths'
import DetailLayout from '../../../../layouts/detail-layout/DetailLayout'
import { uiSettingsSelector } from '../../../../redux/ui/ui.selector'
import { updateSettings } from '../../../../redux/ui/ui.slice'
import { videoEvent } from '../../../../redux/videos/videos.slice'
import useProjects from '../../../projects/effects/useProjects'
import useVideo from '../../effects/useVideo'
import useVideoWithEvents from '../../effects/useVideoWithEvents'
import { VideoEnhanced } from '../../models/video'
import { VideosEventNotification } from '../../models/videoEvents'
import videoDetailRoutes from '../videos-detail/VideosDetail.routes'
import VideosDetailLoader from '../videos-detail/VideosDetailLoader'
import {
  VideoDetailsContentContainer,
  VideoDetailsHeader,
  VideoDetailsInfoHeader,
  VideoDetailsInfoHeaderType,
  VideoDetailsModuleContainer,
  VideoDetailsTabs,
} from './VideosDetailModule.style'

export type VideosDetailModuleSectionProps = {
  video: VideoEnhanced
}

const VideosDetailModule: FC<{}> = () => {
  const dispatch = useDispatch()
  const uiSettings = useSelector(uiSettingsSelector)
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { id } = useParams<{ id: number | any }>()
  const { url } = match
  const { pathname } = location
  const { video, isLoading } = useVideo({ id })

  const sections = useMemo(
    () => videoDetailRoutes(url, pathname),
    [url, pathname]
  )

  const sectionNames = useMemo(
    () => sections.map((section) => section.name),
    [sections]
  )

  const activeTabIndex = useMemo(
    () => sections.find((section) => section.route === pathname)?.index || 0,
    [sections, pathname]
  )

  const handleSettingChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        updateSettings({
          [e.target.name]: e.target.checked,
        })
      )
    },
    []
  )

  const handleTabChange = useCallback(
    (activeTabIndex: number) => {
      history.push(sections[activeTabIndex].route)
    },
    [sections]
  )

  /*
   * listen to video events
   * on video events update events that are affected
   */
  const handleVideoEvents = useCallback(
    (notification: VideosEventNotification) => {
      dispatch(videoEvent(notification))
    },
    [dispatch]
  )

  /*
   * attach async events (pusher)
   * listen for events from remixes
   */
  const { enhancedVideo } = useVideoWithEvents({ video, handleVideoEvents })

  /*
   * load projects
   * when deep llinking to this video we want to make
   * sure projects are loaded when going back to the
   * the videos landing page
   */
  useProjects({ excludeVideos: id ? [id] : undefined })
  // useProjects({ excludeVideos: undefined })

  // prettier-ignore
  return (
    <>
    {!enhancedVideo && null}
    {enhancedVideo && (
    <DetailLayout
      parentRouteText="Back to Videos"
      parentRoute={ROUTES.videos as ROUTES_TYPES}
      menu={
        <Box>
          <Checkbox
            label="Show Tips"
            name="showTips"
            checked={uiSettings.showTips}
            onChange={handleSettingChange}
          />
          <Checkbox
            label="Advanced View"
            name="advancedView"
            checked={uiSettings.advancedView}
            onChange={handleSettingChange}
          />
        </Box>
      }
    >
      <VideoDetailsModuleContainer className="videos-detail-module">
        <VideosDetailLoader isLoading={isLoading}>
          <VideoDetailsHeader>
            <Container>
              <VideoDetailsInfoHeader
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <VideoDetailsInfoHeaderType variant="subtitle2">
                  {enhancedVideo?.name}
                </VideoDetailsInfoHeaderType>
              </VideoDetailsInfoHeader>
              <VideoDetailsTabs
                centered
                activeTabIndex={activeTabIndex}
                tabLabels={sectionNames}
                onChange={handleTabChange}
                sx={{ border: 0 }}
              />
            </Container>
          </VideoDetailsHeader>
          <VideoDetailsContentContainer>
            <Switch>
              {
                sections.map(section => (
                  <AuthRoute key={section.route} exact path={section.path}>
                    <Suspense fallback={
                      <CircularProgress color="primary" />
                    }>
                      <section.component video={enhancedVideo} />
                    </Suspense>
                  </AuthRoute>
                ))
              }
            </Switch>
          </VideoDetailsContentContainer>
        </VideosDetailLoader>
      </VideoDetailsModuleContainer>
    </DetailLayout>
    )}
    </>
  )
}

export default VideosDetailModule
