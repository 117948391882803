import Typography from '@mui/material/Typography'
import { SignInType } from '@tvi/types/api'
import { Button, Gap, TextField } from '@tvi/uikit'
import { useFormik } from 'formik'
import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { BRANDING } from '../../constants/branding'
import { ROUTES } from '../../constants/paths'
import { loginAction } from '../../redux/auth/auth.saga'

const initialValues: SignInType = {
  email: '',
  password: '',
}

const Login: FC = () => {
  // const { redirect } = useQuery<{ redirect: string }>()
  // const isAuthorized = useAppSelector(isAuthorizedSelector)
  const dispatch = useDispatch()

  const formik = useFormik<SignInType>({
    initialValues,
    onSubmit: async (values) => dispatch(loginAction(values)),
  })

  // temporarily disabling this as it may interfere with some
  // business rules
  // useEffect(() => {
  //   if (isAuthorized) browserHistory.replace(redirect ?? '')
  // }, [isAuthorized, redirect])

  return (
    <form
      className="user-form user-form--indent"
      onSubmit={formik.handleSubmit}
    >
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Typography variant="h3">Login to {BRANDING.appName}</Typography>
      <div className="user-form__text flex">
        <Typography>Don't have an account? </Typography>
        <Gap />
        <NavLink to={ROUTES.signup}>
          <Typography variant="subtitle1" color="primary">
            Sign up!
          </Typography>
        </NavLink>
      </div>
      <div className="user-form__form">
        <div className="user-form__form-section">
          <TextField
            required
            className="user-form__form-item"
            name="email"
            label="Email"
            placeholder="Enter Email"
            onChange={formik.handleChange}
          />
          <TextField
            required
            className="user-form__form-item"
            type="password"
            name="password"
            label="Password"
            placeholder="Enter Password"
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="user-form__form-controls">
        <Button type="submit">Log in</Button>
      </div>
    </form>
  )
}

export default Login
