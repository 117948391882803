import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { ServiceProfile } from '@tvi/types/serviceProfile'
import {
  createServiceProfile,
  deleteServiceProfile,
  fetchServiceProfiles,
} from './serviceProfiles.saga'

const SERVICE_PROFILES_DATA_KEY = 'serviceProfiles'

/**
 * Service Profiless Entity Adapter
 */
const serviceProfilesAdapter = createEntityAdapter<ServiceProfile>({
  selectId: (serviceProfile) => serviceProfile.id,
  sortComparer: (a, b) => a.service.localeCompare(b.service),
})

/**
 * ServiceProfiles Slice
 * todo: this isn't fully typescript compliant (ex. action.meta isn't defined)
 * fix typings
 */
export const serviceProfilesSlice = createSlice({
  name: SERVICE_PROFILES_DATA_KEY,
  initialState: serviceProfilesAdapter.getInitialState({
    loading: false,
    loaded: false,
    error: '',
  }),
  reducers: {
    serviceProfileAddOne: serviceProfilesAdapter.addOne,
    serviceProfileUpdateOne: serviceProfilesAdapter.updateOne,
    serviceProfileUpsertOne: serviceProfilesAdapter.upsertOne,
    serviceProfileRemoveOne: serviceProfilesAdapter.removeOne,
    serviceProfileSetAll: serviceProfilesAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServiceProfiles.pending, (state) => {
      state.loading = true
      state.loaded = false
      state.error = ''
    })
    builder.addCase(fetchServiceProfiles.fulfilled, (state, action: any) => {
      const { brandId } = action.meta.arg
      const profiles = ((action.payload || []) as ServiceProfile[]).map(
        (profile) => ({
          ...profile,
          brandId,
        })
      )
      serviceProfilesAdapter.setAll(state, profiles)
    })
    builder.addCase(fetchServiceProfiles.rejected, (state, action: any) => {
      state.error = action.payload.message
    })
    builder.addCase(createServiceProfile.fulfilled, (state, action: any) => {
      const { brandId } = action.meta.arg
      const profile = {
        ...action.payload,
        brandId,
      } as ServiceProfile
      serviceProfilesAdapter.addOne(state, profile)
    })
    builder.addCase(deleteServiceProfile.fulfilled, (state, action) => {
      serviceProfilesAdapter.removeOne(state, (action as any).meta!.arg.id)
    })
    builder.addMatcher(
      isAnyOf(fetchServiceProfiles.fulfilled, fetchServiceProfiles.rejected),
      (state) => {
        state.loading = false
        state.loaded = true
      }
    )
  },
})

export const serviceProfilesReducer = serviceProfilesSlice.reducer
export const {
  serviceProfileAddOne,
  serviceProfileUpdateOne,
  serviceProfileUpsertOne,
  serviceProfileRemoveOne,
  serviceProfileSetAll,
} = serviceProfilesSlice.actions

export const { selectById: serviceProfileSelectById } =
  serviceProfilesAdapter.getSelectors()
