import { Scene } from '@tvi/types/scene'
import { Score } from '@tvi/types/score'
import { TimeObject } from '@tvi/types/timeObject'
import { toArray } from 'lodash'
import scoringConfig, { BenchMarkLevels } from '../../../constants/scoring'
import { rangeInStepsArray } from '../../../utils/array'
import { sortByTime } from '../../../utils/date'
import { changeBetweenNumbers } from '../../../utils/number'
import { VideoEnhanced } from '../models/video'
import { BenchmarkOuterType, GroupedScene } from '../types'
import { mean } from '../workers/scoring.worker'

/*
 * getBenchmarkLevel
 * is the value 'negative' | 'neutral' | 'positive'
 * compared to the scoring benchmark
 */
export const getBenchmarkLevel = (
  value: number,
  benchmarks: BenchMarkLevels = scoringConfig.benchmarkLevels
): 'negative' | 'positive' | 'neutral' => {
  if (value <= benchmarks.negative) {
    return 'negative'
  }
  if (value >= benchmarks.positive) {
    return 'positive'
  }
  return 'neutral'
}

/*
 * getTimeScore
 * get the score at some specific time in a video
 */
export const getTimeScore = (
  video: VideoEnhanced,
  time: TimeObject,
  scoresInput?: Score[]
): number => {
  const start = ~~parseFloat(`${time.startTimeOffset}`)
  const end = ~~parseFloat(`${time.endTimeOffset}`)
  const scoreKey = 'adjustedTvi'

  let scores = (scoresInput || video.scores).filter(
    (score: Score) =>
      ~~Math.round(parseFloat(`${score.startSeconds}`)) >=
        ~~Math.round(start) &&
      ~~Math.round(parseFloat(`${score.seconds}`)) <= ~~Math.round(end)
  )

  if (scores.length === 1) {
    return scores[0][scoreKey]
  }

  if (scores.length === 0) {
    const score = (scoresInput || video.scores).find(
      (s: Score) =>
        ~~Math.round(parseFloat(`${s.startSeconds}`)) >= ~~Math.round(start)
    )
    // handle case where scene is shorter than the segment
    if (score && score.seconds > end) {
      return score[scoreKey]
    }
  }

  const n = Number(
    (
      scores.map((s: any) => s[scoreKey]).reduce((a: any, c: any) => a + c, 0) /
      scores.length
    ).toFixed(0)
  )

  if (isNaN(n) || n < 0 || n > 100) {
    console.error(
      `SecondTimeObject scoring error: ${video.id} - ${time.startTimeOffset} - ${time.endTimeOffset}`
    )
  }
  return n
}

/*
 * getTotalScore
 * get the average tvi score in a list of scores
 * You could use the avg function with a mapped list of tvi scores
 */
export const getTotalScore = (scores: Score[]): number => {
  if (!scores || scores?.length === 0) return 0
  return (
    scores.reduce((acc, score) => acc + score.adjustedTvi, 0) / scores.length
  )
}

/*
 * getScoresByStepRange
 * get scores in a range by steps
 */
export const getScoresByStepRange = (
  scores: number[],
  steps: number = 4
): number[][] => {
  return rangeInStepsArray(scores, steps)
}

/*
 * getAvgScoresByStepRange
 * get average scores in a range by steps
 */
export const getAvgScoresByStepRange = (
  scores: number[],
  steps: number = 4
): number[] => {
  const arrRange = getScoresByStepRange(scores, steps)
  return arrRange.reduce((acc, group: number[]) => [...acc, mean(group)], [])
}

/*
 * getOuterBenchmarkScores
 */
export const getOuterBenchmarkScores = (
  scores: number[],
  steps: number = 4,
  threshold: number = 0
): { top: BenchmarkOuterType; bottom: BenchmarkOuterType } => {
  const benchmarkScores = getTotalBenchmarkScores(scores, steps)
  // console.log('benchmarkScores', benchmarkScores)
  return benchmarkScores.reduce(
    (acc, score, index) => {
      return {
        top: score > acc.top.val + threshold ? { val: score, index } : acc.top,
        bottom:
          score < acc.bottom.val - threshold
            ? { val: score, index }
            : acc.bottom,
      }
    },
    { top: { val: -1000, index: -1 }, bottom: { val: 1000, index: -1 } }
  )
}

/*
 * getTotalBenchmarkScores
 */
export const getTotalBenchmarkScores = (
  scores: number[],
  steps: number = 4,
  benchmarkScores: typeof scoringConfig.benchmarkScores = scoringConfig.benchmarkScores
): number[] => {
  const avgQuartileScores = getAvgScoresByStepRange(scores, steps)
  return avgQuartileScores.map((score, i) =>
    parseFloat(changeBetweenNumbers(score, benchmarkScores[i]).toPrecision(4))
  )
}

/*
 * getTotalBenchmarkScoresPattern
 */
export const getTotalBenchmarkScoresPattern = (
  scores: number[],
  steps: number = 4
): string => {
  const benchmarkScores = getTotalBenchmarkScores(scores, steps)
  return benchmarkScores.map((score) => (score > 0 ? 1 : -1)).join('')
}

/*
 * getSceneBenchmarkScoreByScore
 * get score compared to a benchmark
 */
export const getSceneBenchmarkScoreByScore = (
  quartile: number,
  score: number,
  benchmarks: typeof scoringConfig.benchmarkScores = scoringConfig.benchmarkScores
): number => {
  const scoreTarget = benchmarks[quartile - 1]
  return parseFloat(
    (((score - scoreTarget) / scoreTarget) * 100).toPrecision(2)
  )
}

/*
 * getSceneBenchmarkScore
 * get score compared to a benchmark
 */
export const getSceneBenchmarkScore = (
  scene: Scene,
  benchmarks: typeof scoringConfig.benchmarkScores = scoringConfig.benchmarkScores
): number => {
  if (!scene.score) return 0
  const scoreTarget = benchmarks[(scene.quartileIndex as number) - 1]
  return parseFloat(
    (((scene.score - scoreTarget) / scoreTarget) * 100).toPrecision(2)
  )
}

/*
 * getScoredVideoScenes
 * get all scores in a video as a hash of `Record<time, score>`
 */
export const getScoredVideoScenes = (
  scores: Score[],
  video: VideoEnhanced
): Record<number, number> => {
  const scenes = toArray(video.scenes) || []
  return scenes
    .sort(sortByTime)
    .map((scene: Scene) => {
      const score = getTimeScore(video, scene, scores)
      return {
        ...scene,
        score,
      }
    })
    .reduce(
      (a, scene: Scene) => ({
        ...a,
        [scene.startTimeOffset]: scene.score,
      }),
      {}
    )
}

/*
 * getGroupedSceneScores
 * group scene scores
 */
export const getGroupedSceneScores = (
  scenes: Scene[],
  falloff: number = 4,
  slice: number = 0
): GroupedScene[][] => {
  return (scenes ?? [])
    .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
    .map(
      ({
        index,
        startTimeOffset,
        score,
        quartileIndex,
        scoreChangePerBenchmark,
      }) => ({
        index,
        startTimeOffset,
        score,
        quartileIndex,
        scoreChangePerBenchmark,
      })
    )
    .reduce((scores: any[], score) => {
      if (scores.length > 0) {
        if (
          scores[scores.length - 1].slice(slice)[0].score - (score.score ?? 0) >
          falloff
        ) {
          scores.push([score])
        } else {
          scores[scores.length - 1].push(score)
        }
      } else {
        scores[0] = [score]
      }
      return [...scores]
    }, [])
}
