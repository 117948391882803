import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { videosSlice } from '../../../redux/videos/videos.slice'
import { hasRetentionAnalytics, VideoEnhanced } from '../models/video'
import {
  getSceneBenchmarkScoreByScore,
  getScoredVideoScenes,
} from '../services/scoring'
import ScoringService from '../services/scoringService'

const useScoredVideo = (video: VideoEnhanced) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // async because this will be moved to a worker
    // requiring async since it will respond via a listener

    if (!hasRetentionAnalytics(video) || !video.scenes) return
    if (video?.scores?.length) return
    ;(async () => {
      const scoringService = new ScoringService()
      const scores = scoringService.score(video)
      const sceneScores = scores?.length
        ? getScoredVideoScenes(scores, video)
        : undefined

      dispatch(
        videosSlice.actions.upsert({
          ...video,
          scenes: video.scenes.map((scene) => {
            const score = sceneScores?.[scene.startTimeOffset] as number
            const scoreChangePerBenchmark = getSceneBenchmarkScoreByScore(
              scene.quartileIndex as number,
              score
            )
            return {
              ...scene,
              score,
              scoreChangePerBenchmark,
            }
          }),
          scores,
          sceneScores,
        })
      )
    })()
  }, [video, video.scenes])
}

export default useScoredVideo
