import { Playlist } from '@tvi/types/playlist'

/*
 * Filter playlists by some matching properties to `query`
 * This is really naive and is temporary until we have
 * a better understanding of how this should work
 */
export const playlistSearchFilter = (
  playlists: Playlist[],
  query: string
): Playlist[] => {
  console.log('query', query, !query)
  if (!query) return playlists
  return playlists.filter((playlist) => {
    return (
      playlist.title.toLocaleLowerCase().match(query) ||
      playlist.videos?.find((playlistItem) =>
        playlistItem.title.toLocaleLowerCase().match(query)
      )
    )
  })
}
