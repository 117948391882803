import { Fade, LinearProgress, Typography } from '@mui/material'
import { Playlist, PlaylistItem } from '@tvi/types/playlist'
import { Accordion } from '@tvi/uikit'
import { FC, useEffect } from 'react'
import { useSelectedContext } from '../../../../lib/selected/SelectedProvider'
import { PlaylistVideoItem } from '../../../../redux/playlist-videos/playlistVideos.selectors'
import PlaylistListHeader from './PlaylistListHeader'
import PlaylistListItem from './PlaylistListItem'

export type PlaylistListProps = {
  list: Playlist[]
  chooseVideo: (video: PlaylistVideoItem) => void
  previewVideo: (video: PlaylistVideoItem) => void
  handleOpenPlaylist(playlist: Playlist): void
  defaultExpanded?: number
}

const PlaylistList: FC<PlaylistListProps> = ({
  list,
  chooseVideo,
  previewVideo,
  handleOpenPlaylist,
  defaultExpanded = 1,
}) => {
  // prettier-ignore
  const { selected, handleSelect, handleDeselect } = useSelectedContext<PlaylistItem>()

  useEffect(() => {
    list
      ?.filter?.((_, index) => index < defaultExpanded)
      .forEach((playlist) => handleOpenPlaylist(playlist))
  }, [list?.length])

  return (
    <div className="media-list-container playlists playlists--list">
      <>
        <PlaylistListHeader />
        {list.map((playlist, index) => (
          <Accordion
            key={playlist.id}
            text={playlist.title}
            defaultExpanded={index < defaultExpanded}
            TransitionProps={{ unmountOnExit: true }}
            onChange={() => handleOpenPlaylist(playlist)}
          >
            {playlist.isLoading && <LinearProgress variant="indeterminate" />}
            <Fade in={!playlist.isLoading} timeout={750}>
              <div>
                {Boolean(playlist.videos?.length) && (
                  <div className="playlists__items playlists__items--list">
                    {playlist.videos?.map?.((video) => (
                      <PlaylistListItem
                        key={video.id}
                        onSelect={handleSelect}
                        onDeselect={handleDeselect}
                        chooseVideo={chooseVideo}
                        previewVideo={previewVideo}
                        selected={selected.has(video)}
                        video={video}
                      />
                    ))}
                  </div>
                )}
                {playlist.error && (
                  <Typography
                    py={4}
                    fontWeight={600}
                    fontSize={11}
                    color="error"
                  >
                    No videos are viewable for this playlist
                  </Typography>
                )}
              </div>
            </Fade>
          </Accordion>
        ))}
      </>
    </div>
  )
}

export default PlaylistList
