export const numberChangeFormat = (
  anum: number,
  bnum: number | undefined = 0,
  showPrefix: boolean = true,
  suffix: string = ''
): { val: number | undefined; text: string | undefined } => {
  if (isNaN(anum) || (bnum && isNaN(bnum)))
    return { val: undefined, text: undefined }
  if (anum === bnum || (bnum === null && anum === 0))
    return { val: 0, text: '' }
  const diff = bnum === null ? anum : anum - bnum
  const prefix = showPrefix ? (diff < 0 ? '-' : '+') : ''
  return { val: diff, text: `${prefix}${Math.abs(diff).toFixed(2)}${suffix}` }
}

export const referenceBySlice =
  (slices: number) =>
  (num: number, max: number): number =>
    Math.ceil(num / (max / slices))

export const quartile = (num: number, max: number) =>
  referenceBySlice(4)(num, max)

export const changeBetweenNumbers = (newNum: number, oldNum: number): number =>
  ((newNum - oldNum) / oldNum) * 100

export const lerp = (start: number, end: number, value: number) =>
  (1 - value) * start + value * end

export const toPercent = (num: number): number => Number((num * 100).toFixed())
