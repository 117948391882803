import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginResponse, SessionToken } from '@tvi/types/api'
import { pick } from 'lodash'
import { toCamelCaseObj } from '../../utils/transform'
import { login, logout, session, signup, userByUID } from './auth.saga'

export interface AuthState {
  user:
    | {
        email: string
      }
    | undefined
  session?: SessionToken
  error?: string
  ui: {
    isLoading: boolean
  }
}

// todo: move this to the correct place
export const sessionFromHeader = (headers: any): SessionToken => {
  return toCamelCaseObj(
    pick(headers, ['access-token', 'expiry', 'uid', 'client', 'token-type'])
  ) as SessionToken
}

export const initialState: AuthState = {
  user: {
    email: '',
  },
  error: undefined,
  session: undefined,
  ui: {
    isLoading: false,
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // action from session interceptor
    // session is updated on every api request
    // api requires this update for security
    // see: `addStoreSessionInterceptor`
    // lets use this as a merge from this action
    builder.addCase(
      session.actionType,
      (state, action: PayloadAction<SessionToken>) => {
        state.session = {
          ...state.session,
          ...action.payload,
        } as SessionToken
      }
    )
    // signup loading
    builder.addCase(signup.pending, (state) => {
      state.ui.isLoading = true
    })
    // login.pending
    builder.addCase(login.pending, (state) => {
      state.ui.isLoading = true
    })
    // logout.pending
    builder.addCase(logout.pending, (state) => {
      state.ui.isLoading = false
      state.user = initialState.user
    })
    // logout
    builder.addCase(logout.actionType, (state) => {
      state.session = undefined
    })
    // login.fulfilled
    builder.addCase(
      login.fulfilled,
      (state, action: PayloadAction<LoginResponse>) => {
        state.ui.isLoading = false
        state.user = action.payload
        state.error = undefined
      }
    )
    // userByUID.fulfilled
    builder.addCase(
      userByUID.fulfilled,
      (state, action: PayloadAction<LoginResponse>) => {
        state.ui.isLoading = false
        state.user = action.payload
        state.error = undefined
      }
    )
    // login.rejected
    builder.addCase(
      login.rejected,
      (state, action: PayloadAction<{ message?: string | undefined }>) => {
        state.ui.isLoading = false
        state.error = action.payload.message
      }
    )
  },
})

export const authReducer = authSlice.reducer
