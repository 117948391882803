import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Brand } from '@tvi/types/brand'
import { initiateClient } from '../clients/clients.saga'
import { fetchBrands } from './brands.saga'

const BRANDS_DATA_KEY = 'brands'

export type BrandsState = {
  clients: Brand[]
  ui: {
    isLoading: boolean
    error?: string
  }
}

/**
 * Brands Entity Adapter
 */
const brandsAdapter = createEntityAdapter<Brand>({
  selectId: (brand) => brand.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

/**
 * Brands Slice
 */
export const brandsSlice = createSlice({
  name: BRANDS_DATA_KEY,
  initialState: brandsAdapter.getInitialState(),
  reducers: {
    add: brandsAdapter.addOne,
    update: brandsAdapter.updateOne,
    remove: brandsAdapter.removeOne,
    set: brandsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrands.fulfilled, (state, action: any) => {
      brandsAdapter.upsertMany(state, action.payload)
    })
    builder.addCase(initiateClient.fulfilled, (state, action: any) => {
      brandsAdapter.upsertMany(state, action.payload.brands)
    })
  },
})

export const brandsReducer = brandsSlice.reducer
export const { selectById } = brandsAdapter.getSelectors()
