import styled from '@emotion/styled'
import { pxToRem } from '@tvi/uikit/utils/html-units'

const ConnectWrapper = styled.div`
  display: block;
  text-align: center;

  .connect-module__actions {
    margin: ${pxToRem(10)};
    margin-bottom: ${pxToRem(30)};
  }

  .connect-card {
    margin: ${pxToRem(5)};
  }
`

export default ConnectWrapper
