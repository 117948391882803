import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { pxToRem } from '@tvi/uikit/utils/html-units'

export const PlaylistModuleContainer = styled(Box)(() => ({
  '.MuiAutocomplete-root': {
    minWidth: pxToRem(175),
    input: {
      minWidth: 0,
      caretColor: 'transparent',
    },
    label: {
      marginLeft: 0,
    },
    '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      minHeight: pxToRem(41),
      paddingRight: `${pxToRem(5)} !important`,
    },
    '.MuiOutlinedInput-root': {
      fontSize: pxToRem(12),
      paddingRight: 0,
    },
    '.MuiChip-label': {
      fontSize: pxToRem(12),
      fontWeight: 600,
    },
    '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      minWidth: pxToRem(20),
      paddingRight: 0,
    },
    '.MuiAutocomplete-tag': {
      height: pxToRem(25),
    },
    '.MuiAutocomplete-endAdornment': {
      display: 'flex',
      alignItems: 'center',
      top: `calc(50% - ${pxToRem(15)})`,
      minHeight: pxToRem(30),
    },
    '.MuiAutocomplete-clearIndicator': {
      width: pxToRem(24),
      height: pxToRem(24),
      svg: {
        width: '100%',
        height: '100%',
      },
    },
  },
}))
