import { Box, SxProps } from '@mui/system'
import { pxToRem } from '@tvi/uikit/utils/html-units'
import { FC } from 'react'
import ContentLoader from 'react-content-loader'
import { rangeArray } from '../../utils/array'

export type LoaderListProps = {
  num?: number
  sx?: SxProps
  viewBox?: string
  width?: number
  height?: number
}

const LoaderList: FC<LoaderListProps> = ({
  num = 3,
  sx,
  width = '100%',
  height = '100%',
  viewBox = '0 0 450 50',
}) => (
  <Box
    sx={{
      marginTop: pxToRem(50),
      ...sx,
    }}
  >
    {rangeArray(num).map((i) => (
      <ContentLoader
        key={i}
        width={width}
        height={height}
        viewBox={viewBox}
        backgroundColor="#838383"
        foregroundColor="#c7c7c7"
        opacity={0.8}
      >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="8" />
        <rect x="0" y="20" rx="3" ry="3" width="100%" height="8" />
      </ContentLoader>
    ))}
  </Box>
)

export default LoaderList
