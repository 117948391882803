import { FC, ReactNode } from 'react'
import ConnectCardGrid from '../../../components/connect/ConnectCardGrid'
import ConnectCardGridItem, {
  ConnectProps,
} from '../../../components/connect/ConnectCardGridItem'
import { useBrandsProvider } from '../../brands/components/BrandsProvider'
import { useServiceProfilesProvider } from '../providers/ServiceProfilesProvider'

export type ConnectModuleProps = {
  CardList?: FC<{ children: ReactNode }>
  CardItem?: FC<ConnectProps>
}

const ConnectServiceModule: FC<ConnectModuleProps> = ({
  CardList = ConnectCardGrid,
  CardItem = ConnectCardGridItem,
}) => {
  const brand = useBrandsProvider()
  const { serviceProfiles, connect, disconnect, update } =
    useServiceProfilesProvider()

  return (
    <>
      <CardList className="connect-module__list">
        {brand &&
          serviceProfiles.map((serviceProfile) => (
            <CardItem
              key={serviceProfile.id}
              className="connect-module__item"
              brand={brand}
              connect={connect}
              disconnect={disconnect}
              serviceProfile={serviceProfile}
              update={update}
            />
          ))}
      </CardList>
    </>
  )
}

export default ConnectServiceModule
