import eviApi, {
  GetPlaylistItemDetailArgs,
  GetPlaylistItemsArgs,
  GetPlaylistsArgs,
  GetPlaylistsResponse,
  InitiateDownloadArgs,
  SuccessResponse,
} from '@tvi/api-client'
import { PlaylistItem } from '@tvi/types/playlist'
import { createAsyncSaga } from 'create-async-saga'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { httpErrorAction } from '../errors/errors.actions'
import { updateDownloadStatusAction } from '../playlist-videos/playlistVideos.saga'
import { selectPlaylistVideoById } from '../playlist-videos/playlistVideos.selectors'

export const fetchPlaylistItemDetailed = createAsyncSaga(
  'edisenVI/playlists/fetchPlaylistItemDetailed',
  function* fetchPlaylistItemDetailed(args: GetPlaylistItemDetailArgs) {
    try {
      return yield call(eviApi.v1.getPlaylistItemDetails, args)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const fetchPlaylistItems = createAsyncSaga(
  'edisenVI/playlists/fetchPlaylistItems',
  function* fetchPlaylistItems(args: GetPlaylistItemsArgs) {
    try {
      return (
        (yield call(eviApi.v1.getPlaylistItems, args)) as {
          playlistItems: PlaylistItem[]
        }
      ).playlistItems.map<PlaylistItem>((video) => ({
        ...video,
        ...args,
      }))
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const fetchPlaylists = createAsyncSaga(
  'edisenVI/playlists/getPlaylists',
  function* (args: GetPlaylistsArgs): any {
    try {
      return (yield call(eviApi.v1.getPlaylists, args)) as GetPlaylistsResponse
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const downloadVideo = createAsyncSaga(
  'edisenVI/playlists/playlistItem/downloadVideo',
  function* (args: InitiateDownloadArgs): any {
    const { resourceId } = args
    const playlistVideo = yield select(selectPlaylistVideoById(resourceId))
    if (!playlistVideo.isDownloadNone) {
      throw new Error(
        `PlaylistVideo is downloading: ${playlistVideo.downloadStatus}`
      )
    }
    try {
      // optimistic update
      yield put(
        updateDownloadStatusAction({
          resourceId,
          downloadStatus: 'pending',
        })
      )
      // api update
      return (yield call(eviApi.v1.initiateDownload, args)) as SuccessResponse
    } catch (error) {
      // revert optimistic update
      yield put(updateDownloadStatusAction(playlistVideo))
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export default function* playlistsSaga() {
  yield all([
    takeEvery(fetchPlaylists.actionType, fetchPlaylists.asyncSaga),
    takeEvery(fetchPlaylistItems.actionType, fetchPlaylistItems.asyncSaga),
    takeEvery(
      fetchPlaylistItemDetailed.actionType,
      fetchPlaylistItemDetailed.asyncSaga
    ),
    takeEvery(downloadVideo.actionType, downloadVideo.asyncSaga),
  ])
}

export const fetchPlaylistsActions = fetchPlaylists.action
export const fetchPlaylistItemsActions = fetchPlaylistItems.action
export const fetchPlaylistItemDetailsActions = fetchPlaylistItemDetailed.action
export const downloadVideoActions = downloadVideo.action
