import { useMemo } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import type { AppDispatch } from '../store'
import type { AppState } from '../reducers'

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useDispatchAction = <
  T extends { [key: string]: (...args: any[]) => AnyAction }
>(
  actions: T
) => {
  const dispatch = useDispatch<AppDispatch>()
  return useMemo(
    () =>
      Object.fromEntries(
        Object.entries(actions).map(([actionName, action]) => [
          actionName,
          (...args) => {
            dispatch(action(...args))
          },
        ])
      ),
    [actions, dispatch]
  ) as { [K in keyof T]: (...args: Parameters<T[K]>) => void }
}
