import { PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { all, call, takeEvery } from 'redux-saga/effects'
import actionTypes from '../../constants/actionTypes'
import { toastifyError } from '../../constants/appConfig'
import { HttpErrorPayload } from './errors.actions'

function* httpErrorSaga({ payload }: PayloadAction<HttpErrorPayload>) {
  let msg = 'Unknown error.'

  if (payload?.message) {
    msg = payload.message
    if (msg.match('playlistNotFound')) {
      msg = 'Playlist not found'
    }
  } else if (typeof payload === 'string') {
    msg = payload
  } else if (Array.isArray(payload?.errors)) {
    msg = payload.errors.join('\n')
  } else if (payload?.error?.message) {
    msg = payload?.error?.message
  }
  yield call([toast, 'error'], msg, toastifyError)
}

function* errorSaga() {
  yield all([takeEvery(actionTypes.HTTP_ERROR_TYPE, httpErrorSaga)])
}

export default errorSaga
