export const PALETTE = {
  gray100: '#0e1117',
  gray200: '#161a23',
  gray300: '#1e232f',
  gray400: '#22252E',
  grey450: '#232834',
  gray500: '#343944',
  gray600: '#6f7177',
  gray700: '#8e9197',
  gray800: '#cbcbcb',
  gray900: '#E5E5E5',
  blue500: '#4DB0C2',
  borderGray: '#343944',
  scrollbarGray: '#999999',
  scrollTrackGray: '#DDDDDD',
}
