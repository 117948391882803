// Remix (Edits)
// prettier-ignore
export const configVideoEventsEdits = [
  'create_edit_update',
  'create_edit_complete',
] as const

export const configVideoEvents = [...configVideoEventsEdits] as const

export const videoEditApi = {
  edit: {
    url: process.env.REACT_APP_EDIT_API,
    version: process.env.REACT_APP_EDIT_VERSION,
  },
}
