/* istanbul ignore file */
import { create } from '@tvi/http'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 1 * 15 * 1000, // 15 seconds
  exclude: { query: false },
  // key: (req) => req.url + JSON.stringify(req.params),
})

const http = create({
  adapter: cache.adapter,
})

export default http
