// organize-imports-ignore
import './utils/disableEmotionWarnings'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { store } from './redux/store'
import reportWebVitals from './reportWebVitals'
import './bootstrap'

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

if (process.env.REACT_APP_VITALS && JSON.parse(process.env.REACT_APP_VITALS)) {
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals((...rest) => {
    if (process.env.NODE_ENV !== 'development') return
    return console.table([...rest])
  })
}
