import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { PlaylistItem } from '@tvi/types/playlist'
import { all, put, takeEvery } from 'redux-saga/effects'
import { BrandEventNotification } from '../../modules/brands/models/brand'
import { brandVideoEvent } from '../videos/videos.slice'

export type UpdateDownloadStatus = {
  resourceId: string
  downloadStatus: PlaylistItem['downloadStatus']
}

/*
 * On Brand Video Notifications
 */
const onBrandVideoNotification = function* onBrandVideoNotification(
  action: PayloadAction<BrandEventNotification>
) {
  const { event: playlistItem, type } = action.payload
  const { resourceId, downloadStatus } = playlistItem

  switch (type) {
    case 'video_creation_update':
      yield put(
        updateDownloadStatusAction({
          resourceId,
          downloadStatus: 'complete',
        })
      )
      break
    case 'video_download_update':
      yield put(
        updateDownloadStatusAction({
          resourceId,
          downloadStatus,
        })
      )
      break
    case 'youtube_video_destroyed':
      yield put(
        updateDownloadStatusAction({
          resourceId,
          downloadStatus: 'none',
        })
      )
      break
  }
}

export default function* playlistVideosSaga() {
  yield all([takeEvery(brandVideoEvent.type, onBrandVideoNotification)])
}

export const updateDownloadStatusAction = createAction<UpdateDownloadStatus>(
  'playlist/update/download/status'
)
