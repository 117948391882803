import Box from '@mui/system/Box'
import { ButtonBack } from '@tvi/uikit'
import { pxToRem } from '@tvi/uikit/utils/html-units'
import { FC } from 'react'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import SettingsTabs from '../../components/settings-tabs/SettingsTabs'
import { ROUTES } from '../../constants/paths'
import BackPanelLayout from '../../layouts/back-panel-layout/BackPanelLayout'
import ContentContainer from '../../layouts/content-container/ContentContainer'
import browserHistory from '../../utils/browserHistory'
import LinkedAccounts from './linked-accounts/LinkedAccounts'

const Settings: FC = () => {
  return (
    <Box>
      <BackPanelLayout>
        <NavLink to={ROUTES.home}>
          <ButtonBack text="Back" />
        </NavLink>
      </BackPanelLayout>
      <ContentContainer mt={pxToRem(45)} sx={{ display: 'flex' }}>
        <Box
          mr={pxToRem(50)}
          sx={{
            width: pxToRem(336),
            flexShrink: 0,
          }}
        >
          <SettingsTabs onChange={browserHistory.push} />
        </Box>
        <Switch>
          <Route exact path={ROUTES.settings}>
            <Redirect
              to={ROUTES.settingsLinkedAccounts}
              from={ROUTES.settings}
            />
          </Route>
          <Route exact path={ROUTES.settingsLinkedAccounts}>
            <LinkedAccounts />
          </Route>
        </Switch>
      </ContentContainer>
    </Box>
  )
}

export default Settings
