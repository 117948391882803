import { AnalysisStatusTypes } from '@tvi/types/analysis'
import { Brand } from '@tvi/types/brand'
import { ServiceProfileServices } from '@tvi/types/serviceProfile'
import camelcaseKeys from 'camelcase-keys'
import isEmpty from 'lodash.isempty'
import { Observable } from 'rxjs'
import { configBrandEvents } from '../../../constants/brandsConfig'
import { configNotificationServiceType } from '../../../constants/notifications'
import { getNotificationServiceBrandChannelFactory } from '../../notifications/services/services'

const notificationServiceType = configNotificationServiceType

// Event keys that we should subscribe to
export type BrandEventType = typeof configBrandEvents[number]

// Notification event object - currently Pusher
export interface BrandEventNotificationInterface {
  status: AnalysisStatusTypes
  brandId: number
  resourceId: string
  videoId?: number
  projectId?: number
  service?: ServiceProfileServices
  downloadStatus?: 'pending' | 'complete' | 'none'
}

export interface BrandEventNotification {
  type: BrandEventType
  event: BrandEventNotificationInterface
}

export interface BrandEnhanced extends Brand {
  events?: Observable<any>
}

/*
 * For each BrandEventType for a user's brand listen
 * for events from notification service and publish that event
 */
const observeAsyncEvents = (
  brand: Brand,
  keys: BrandEventType[]
): Observable<BrandEventNotification> => {
  return new Observable<BrandEventNotification>((observer) => {
    const channel = getNotificationServiceBrandChannelFactory(
      brand,
      notificationServiceType
    )

    keys.forEach((key) => {
      channel.unbind(key)
      channel.bind(key, (event: BrandEventNotificationInterface) => {
        if (event) {
          observer.next({
            type: key,
            event: camelcaseKeys(event),
          })
        } else {
          observer.complete()
        }
      })
    })
  })
}

export const enhancedBrands = (brand: BrandEnhanced): BrandEnhanced => {
  return {
    ...(brand || {}),
    events: isEmpty(brand?.events)
      ? observeAsyncEvents(brand, configBrandEvents.slice())
      : brand?.events,
  }
}
