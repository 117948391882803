import { TVITheme } from '@tvi/uikit'
import { PALETTE } from '../../constants/uiColors'

export type SimpleBarStylesProps = {
  theme: TVITheme
  outOfContent?: boolean
}

export const simpleBarStyles = ({
  theme,
  outOfContent,
}: SimpleBarStylesProps) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  paddingRight: outOfContent ? 8 : undefined,
  '.simplebar-track': {
    pointerEvents: 'all',
  },
  '.simplebar-scrollbar:before': {
    backgroundColor: theme.palette.primary.main,
    opacity: 0,
    transition: '0.2s',
  },
  '.simplebar-horizontal .simplebar-scrollbar:before': {
    opacity: 1,
  },
  '.simplebar-vertical .simplebar-scrollbar:before': {
    left: 2,
    right: 2,
  },
  '.simplebar-vertical .simplebar-scrollbar.simplebar-visible:before': {
    opacity: 1,
  },
  '.simplebar-track.simplebar-vertical': {
    width: 8,
    '&:before': {
      left: 0,
      right: 0,
    },
  },
  '.simplebar-track.simplebar-horizontal': {
    height: '8px',
    // bottom: 0,
    backgroundColor: PALETTE.gray400,
    borderRadius: '4px',
    border: `1px solid ${PALETTE.borderGray}`,
  },
  '.simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    top: 0,
    height: '100%',
  },
})

export const simpleBarSmallStyles = ({ theme }: SimpleBarStylesProps) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  '.simplebar-track': {
    pointerEvents: 'all',
  },
  '.simplebar-track.simplebar-vertical': {
    width: '4px',
  },
  '.simplebar-vertical .simplebar-scrollbar:before': {
    top: 2,
    bottom: 2,
    left: 0,
    right: 0,
    borderRadius: 0,
    background: PALETTE.scrollbarGray,
  },
  '.simplebar-horizontal .simplebar-scrollbar:before': {
    opacity: 1,
  },
  '.simplebar-vertical .simplebar-scrollbar.simplebar-visible:before': {
    opacity: 1,
  },
  '.simplebar-track.simplebar-horizontal': {
    bottom: 0,
    backgroundColor: PALETTE.scrollTrackGray,
    borderRadius: 0,
    border: 0,
  },
  '.simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    top: 0,
    height: '100%',
  },
})
