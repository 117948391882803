import eviApi, { StatusResponse } from '@tvi/api-client'
import {
  PostUploadArgs,
  StoreCloudUploadArgs,
} from '@tvi/api-client/services/assets/AssetService'
import { create } from '@tvi/http'
import { Asset, UploadStorageParams } from '@tvi/types/asset'
import { createAsyncSaga } from 'create-async-saga'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { httpErrorAction } from '../errors/errors.actions'

export const initiateUploadAsset = function* (args: {
  mimeType: Asset['mimeType']
}) {
  try {
    return (yield call(
      eviApi.v1.initiateUploadAsset,
      args.mimeType
    )) as UploadStorageParams
  } catch (error) {
    yield put(httpErrorAction(error))
    throw error
  }
}

export const storeCloudUpload = function* (args: StoreCloudUploadArgs) {
  try {
    const http = create()
    http.defaults.headers.put = {
      'Content-Type': args.file.type,
    }
    return http
      .put(args.uploadUri, args.file, {
        onUploadProgress: args.progress,
      })
      .catch(() => {
        // gcs always throws a cors error even on success
      })
    // yield call(eviApi.v1.storeCloudUpload, args)
  } catch (error: any) {
    // GCS throws a CORS error regardless of success or failure
  }
}

export const postUpload = function* (args: PostUploadArgs) {
  try {
    return (yield call(eviApi.v1.postUpload, args)) as Asset
  } catch (error) {
    yield put(httpErrorAction(error))
    throw error
  }
}

export const deleteAsset = createAsyncSaga(
  'assets/delete',
  function* (args: { id: number }) {
    try {
      ;(yield call(eviApi.v1.deleteAsset, args.id)) as StatusResponse
      return args
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const fetchAssets = createAsyncSaga(
  'assets/get',
  function* (args: { projectId: number }) {
    try {
      return (yield call(eviApi.v1.getAssets, args.projectId)) as Asset[]
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export type SimpleUploadItemType = {
  projectId: number
  file: File
  mimeType: Asset['mimeType']
  progress?(event: ProgressEvent): void
}

export type UploadAssetsArgs = SimpleUploadItemType & {
  onComplete?(asset: Asset): void
}

export const uploadAsset = createAsyncSaga(
  'assets/upload',
  function* ({
    mimeType,
    file,
    projectId,
    progress,
    onComplete,
  }: UploadAssetsArgs) {
    const { gcsUri, uploadUri } = yield call(initiateUploadAsset, {
      mimeType,
    })
    yield call(storeCloudUpload, {
      uploadUri: uploadUri,
      file,
      progress,
    })
    const result = yield call(postUpload, {
      projectId,
      mimeType,
      fileName: file.name,
      uri: gcsUri,
    })

    onComplete?.(result)
    return result
  }
)

export default function* assetsSaga() {
  yield all([
    takeEvery(deleteAsset.actionType, deleteAsset.asyncSaga),
    takeLatest(fetchAssets.actionType, fetchAssets.asyncSaga),
    takeEvery(uploadAsset.actionType, uploadAsset.asyncSaga),
  ])
}

export const deleteAssetAction = deleteAsset.action
export const fetchAssetsAction = fetchAssets.action
export const uploadAssetAction = uploadAsset.action
