import { createSelector } from '@reduxjs/toolkit'
import { ServiceProfile } from '@tvi/types/serviceProfile'
import toArray from 'lodash/toArray'
import { AppState } from '../reducers'
import { transformEntities } from '../utils/entities'
import { serviceProfileSelectById } from './serviceProfiles.slice'

/**
 * selectServiceProfiles - generic selection of serviceProfiles (ids, entities)
 * state is stored as a list of ids and a map of entities for faster lookup and flexibility
 */
export const selectServiceProfilesSelector = (state: AppState) => {
  const data = state.entities.serviceProfiles
  const entities = transformEntities<ServiceProfile>(
    data.entities,
    (entity) => ({
      ...entity,
      isConnected: entity.status === 'connected',
      isExpired: entity.status === 'expired',
    })
  )
  return {
    ...data,
    entities,
  }
}

/**
 * selectServiceProfilesLoading - are serviceProfiles loading
 */
export const selectServiceProfilesLoading = (state: AppState) => {
  return state.entities.serviceProfiles.loading
}

/**
 * selectServiceProfilesLoading - are serviceProfiles loading
 */
export const selectServiceProfiles = (state: AppState) => {
  return state.entities.serviceProfiles
}

/**
 * hasServiceProfiles - are there any service profiles
 */
export const hasServiceProfilesSelector = (state: AppState): boolean =>
  state?.entities?.serviceProfiles?.ids?.length > 0

/**
 * serviceProfilesList - array of service profiles
 */
export const serviceProfilesListSelector = createSelector(
  selectServiceProfilesSelector,
  (serviceProfiles): ServiceProfile[] =>
    (toArray(serviceProfiles?.entities) || []) as ServiceProfile[]
)

/**
 * serviceProfilesActiveList - array of service profiles
 * that have minimum pending status
 */
export const serviceProfilesActiveList = createSelector(
  serviceProfilesListSelector,
  (serviceProfiles): ServiceProfile[] =>
    serviceProfiles.filter((serviceProfile) => Boolean(serviceProfile.status))
)

/**`
 * selectServiceProfileById
 */
export const selectServiceProfileById = (id: number) => {
  return createSelector(selectServiceProfilesSelector, (state) =>
    serviceProfileSelectById(state, id)
  )
}

/**
 * selectServiceProfileByBrandId
 */
export const selectServiceProfileByBrandId = (brandId: number) => {
  return createSelector(
    serviceProfilesActiveList,
    (serviceProfiles): ServiceProfile[] =>
      serviceProfiles.filter(
        (profile) => Number(profile.brandId) === Number(brandId)
      )
  )
}

/**
 * hasConnectedService - are there any connected services
 */
export const hasConnectedServiceSelector = createSelector(
  serviceProfilesListSelector,
  (list: ServiceProfile[]): boolean =>
    Boolean(list.find((serviceProfile) => serviceProfile.isConnected))
)
