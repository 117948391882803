import { createSelector } from '@reduxjs/toolkit'
import { Playlist } from '@tvi/types/playlist'
import toArray from 'lodash/toArray'
import { Loaders } from '../entities/types'
import { selectPlaylistVideosListsMappedSelector } from '../playlist-videos/playlistVideos.selectors'
import { AppState } from '../reducers'

/**
 * selectPlaylistsSelector - generic selection of playlists (ids, entities)
 */
export const selectPlaylistsSelector = (state: AppState) =>
  state.entities.playlists

/**
 * selectPlaylistsListsSelector - array of playlists
 */
export const selectPlaylistsListsSelector = createSelector(
  selectPlaylistsSelector,
  (playlists): Playlist[] => (toArray(playlists?.entities) || []) as Playlist[]
)

/**
 * selectPlaylistsListsSelector - array of playlists
 */
export const selectPlaylistsByProfileIds = (profileIds: number[]) =>
  createSelector(selectPlaylistsListsSelector, (playlists): Playlist[] =>
    playlists.filter((playlist) =>
      profileIds.includes(playlist.serviceProfileId as number)
    )
  )

/**
 * selectPlaylistsListsWithVideosSelector - array of playlists with mapped videos
 */
export const selectPlaylistsListsWithVideosSelector = (profileIds: number[]) =>
  createSelector(
    selectPlaylistsByProfileIds(profileIds),
    selectPlaylistVideosListsMappedSelector,
    (playlists, videos): Playlist[] =>
      playlists.map((playlist) => {
        return {
          ...playlist,
          videos: videos.filter((video) => playlist.id === video.playlistId),
        }
      })
  )

export const selectPlaylistsLoading = createSelector(
  selectPlaylistsSelector,
  (playlists): boolean => playlists?.ui?.isLoading
)

/**
 * selectLoadersByServiceProfileId
 * loader states from a service profile id
 */
export const selectLoadersByServiceProfileId = (serviceProfileId: number) =>
  createSelector(
    selectPlaylistsSelector,
    (playlists): Loaders => playlists.loaders[serviceProfileId]
  )

/**
 * selectLoadersByServiceProfileIds
 * loader states from a list of service profile ids
 */
export const selectLoadersByServiceProfileIds = (serviceProfileIds: number[]) =>
  createSelector(
    selectPlaylistsSelector,
    (playlists): Loaders =>
      serviceProfileIds.reduce((loaders, serviceProfileId) => {
        const loader = playlists.loaders[serviceProfileId]
        return {
          ...loaders,
          ...(loader
            ? {
                [serviceProfileId]: loader,
              }
            : undefined),
        }
      }, {})
  )

/**
 * selectLoadingByServiceProfileIds
 * if all service profiles by ids are loading
 */
export const selectLoadingByServiceProfileIds = (serviceProfileIds: number[]) =>
  createSelector(selectPlaylistsSelector, (playlists): boolean =>
    serviceProfileIds.some(
      (serviceProfileId) => playlists.loaders[serviceProfileId]?.loading
    )
  )

/**
 * selectLoadedByServiceProfileIds
 * if all service profiles by ids are loaded
 */
export const selectLoadedByServiceProfileIds = (serviceProfileIds: number[]) =>
  createSelector(selectPlaylistsSelector, (playlists): boolean =>
    serviceProfileIds.every(
      (serviceProfileId) => playlists.loaders[serviceProfileId]?.loaded
    )
  )
