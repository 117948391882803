import styled from '@emotion/styled'
import { Container, Theme, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Tabs } from '@tvi/uikit'
import { pxToRem } from '@tvi/uikit/utils/html-units'
import { PALETTE } from '../../../../constants/uiColors'

export const VideoDetailsModuleContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  background: ${({ theme }: { theme?: Theme }) =>
    theme?.palette?.background?.default};

  .track-horizontal {
    position: absolute;
    height: 6px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 3px;
  }

  .thumb-horizontal {
    position: relative;
    display: block;
    height: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(125, 149, 255, 0.993); // changed the color
  }
`

export const VideoDetailsInfoHeader = styled(Box)`
  border-bottom: 1px solid ${PALETTE.borderGray};
  padding: ${pxToRem(10)} 0;
`

export const VideoDetailsInfoHeaderType = styled(Typography)`
  font-size: ${pxToRem(12)};
  font-weight: 600;
  color: #fff;
`

export const VideoDetailsHeader = styled(Box)`
  border-bottom: 2px solid ${PALETTE.gray100};
`

export const VideoDetailsTabs = styled(Tabs)`
  button {
    margin: 0 2vw;
  }
`

export const VideoDetailsContentContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: ${pxToRem(45)} 0;
`

export const VideoDetailsFilmstripItem = styled(Typography)`
  font-size: ${pxToRem(12)};
  color: #fff;
`
