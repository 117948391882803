import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { Project } from '@tvi/types/project'
import { Video } from '@tvi/types/video'
import { schema } from 'normalizr'
import { deleteProject, fetchProjects } from './projects.saga'

const PROJECTS_DATA_KEY = 'projects'

export type ProjectsState = {
  projects: Project[]
  ui: {
    isLoading: boolean
    error?: string
  }
}

export type FetchProjectsType = {
  brandId: number
  expanded?: boolean
}

type FetchProjectsAction = PayloadAction<
  Project[],
  string,
  { arg: FetchProjectsType }
>

const projectSchema: schema.Entity<Project> = new schema.Entity(
  PROJECTS_DATA_KEY
)
// const projectsSchema = new schema.Array(projectSchema)

/**
 * Projects Entity Adapter
 */
const projectsAdapter = createEntityAdapter<Project>({
  selectId: (project) => project.id,
  sortComparer: (a, b) => a.label.localeCompare(b.label),
})

/**
 * Projects Slice
 */
export const projectsSlice = createSlice({
  name: PROJECTS_DATA_KEY,
  initialState: projectsAdapter.getInitialState({
    ui: {
      isLoading: false,
    },
  }),
  reducers: {
    add: projectsAdapter.addOne,
    update: projectsAdapter.updateOne,
    remove: projectsAdapter.removeOne,
    set: projectsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.ui = {
        isLoading: true,
      } as any
    })
    builder.addCase(deleteProject.fulfilled, (state, action: any) => {
      projectsAdapter.removeOne(state, action.meta.arg.id)
    })
    // builder.addCase(setActiveClient.pending, (state, action: any) => {
    //   // when we have a client switch we must reload projects
    //   state.ui = {
    //     isLoading: true,
    //   }
    // })
    builder.addCase(fetchProjects.fulfilled, (state, action: any) => {
      // excludeVideos
      // We don't want to update some videos when we know they are loaded
      // and have updated data
      const excludeVideos = (action.meta?.arg?.excludeVideos || []) as number[]

      const brandId = action.meta.arg.brandId
      projectsAdapter.upsertMany(
        state,
        (action.payload as Project[]).map((project) => ({
          ...project,
          brandId,
          videos: (project.videos as Video[])
            .filter((video: Video) => !excludeVideos.includes(video.id))
            .map((video) => ({
              ...(video as Video),
              brandId,
            })),
        }))
      )
      state.ui = {
        isLoading: false,
      }
    })
  },
})

export const projectsReducer = projectsSlice.reducer
