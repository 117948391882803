import AddLinkIcon from '@mui/icons-material/AddLink'
import UnLinkIcon from '@mui/icons-material/Link'
import VideosIcon from '@mui/icons-material/VideoLibrary'
import { Input, TableCell, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Button, TVIYoutubeIcon } from '@tvi/uikit'
import { ChangeEvent, FC, FocusEvent, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants/paths'
import { toCapitalize } from '../../utils/transform'
import './Connect.scss'
import { ConnectProps } from './ConnectCardGridItem'

const ConnectCardListItem: FC<ConnectProps> = ({
  serviceProfile,
  brand,
  connect,
  disconnect,
  update,
}) => {
  const { email, service, status, name, isConnected } = serviceProfile
  const typeText = useMemo(() => toCapitalize(service), [service])
  const [profileName, setProfileName] = useState<string>(name as string)

  const updateName = () => {
    if (!profileName) return
    update({
      ...serviceProfile,
      name: profileName,
    })
  }

  const handleChangeName = (
    e: FocusEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>
  ) => {
    setProfileName(e.currentTarget.value)
  }

  return (
    <TableRow>
      <TableCell
        sx={{
          width: '20%',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TVIYoutubeIcon />
          <span>{typeText}</span>
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          width: '50%',
        }}
      >
        <Input
          value={profileName}
          onChange={handleChangeName}
          onBlur={updateName}
          fullWidth={true}
        />
      </TableCell>
      <TableCell
        sx={{
          width: '30%',
        }}
      >
        <Box
          className="connect-actions"
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(3, 1fr)`,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            className="connect-action-button"
            color="secondary"
            variant="text"
            type="button"
            onClick={() => disconnect(serviceProfile.id)}
          >
            <Typography lineHeight={0} p={0} color="secondary">
              <UnLinkIcon />
            </Typography>
            <Typography
              position="absolute"
              bottom={-3}
              fontSize={8}
              fontWeight={600}
              lineHeight={0}
              mt={4}
              p={0}
              color="secondary"
            >
              delete
            </Typography>
          </Button>
          {isConnected && (
            <>
              <Button
                className="connect-action-button"
                component={Link}
                variant="text"
                to={`${ROUTES.videos}?filters=p:${serviceProfile.id}`}
                color="primary"
                type="button"
              >
                <Typography lineHeight={0} p={0} color="primary">
                  <VideosIcon />
                </Typography>
                <Typography
                  position="absolute"
                  bottom={-4}
                  fontSize={8}
                  fontWeight={600}
                  lineHeight={0}
                  p={0}
                  color="grey.500"
                >
                  {status}
                </Typography>
              </Button>
            </>
          )}
          {!isConnected && (
            <>
              <Button
                className="connect-action-button"
                variant="text"
                type="button"
                onClick={() => {
                  // we can't update the service profile so we must
                  // delete the previous one and then connect anew
                  connect(email, brand.id, service, serviceProfile.name)
                  disconnect(serviceProfile.id)
                }}
              >
                <Typography lineHeight={0} p={0} color="grey.500">
                  <AddLinkIcon />
                </Typography>
                <Typography
                  position="absolute"
                  bottom={-4}
                  fontSize={8}
                  fontWeight={600}
                  lineHeight={0}
                  mt={4}
                  p={0}
                  color="grey.500"
                >
                  {status}
                </Typography>
              </Button>
            </>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default ConnectCardListItem
