import { Brand } from '@tvi/types/brand'
import Pusher from 'pusher-js'
import NotificationService from '.'
import pusherConfig from '../../../constants/notifications'
import {
  Channel,
  NotificationServiceObjectInterface,
  NotificationServiceTypes,
} from './types'

const NotificationServices = new Map<string, NotificationService>()

/*
 * only support pusher for now
 */
export const getNotificationServiceFactory = (
  type: NotificationServiceTypes = 'pusher',
  config: any = pusherConfig
) => {
  let service: NotificationServiceObjectInterface

  if (NotificationServices.get(type)) {
    return NotificationServices.get(type)
  }

  switch (type) {
    default:
      service = new Pusher(config?.key, {
        cluster: config?.cluster,
        disableStats: true,
      }) as unknown as NotificationServiceObjectInterface
      break
  }

  // prettier-ignore
  return NotificationServices
          .set(type, new NotificationService(service))
          .get(type)
}

/*
 * only support pusher for now
 */
export const getNotificationServiceBrandChannelFactory = (
  brand: Brand,
  type: NotificationServiceTypes = 'pusher',
  config: any = pusherConfig,
  key: string | undefined = undefined
): Channel => {
  const service = getNotificationServiceFactory(
    'pusher',
    config
  ) as NotificationService

  if (key) {
    return service.getChannel(key)
  }

  let definedKey

  switch (type) {
    default:
      definedKey = `${config.keys.brand}_${brand.id}`
      break
  }

  return service.getChannel(definedKey)
}
