import eviApi from '@tvi/api-client'
import { SessionToken, SignInType, UserRegistration } from '@tvi/types/api'
import { createAsyncSaga } from 'create-async-saga'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { REDIRECT } from '../../constants/paths'
import browserHistory from '../../utils/browserHistory'
import { httpErrorAction } from '../errors/errors.actions'
import { afterLoginCommandAction } from '../sagas/commands/after-login.command'

/*
 * ======================================================
 * ACTION SAGAS
 * lets generally separate async calls
 * and reactive actives such as redirects
 * and other side effects. We could keep them
 * in single sagas but separating them makes
 * it easier to find those actions that
 * are dependent on state changes and business rules.
 * ======================================================
 */

export const signup = createAsyncSaga(
  'edisenVI/auth/signup',
  function* signupSaga(payload: UserRegistration) {
    try {
      return yield call(eviApi.v1.signUp, payload)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const session = createAsyncSaga(
  'edisenVI/auth/session',
  function* sessionSaga(payload: SessionToken) {
    return yield payload
  }
)

export const login = createAsyncSaga(
  'edisenVI/auth/login',
  function* loginSaga(payload: SignInType) {
    try {
      return yield call(eviApi.v1.signIn, payload)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const userByUID = createAsyncSaga(
  'edisenVI/auth/user/uid',
  function* userByUID(payload: { uid: string }) {
    try {
      return yield call(eviApi.v1.userByUID, payload)
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const logout = createAsyncSaga('auth/logout', function* (): any {
  try {
    yield call(eviApi.v1.signOut)
  } catch (error) {
    //
  } finally {
    yield call(browserHistory.push, REDIRECT.afterLogout)
  }
})

/*
 * ======================================================
 * REACTION SAGAS / BUSINESS RULE DRIVEN
 * these will generally have names prefixed with 'on'
 * such as 'onLoginSuccess' or 'afterSubscription'
 * ======================================================
 */

/*
 * After login
 * - If a user has any connected services then
 *   they should be redirected to the select videos page
 * - Else they should be redirected to the connect page
 */
const onLoginSuccess = function* onLoginSuccess(): any {
  // call after login command
  yield put(afterLoginCommandAction())
}

/*
 * After Registration
 * - log user in
 * todo: needs response type
 */
const onRegistrationSuccess = function* onRegistrationSuccess(
  response: any
): any {
  // call after login command
  const { email, password } = response.meta.arg
  yield put(loginAction({ email, password }))
}

/*
 * ======================================================
 * SAGA LISTENERS
 * ======================================================
 */

export default function* authSaga() {
  yield all([
    takeEvery(login.actionType, login.asyncSaga),
    takeEvery(logout.actionType, logout.asyncSaga),
    takeEvery(signup.actionType, signup.asyncSaga),
    takeEvery(session.actionType, session.asyncSaga),
    takeEvery(userByUID.actionType, userByUID.asyncSaga),
    takeEvery(login.fulfilled, onLoginSuccess),
    takeEvery(signup.fulfilled, onRegistrationSuccess),
  ])
}

/*
 * ======================================================
 * SAGA ACTIONS
 * ======================================================
 */

export const loginAction = login.action
export const logoutAction = logout.action
export const signupAction = signup.action
export const sessionAction = session.action
export const userByUIDAction = userByUID.action
