import { Tabs } from '@tvi/uikit'
import { FC, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router'
import { ROUTES } from '../../constants/paths'

export const settingsTabs = {
  // 'General Settings': ROUTES.settings,
  'Linked Accounts': ROUTES.settingsLinkedAccounts,
}
type TabsKeys = keyof typeof settingsTabs
const tabs: string[] = Object.keys(settingsTabs)
const tabRoutes: string[] = Object.values(settingsTabs)

export type SettingsTabsProps = {
  onChange(tabRoute: string, tabKey: string): void
}

const SettingsTabs: FC<SettingsTabsProps> = ({ onChange }) => {
  const location = useLocation()
  const onChangeTab = useCallback(
    (tabIndex) =>
      onChange(settingsTabs[tabs[tabIndex] as TabsKeys], tabs[tabIndex]),
    [onChange]
  )

  const activeTab = useMemo(
    () => tabRoutes.indexOf(location.pathname),
    [location.pathname]
  )

  return (
    <Tabs
      orientation="vertical"
      tabLabels={tabs}
      activeTabIndex={activeTab}
      onChange={onChangeTab}
    />
  )
}

export default SettingsTabs
