import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { BrandsProvider } from '../../../modules/brands/components/BrandsProvider'
import LinkedAccounts from '../../../modules/serviceProfiles/components/LinkAccounts'
import { ServiceProfilesProvider } from '../../../modules/serviceProfiles/providers/ServiceProfilesProvider'

export type LinkedAccountsProps = {}

const LinkedAccountsPage: FC<LinkedAccountsProps> = () => {
  return (
    <>
      <Helmet>
        <title>Linked Accounts</title>
      </Helmet>
      <BrandsProvider>
        <ServiceProfilesProvider>
          <LinkedAccounts />
        </ServiceProfilesProvider>
      </BrandsProvider>
    </>
  )
}

export default LinkedAccountsPage
