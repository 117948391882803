import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { PlaylistItem } from '@tvi/types/playlist'
import { fetchPlaylistItems } from '../playlists/playlists.saga'
import {
  UpdateDownloadStatus,
  updateDownloadStatusAction,
} from './playlistVideos.saga'
import { transformPlaylistVideoEntity } from './transformers'

const PLAYLIST_VIDEOS_DATA_KEY = 'playlistVideos'

/**
 * PlaylistVideos Entity Adapter
 */
const playlistVideosAdapter = createEntityAdapter<PlaylistItem>({
  selectId: (video) => video.id,
  sortComparer: (a, b) => a.title.localeCompare(b.title),
})

/**
 * PlaylistVideos Slice
 */
export const playlistVideosSlice = createSlice({
  name: PLAYLIST_VIDEOS_DATA_KEY,
  initialState: playlistVideosAdapter.getInitialState(),
  reducers: {
    add: playlistVideosAdapter.addOne,
    update: playlistVideosAdapter.updateOne,
    remove: playlistVideosAdapter.removeOne,
    set: playlistVideosAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlaylistItems.fulfilled, (state, action) => {
      playlistVideosAdapter.upsertMany(
        state,
        action.payload.map((playlistVideo, index) => ({
          ...playlistVideo,
          sort: index,
        }))
      )
    })
    builder.addCase(
      updateDownloadStatusAction,
      (state, action: PayloadAction<UpdateDownloadStatus>) => {
        const { resourceId, downloadStatus } = action.payload
        const entity = state.entities[resourceId]
        if (!entity) return
        playlistVideosAdapter.upsertOne(
          state,
          transformPlaylistVideoEntity({
            ...entity,
            downloadStatus,
          })
        )
      }
    )
  },
})

export const { selectById } = playlistVideosAdapter.getSelectors()
export const playlistVideosReducer = playlistVideosSlice.reducer
