import React, { Context, createContext, ReactNode, useContext } from 'react'
import { useSelected, UseSelectedResponseType } from './useSelected'

type SelectedResponse<T = unknown, K = unknown> =
  | UseSelectedResponseType<T, K>
  | undefined

export const SelectedContext = createContext<SelectedResponse<any>>(undefined)

type Props<T, K> = {
  list?: T[]
  data?: UseSelectedResponseType<T, K>
  children: ReactNode
}

export function SelectedProvider<T, K>({
  data,
  children,
  list = [],
}: Props<T, K>) {
  let select = useSelected<T, K>({ list })

  if (data) {
    const selectedMap = new Map()
    data.all.forEach((item) => {
      if (data.selected.has(data.listKey(item))) {
        selectedMap.set(data.listKey(item), item)
      }
    })
    select = {
      ...data,
      selected: selectedMap,
    }
  }

  return (
    <SelectedContext.Provider value={{ ...select }}>
      {children}
    </SelectedContext.Provider>
  )
}

export function useSelectedContext<T, K = unknown>() {
  const context = useContext<SelectedResponse<T, K>>(
    SelectedContext as Context<SelectedResponse<T, K>>
  )

  if (context === undefined) {
    throw new Error('useSelectedContext must be used within a SelectedProvider')
  }

  return context
}

export default SelectedContext
