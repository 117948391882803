import Box, { BoxProps } from '@mui/material/Box'
import { pxToRem } from '@tvi/uikit/utils/html-units'
import { FC } from 'react'
import { LAYOUT_SIZE } from '../../constants/uiSizes'

export type ContentContainerProps = {} & BoxProps

// todo: Not sure this is needed MUI already has a Container
// component that does the same thing
// we should use Container and just set the custom sizes
// based on design needs
const ContentContainer: FC<ContentContainerProps> = ({ children, ...rest }) => {
  return (
    <Box
      {...rest}
      ml="auto"
      mr="auto"
      sx={{
        ...rest.sx,
        width: '100%',
        maxWidth: pxToRem(LAYOUT_SIZE.applicationContent),
      }}
    >
      {children}
    </Box>
  )
}

export default ContentContainer
