import { createTheme, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { theme } from '@tvi/uikit'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AppRouter from './AppRouter'
import Header from './components/header/Header'
import { toastifyDefault } from './constants/appConfig'
import BaseLayout from './layouts/base-layout/BaseLayout'
import browserHistory from './utils/browserHistory'
import './styles/index.scss'
import 'simplebar/dist/simplebar.min.css'
import { StyleContainer } from './App.style'

const appTheme = createTheme({
  breakpoints: {
    values: {
      ...theme.breakpoints.values,
      lg: 1176,
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={{ ...appTheme, ...theme }}>
      <Router history={browserHistory}>
        <CssBaseline />
        <StyleContainer>
          <BaseLayout header={<Header />}>
            <AppRouter />
          </BaseLayout>
        </StyleContainer>
      </Router>
      <ToastContainer {...toastifyDefault} />
    </ThemeProvider>
  )
}

export default App
