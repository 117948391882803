import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { alpha, MenuItem, MenuList, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Client } from '@tvi/types/client'
import { Button, ContextMenu, Search, TVITheme } from '@tvi/uikit'
import clsx from 'clsx'
import {
  ChangeEvent,
  FC,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../constants/paths'
import { PALETTE } from '../../constants/uiColors'
import {
  isAuthorizedStrictestSelector,
  userEmailSelector,
} from '../../redux/auth/auth.selectors'
import { setActiveClientAction } from '../../redux/clients/clients.saga'
import {
  activeClientSelector,
  clientListSelector,
  hasClients as hasClientsSelector,
} from '../../redux/clients/clients.selectors'
import { useAppSelector } from '../../redux/utils/hooks'
import { CustomScroll } from '../custom-scroll/CustomScroll'
import { ButtonUserMenu } from './UserMenu.styled'

const UserMenu: FC = () => {
  const dispatch = useDispatch()
  const anchorRef = useRef(null)
  const [searchStr, setSearchStr] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen((state) => !state)
  const isAuthorized = useAppSelector(isAuthorizedStrictestSelector)
  const email: string = useAppSelector(userEmailSelector)
  const hasClients: boolean = useAppSelector(hasClientsSelector)
  const activeClient: Client | null = useAppSelector(activeClientSelector)
  const clients: Client[] = useAppSelector(clientListSelector)

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const closeOnClick =
    (handler?: MouseEventHandler) => (e: MouseEvent<Element>) => {
      close()
      handler?.(e)
    }

  const searchClients = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchStr(e.target.value)
    },
    [setSearchStr]
  )

  const selectClient = useCallback(
    (client: Client) => {
      close()
      // add delay so that menu doesn't freeze on close
      setTimeout(() => {
        dispatch(setActiveClientAction(client))
      }, 0)
    },
    [dispatch, setActiveClientAction, close]
  )

  const filterClients = (item: Client) => {
    return ~item.name.toLowerCase().indexOf(searchStr.toLowerCase())
  }

  useEffect(() => {
    if (!isOpen) {
      setSearchStr('')
    }
  }, [isOpen])

  return !isAuthorized ? null : (
    <>
      <Button
        ref={anchorRef}
        aria-label="user-menu-btn"
        onClick={toggleOpen}
        endIcon={
          isOpen ? (
            <ArrowDropUpIcon htmlColor="white" />
          ) : (
            <ArrowDropDownIcon htmlColor="white" />
          )
        }
      >
        <Typography variant="subtitle1">{email}</Typography>
      </Button>
      <ContextMenu
        isCustomList
        arrow={false}
        aria-label="user-menu"
        placement="bottom"
        anchorEl={anchorRef.current}
        open={isOpen}
        onClose={toggleOpen}
        sx={{
          marginRight: 10,
          padding: 22,
          width: '270px',
          background: PALETTE.gray300,
          borderRadius: '2px',
        }}
      >
        <Box
          sx={{
            paddingBottom: 16,
          }}
        >
          <Search
            size="small"
            placeholder="Search"
            name="search"
            onChange={searchClients}
          />
        </Box>
        <CustomScroll
          outOfContent
          style={{
            maxHeight: '50vh',
            minHeight: '60px',
          }}
        >
          {hasClients ? (
            <MenuList dense aria-label="clients">
              {clients.filter(filterClients).map((client) => (
                <MenuItem
                  dense
                  key={client.id}
                  onClick={() => selectClient(client as Client)}
                  className={clsx({
                    selected: activeClient && activeClient.id === client.id,
                  })}
                  sx={(theme: TVITheme) => ({
                    justifyContent: 'flex-end',
                    '&:hover, &.selected': {
                      color: theme.palette.primary.contrastText,
                      background: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.activatedOpacity
                      ),
                    },
                  })}
                >
                  {client.name}
                </MenuItem>
              ))}
            </MenuList>
          ) : (
            <Typography variant="body2">No clients</Typography>
          )}
        </CustomScroll>
        <Box
          sx={{
            paddingTop: 10,
            display: 'flex',
            flexDirection: 'column',
            borderTopColor: PALETTE.gray500,
            borderTopWidth: 2,
            borderTopStyle: 'solid',
          }}
        >
          <NavLink to={ROUTES.videos}>
            <ButtonUserMenu
              fullWidth
              aria-label="videos"
              size="small"
              variant="contained"
              onClick={closeOnClick()}
            >
              Videos
            </ButtonUserMenu>
          </NavLink>
          <NavLink to={ROUTES.settings}>
            <ButtonUserMenu
              fullWidth
              aria-label="settings"
              size="small"
              variant="contained"
              onClick={closeOnClick()}
            >
              Account Settings
            </ButtonUserMenu>
          </NavLink>
        </Box>
      </ContextMenu>
    </>
  )
}

export default UserMenu
