import eviApi, { IdType } from '@tvi/api-client'
import { createAsyncSaga } from 'create-async-saga'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { httpErrorAction } from '../errors/errors.actions'
import { FetchProjectsType } from './projects.slice'

export const fetchProjects = createAsyncSaga(
  'edisenVI/projects/getProjects',
  function* ({ brandId, expanded = true }: FetchProjectsType): any {
    try {
      return yield call(eviApi.v1.getProjectsByBrandId, { brandId, expanded })
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export const deleteProject = createAsyncSaga(
  'edisenVI/projects/delete',
  function* (args: IdType): any {
    try {
      yield call(eviApi.v1.deleteProject, args)
      return args
    } catch (error) {
      yield put(httpErrorAction(error))
      throw error
    }
  }
)

export default function* projectsSaga() {
  yield all([
    takeEvery(fetchProjects.actionType, fetchProjects.asyncSaga),
    takeEvery(deleteProject.actionType, deleteProject.asyncSaga),
  ])
}

export const fetchProjectsAction = fetchProjects.action
export const deleteProjectsAction = deleteProject.action
