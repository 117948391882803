import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import { Brand } from '@tvi/types/brand'
import {
  ServiceProfile,
  ServiceProfileServices,
} from '@tvi/types/serviceProfile'
import { Button, Tag, TextBlock1, TVIYoutubeIcon } from '@tvi/uikit'
import { pxToRem } from '@tvi/uikit/utils/html-units'
import { trim } from 'lodash'
import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants/paths'
import { toCapitalize } from '../../utils/transform'
import './Connect.scss'

export type ConnectProps = {
  serviceProfile: ServiceProfile
  brand: Brand
  connect(
    email: string,
    brandId: number,
    service: ServiceProfileServices,
    name?: string
  ): void
  disconnect(id: number): void
  update(profile: ServiceProfile): void
}

const ConnectButtons = styled(Button)`
  margin: 0 5px;
`

const ConnectCardGridItem: FC<ConnectProps> = ({
  serviceProfile,
  brand,
  connect,
  disconnect,
}) => {
  const { email, service, status, isConnected } = serviceProfile
  const typeText = useMemo(() => toCapitalize(service), [service])

  return (
    <div className="connect-card user-form user-form--indent">
      {/* this icon should change by type */}
      <TVIYoutubeIcon
        sx={{
          fontSize: 110,
          color: 'primary.contrastText',
        }}
        inheritViewBox={false}
      />
      <TextBlock1
        title={`Link ${typeText} Account`}
        sx={{
          maxWidth: pxToRem(400),
        }}
      >
        {service && (
          <Box mt={-20} mb={20}>
            <Tag text={trim(`${status} ${email}`)} m="4px" />
          </Box>
        )}
        Click the Connect Account Button to sign into your account. Your browser
        will take you to the{' '}
        <span className="connect__type-text">{service}</span> sign in page,
        after your sign in, please come back to this page.
      </TextBlock1>

      <div className="user-form__form-controls">
        {isConnected && (
          <>
            <ConnectButtons
              color="secondary"
              type="button"
              onClick={() => disconnect(serviceProfile.id)}
            >
              Unlink Account
            </ConnectButtons>
            <ConnectButtons
              component={Link}
              to={`${ROUTES.videos}?filters=p:${serviceProfile.id}`}
              color="primary"
              type="button"
            >
              Select Videos
            </ConnectButtons>
          </>
        )}
        {!isConnected && (
          <Button
            color="primary"
            type="button"
            onClick={() => connect(email, brand.id, service)}
          >
            Link Account
          </Button>
        )}
      </div>
    </div>
  )
}

export default ConnectCardGridItem
