import { LinearProgress } from '@mui/material'
import { FC } from 'react'
import { VideoEnhanced } from '../../models/video'

const VideoProgress: FC<{ video: VideoEnhanced }> = ({ video }) => {
  return video?.analysisIsComplete || !video ? null : (
    <LinearProgress sx={{ position: 'absolute', width: '100%', bottom: 0 }} />
  )
}

export default VideoProgress
