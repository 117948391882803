import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material/styles/createTheme'
import MediaCard from '@tvi/uikit/components/media-card/MediaCard'
import DialogDelete from '../../../../components/dialog/DialogDelete'

export const VidoesConfirmDeleteDialog = styled(DialogDelete)(({ theme }) => ({
  '.MuiCardContent-root': {
    padding: 0,
  },
  '& .MuiCardMedia-root': {
    height: '100%',
  },
}))

export const VidoesConfirmDeleteMediaCard = styled(MediaCard)(
  ({ theme }: { theme: Theme }) => ({
    '& .MuiCardMedia-root': {
      height: '100%',
      maxHeight: theme.spacing(56),
      border: `1px solid ${theme.palette.grey[700]}`,
    },
    '.MuiCardContent-root': {
      padding: 0,
    },
  })
)
