import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logoutAction } from '../../redux/auth/auth.saga'

const Logout: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutAction(void 0))
  }, [dispatch, logoutAction])

  return <></>
}

export default Logout
