import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles/createTheme'
import { PALETTE } from '../../../constants/uiColors'

export const SelectedCardMedia = styled.div`
  position: relative;
  pointer-events: all;
  user-select: none;

  img {
    pointer-events: none;
  }

  &:before {
    position: absolute;
    object-fit: fill;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    transition: all 0.1s ease;
    pointer-events: none;
    content: '';

    .deleting & {
      border: 3px solid ${(props) => (props.theme as Theme).palette.error.main};
    }
  }

  &:hover:before {
    border: 3px solid ${() => PALETTE.gray700};
    transition: all 0.3s ease;
    content: '';
  }

  &.selected:before {
    border: 3px solid ${(props) => (props.theme as any).palette.primary.main};
    transition: all 0.05s ease;
    content: '';
  }

  .deleting & {
    pointer-events: none;
  }
`
