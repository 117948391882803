import {
  createEntityAdapter,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import { GetPlaylistsArgs } from '@tvi/api-client'
import { Playlist } from '@tvi/types/playlist'
import { Loaders } from '../entities/types'
import { fetchPlaylistItems, fetchPlaylists } from './playlists.saga'

const PLAYLISTS_DATA_KEY = 'playlists'

export type PlaylistsState = {
  playlists: Playlist[]
  loaders: Loaders
  ui: {
    isLoading: boolean
    error?: string
  }
}

/**
 * Playlists Entity Adapter
 */
const playlistsAdapter = createEntityAdapter<Playlist>({
  selectId: (playlist) => playlist.id,
  sortComparer: (a, b) => a.title.localeCompare(b.title),
})

/**
 * Playlists Slice
 */
export const playlistsSlice = createSlice({
  name: PLAYLISTS_DATA_KEY,
  initialState: playlistsAdapter.getInitialState({
    ui: {
      isLoading: true,
    },
    loaders: {} as Loaders,
  }),
  reducers: {
    add: playlistsAdapter.addOne,
    update: playlistsAdapter.updateOne,
    remove: playlistsAdapter.removeOne,
    set: playlistsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlaylists.fulfilled, (state, action: any) => {
      const serviceProfileId = action.meta.arg.serviceProfileId
      playlistsAdapter.upsertMany(
        state,
        (action.payload.playlists as Playlist[]).map((playlist) => ({
          ...playlist,
          serviceProfileId,
          loaded: true,
        }))
      )
      state.ui = {
        isLoading: false,
      }
    })
    builder.addMatcher(
      isAnyOf(fetchPlaylists.pending),
      (
        state,
        action: PayloadAction<void, string, { arg: GetPlaylistsArgs }>
      ) => {
        const serviceProfileId = action.meta.arg.serviceProfileId as number
        state.ui = {
          isLoading: true,
        }
        // state of loading playlists for a service profile
        state.loaders[serviceProfileId] = {
          loading: true,
          loaded: false,
        }
      }
    )
    builder.addMatcher(
      isAnyOf(fetchPlaylists.rejected, fetchPlaylists.fulfilled),
      (
        state,
        action: PayloadAction<void, string, { arg: GetPlaylistsArgs }>
      ) => {
        const serviceProfileId = action.meta.arg.serviceProfileId as number
        state.ui = {
          isLoading: false,
        }
        // state of loading playlists for a service profile
        state.loaders[serviceProfileId] = {
          loading: false,
          loaded: true,
        }
      }
    )
    builder.addMatcher(
      isAnyOf(fetchPlaylistItems.pending),
      (state, action: any) => {
        const playlist = state.entities[action.meta.arg.playlistId] as Playlist
        state.entities[action.meta.arg.playlistId] = {
          ...playlist,
          isLoading: true,
        }
      }
    )
    builder.addMatcher(
      isAnyOf(fetchPlaylistItems.fulfilled, fetchPlaylistItems.rejected),
      (state, action: any) => {
        const playlist = state.entities[action.meta.arg.playlistId] as Playlist
        state.entities[action.meta.arg.playlistId] = {
          ...playlist,
          isLoading: false,
          loaded: true,
        }
      }
    )
    builder.addMatcher(
      isAnyOf(fetchPlaylistItems.rejected),
      (state, action: any) => {
        const playlist = state.entities[action.meta.arg.playlistId] as Playlist
        state.entities[action.meta.arg.playlistId] = {
          ...playlist,
          error: action.payload,
        }
      }
    )
  },
})

export const playlistsReducer = playlistsSlice.reducer
