import { Scene } from '@tvi/types/scene'
import { Video } from '@tvi/types/video'
import { TransitionOption } from '../../../constants/videoConstants'
import { formatDate } from '../../../utils/date'
import { toGSResourceUrl } from '../../../utils/transform'
import { TimelineLayerRenderItem } from '../../timeline/lib/timeline'
import { VideoEnhanced } from './video'

export type ClipAudioTypes = 'mp3' | 'aiff' | 'wav'
export type ClipVideoTypes = 'mp4'
export type ClipAudioLayer = TimelineLayerRenderItem

export type ClipTransition = {
  type: TransitionOption['type']
  offset: number
  duration: number
}

export type Clip = {
  type: string
  url: string
  start: number
  end: number
  transition?: ClipTransition
}

export type CreateEditProps = {
  id: string
  video: VideoEnhanced
  clips: Clip[]
}

/*
 * createEditName
 * creating a video setting parentId, and meta holding remix data
 */
export const createEditName = ({ video }: { video: VideoEnhanced }) => {
  return `Remix: ${video.name} - ${formatDate(new Date())}`
}

/*
 * getAssetUrl
 * depending on storage return url
 */
export const getAssetUrl = (url: string) => {
  return toGSResourceUrl(url)
}

/*
 * createEditVideoClips
 * creating the clips
 */
export const createEditVideoClips = ({
  video,
  url,
  scenes,
  transitions,
  type = 'mp4',
}: {
  video: Video
  url: string
  scenes: Scene[]
  transitions: any
  type?: ClipVideoTypes
}): any => {
  let total = 0
  let last = 0
  let lastOffset = 0

  // FFMPEG needs at least 1 frame to create a transition
  const OFFSET_CONST = 1 / video.frameRate + 0.001
  return scenes.reduce((mapped: Clip[], scene: Scene) => {
    const duration = scene.endTimeOffset - scene.startTimeOffset
    // const start = last
    // const end = last + duration
    const start = scene.startTimeOffset
    const end = start + duration

    const inputTransition = transitions?.[scene.id]
    let transtionDuration = Math.min(duration, inputTransition?.out || 0)

    last = last + duration

    let offset = Number(
      Math.max(
        0,
        duration + lastOffset - transtionDuration - OFFSET_CONST
      ).toFixed(3)
    )

    const transition = {
      type: inputTransition?.type || 'fade',
      offset,
      duration: transtionDuration,
    } as ClipTransition

    const clip = {
      type,
      url,
      start,
      end,
      transition,
    }

    lastOffset = offset
    total = total + duration - transtionDuration
    return [...mapped, clip]
  }, [])
}

/*
 * createEditAudioClips
 * creating the clips
 */
export const createEditAudioClips = ({
  clips,
  type = 'mp3',
}: {
  clips: ClipAudioLayer[]
  type?: ClipAudioTypes
}): Clip[] => {
  return clips.reduce((mapped: Clip[], audioClip: TimelineLayerRenderItem) => {
    const { in: start, end, trimOffsetInTime, trimOffsetOutTime } = audioClip
    const offset = start * 1000 // offset is in milliseconds
    const url = getAssetUrl(audioClip.data.asset.uri)
    const startTime = Math.abs(trimOffsetInTime || (0 as number))
    const duration = end - Math.abs(trimOffsetOutTime || 0)

    const clip = {
      type,
      url,
      start: startTime,
      end: duration,
      offset,
    }

    return [...mapped, clip]
  }, [])
}

/*
 * createEditVideo
 * creating a video setting parentId, and meta holding remix data
 */
export const createEditVideo = ({
  id,
  video,
}: CreateEditProps): VideoEnhanced => {
  const { id: parentId, scenes, thumbnailUri, thumbnails } = video
  const edit = {
    ...video,
    parentId,
    meta: {
      edit: {
        id,
        status: 'pending',
        video: {
          thumbnailUri,
          thumbnails,
          scenes,
        },
      },
    },
    name: createEditName({ video }),
  } as VideoEnhanced

  if (edit.events) delete edit.events
  return edit
}
