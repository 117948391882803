export const scoringConfig = {
  benchmarkScores: [57, 50, 40, 30],
  benchmarkLevels: {
    negative: 25,
    positive: 60,
  },
  benchmarkAdvanced: {
    mean: {
      value: 50.0,
      definition: `The average score for each scene in your video`,
      trend: {
        down: `The average performance for this video is significantly lower than most videos of this type. This video struggles to garner resonance in its viewers and is not getting your content's message across. Consider a reworking of the content that favors the scenes that work and removes that don't.`,
      },
    },
    skew: {
      value: 0.0,
      definition: `The asymmetry of the probability distribution of Tonik score across all scenes`,
      trend: {
        up: `There is a slight skew toward higher scores in this video relative to the video's average. In other words, there is a better chance of a score being better than average than being worse than average. Coupled with your average performance score, this means that the good portions of your content are alight, but the bad portions of your content are bringing the rest of the video down.`,
      },
    },
    variance: {
      value: 375.0,
      definition: `The standard deviation of scores, showing how tightly or loosely clustered they are`,
      trend: {
        down: `Variation is significantly lower than average when compared to similar videos. This isn't necessarily a bad thing, is just means that there is less change in this video from segment to segment. In other words, scores tend to stay constant at the various levels that they are at: good scores don't vary much amongst one another, and bad scores don't vary much amongst one another.`,
      },
    },
    kurt: {
      value: 0.0,
      definition: `A measure of the tails of the distribution of scores, indicating distance from the average`,
      trend: {
        up: `There is a much narrow distribution of scores than average for similar videos. This indicates that there are veery clear-cut 'good' and 'bad' sections of this video. Focusing on the good and avoiding the bad for future iterations of content can provide a significant lift in resonance going forward.`,
      },
    },
  },
} as const

export type BenchMarkScores = typeof scoringConfig.benchmarkScores
export type BenchMarkLevels = typeof scoringConfig.benchmarkLevels
export type BenchMarkAdvanced = typeof scoringConfig.benchmarkAdvanced
export default scoringConfig
