import { combineReducers } from 'redux'
import { assetsReducer } from '../assets/assets.slice'
import { authReducer } from '../auth/auth.slice'
import { brandsReducer } from '../brands/brands.slice'
import { clientsReducer } from '../clients/clients.slice'
import { playlistVideosReducer } from '../playlist-videos/playlistVideos.slice'
import { playlistsReducer } from '../playlists/playlists.slice'
import { projectsReducer } from '../projects/projects.slice'
import { serviceProfilesReducer } from '../serviceProfiles/serviceProfiles.slice'
import { uiReducer } from '../ui/ui.slice'
import { videosReducer } from '../videos/videos.slice'

// Currently all state is static eventually
// this should be dynamic from dynamically loaded modules
// see: https://github.com/microsoft/redux-dynamic-modules

export const entities = {
  assets: assetsReducer,
  playlists: playlistsReducer,
  playlistVideos: playlistVideosReducer,
  serviceProfiles: serviceProfilesReducer,
  projects: projectsReducer,
  videos: videosReducer,
}

export const app = {
  auth: authReducer,
  ui: uiReducer,
  clients: clientsReducer,
  brands: brandsReducer,
}

export const rootReducer = combineReducers({ ...app })
export const entitiesReducer = combineReducers({ ...entities })
export type RootReducerType = typeof rootReducer
export type EntitiesReducerType = typeof entitiesReducer
export type AppState = ReturnType<RootReducerType> & {
  entities: ReturnType<EntitiesReducerType>
}
export default rootReducer
