import { ToastOptions } from 'react-toastify'

export type AppSessionKeys = {
  storageKey: string
  base: string
  key: string
}

export const toastifyDefault: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  draggable: true,
  pauseOnHover: true,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
}
export const toastifySuccess: ToastOptions = {
  ...toastifyDefault,
}
export const toastifyError: ToastOptions = {
  ...toastifyDefault,
}
export const toastifyWarn: ToastOptions = {
  ...toastifyDefault,
}
export const toastifyInfo: ToastOptions = {
  ...toastifyDefault,
}

export const appSession: AppSessionKeys = {
  storageKey: 'auth',
  base: 'persist',
  key: 'edisen_vi',
}

export const api = {
  url: process.env.REACT_APP_API_V1,
  version: 2,
}
