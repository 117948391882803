import { FC, ReactElement } from 'react'

type BaseLayoutProps = {
  header: ReactElement
}

const BaseLayout: FC<BaseLayoutProps> = ({ header, children }) => {
  return (
    <div className="container">
      <header className="header-layout">{header}</header>
      {children}
    </div>
  )
}

export default BaseLayout
