import { NotificationServiceTypes } from '../modules/notifications/services/types'

export type PusherConfig = {
  app_id: string
  key: string
  cluster: string
  forceTLS: boolean
  keys: {
    brand: string
    editor: string
    analytics_report: string
  }
}

export const configNotificationServiceType: NotificationServiceTypes = 'pusher'

/*
 * Pusher brand video events
 */
export const pusher = process.env.REACT_APP_PUSHER
  ? JSON.parse(process.env.REACT_APP_PUSHER)
  : null

/*
 * Pusher video editor events
 */
export const pusherEditor = process.env.REACT_APP_PUSHER_EDITOR
  ? JSON.parse(process.env.REACT_APP_PUSHER_EDITOR)
  : null

export default pusher
