import { createSelector } from '@reduxjs/toolkit'
import { Project } from '@tvi/types/project'
import toArray from 'lodash/toArray'
import { projectsVideos } from '../../modules/projects/models/project'
import { AppState } from '../reducers'

/**
 * selectProjectsSelector - generic selection of projects (ids, entities)
 */
export const selectProjectsSelector = (state: AppState) =>
  state.entities.projects

/**
 * selectProjectsListsSelector - array of projects
 */
export const selectProjectsListsSelector = createSelector(
  selectProjectsSelector,
  (projects): Project[] => (toArray(projects?.entities) || []) as Project[]
)

/**
 * selectProjectsByBrandId - array of projects
 */
export const selectProjectsByBrandId = (brandId: number) =>
  createSelector(selectProjectsListsSelector, (projects): Project[] =>
    projects.filter((project) => project.brandId === brandId)
  )

/**
 * selectProjectVideos - array of videos from a list of projects
 */
export const selectProjectVideos = createSelector(
  selectProjectsListsSelector,
  projectsVideos
)

export const selectProjectsLoading = createSelector(
  selectProjectsSelector,
  (projects): boolean => projects?.ui?.isLoading
)
