import { FC } from 'react'
import { Helmet } from 'react-helmet'
import ConnectCardGrid from '../../components/connect/ConnectCardGrid'
import ConnectCardGridItem from '../../components/connect/ConnectCardGridItem'
import CenterLayout from '../../layouts/center-layout/CenterLayout'
import ConnectWrapper from '../../layouts/connect-wrapper/ConnectWrapper'
import { BrandsProvider } from '../../modules/brands/components/BrandsProvider'
import ConnectServiceActionModule from '../../modules/serviceProfiles/components/ConnectServiceActionModule'
import ConnectServiceModule from '../../modules/serviceProfiles/components/ConnectServiceModule'
import { ServiceProfilesProvider } from '../../modules/serviceProfiles/providers/ServiceProfilesProvider'

const ConnectPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>Connect Linked Accounts</title>
      </Helmet>
      <BrandsProvider>
        <ServiceProfilesProvider>
          <CenterLayout>
            <ConnectWrapper>
              <ConnectServiceActionModule />
              <ConnectServiceModule
                CardList={ConnectCardGrid}
                CardItem={ConnectCardGridItem}
              />
            </ConnectWrapper>
          </CenterLayout>
        </ServiceProfilesProvider>
      </BrandsProvider>
    </>
  )
}

export default ConnectPage
